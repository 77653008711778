import React, { useState, useEffect,useRef } from 'react';
import '../index.css';
import { useLocation } from 'react-router-dom';
import { CalendarToday as CalendarTodayIcon, EventNote as EventNoteIcon } from '@mui/icons-material';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import {FormControl,useMediaQuery,Collapse,Paper,Table,TableBody,TableCell,TableContainer,TableHead,TableRow,ToggleButton,Slider,Grid,Input, Box, Typography,Tooltip,IconButton,ToggleButtonGroup,MenuItem,Select, } from '@mui/material';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { useTheme } from '@emotion/react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import WidthFullIcon from '@mui/icons-material/WidthFull';

import MyResponsiveLine from './KeyBusinessMetricsChart'
import ViewTimelineIcon from '@mui/icons-material/ViewTimeline';


const NewStockPage = (ticker) => {
  const getPreviousQuarter = (year) => {
    if (alignment === 'annual') {
      return `FY${parseInt(year.slice(2)) - 1}`;
    } else {
      const quarterYear = year.split('-')[1];
      const quarter = year.split('-')[0];
      let prevQuarter = quarter;
      let prevYear = quarterYear;
  
      // Update previous quarter and year based on current quarter
      switch (quarter) {
        case 'Q1':
          prevQuarter = 'Q4';
          break;
        case 'Q2':
          prevQuarter = 'Q1';
          break;
        case 'Q3':
          prevQuarter = 'Q2';
          break;
        case 'Q4':
          prevQuarter = 'Q3';
          break;
        default:
          break;
      }
  
      // If the previous quarter is Q4, decrement the year
      if (prevQuarter === 'Q4') {
        prevYear = parseInt(quarterYear) - 1;
      }
  
      return `${prevQuarter}-${prevYear}`;
    }
  };
  const getPreviousYear = (year) => {
    if (alignment === 'annual') {
      return `FY${parseInt(year.slice(2)) - 1}`;
    } else {
      const quarterYear = year.split('-')[1];
      const quarter = year.split('-')[0];
      let prevQuarter = quarter;
      let prevYear = quarterYear;
  
      // Update previous quarter and year based on current quarter
      switch (quarter) {
        case 'Q1':
          prevQuarter = 'Q1';
          break;
        case 'Q2':
          prevQuarter = 'Q2';
          break;
        case 'Q3':
          prevQuarter = 'Q3';
          break;
        case 'Q4':
          prevQuarter = 'Q4';
          break;
        default:
          break;
      }

      prevYear = parseInt(quarterYear) - 1;
  
      return `${prevQuarter}-${prevYear}`;
    }
  };
  
  const shouldWrap2 = useMediaQuery('(max-width: 600px)');
  const calculateYoYGrowth = (current, previous) => {
    if (current === undefined || previous === undefined) return '';
    if (previous === 0) return '';
    return formatPercentage((current - previous) / previous);
  };
  const calculatePercentageOfRevenue = (current, revenue) => {
    if (current === undefined || revenue === undefined) return '';
    if (revenue === 0) return ''; // Avoid division by zero
    return formatPercentage(current / revenue)
  };
  const calculateYoYValue = (current, revenue,format) => {
    if (current === undefined || revenue === undefined) return '';
    if (revenue === 0) return ''; // Avoid division by zero
    const growth = current - revenue
    if (format ==='percent'){
      if (growth >0){
        return `+${formatNumber(current - revenue,format,alignmentScale)}pts`;
      }
      else{
      return `${formatNumber(current - revenue,format,alignmentScale)}pts`;
      }
    }
    if (growth >0){
      return `+${formatNumber(current - revenue,format,alignmentScale)}`;
    }
    else{
    return `${formatNumber(current - revenue,format,alignmentScale)}`;
    }
  };
  const formatPercentage = (num) =>{
    if (num < 1){
      return `${(num* 100).toFixed(1)}%`;
    }
    else{
      return `${(num* 100).toFixed(0)}%`;
    }
    
  }
  const formatNumber = (num,format,scale,curr) => {
    
    if (format ==='value'){
      if (typeof num !== 'number' || isNaN(num)) {
        return "";
      }

      if (num < 0) {
        if (Math.abs(num) >= 1000000000) {
          return `-${(Math.abs(num) / 1000000).toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 })}M`;
        } else if (Math.abs(num) > 1000000) {
          return `-${(Math.abs(num) / 1000000).toFixed(1)}M`} 
        else {
          return `${(num).toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 })}`
        }
      } else if (num >= 1000000000) {
        return `${(num / 1000000).toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 })}M`;
      } else if (num > 1000000) {
        return `${(num / 1000000).toFixed(1)}M`;
      } 
      else if (num >100){
        return `${(num).toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 })}`
      }
      else{
      return `${(num).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`
    } 
    }
    else if (format ==='percent'){
      if (typeof num !== 'number' || isNaN(num)) {
        return "";
      }
      if (num < 1){
        return `${(num* 100).toFixed(1)}%`;
      }
      else{
        return `${(num* 100).toFixed(0)}%`;
      }
    }
    else if (format ==='money'&&scale==='M'){
      if (typeof num !== 'number' || isNaN(num)) {
        return "";
      }
      if (num < 0) {

        if (Math.abs(num) >= 1000000000) {
          return `-$${(Math.abs(num) / 1000000).toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 })}M`;
        } else {
          return `-$${(Math.abs(num) / 1000000).toFixed(1)}M`;
        }
      } 

      else if (num >= 1000000000) {
        return `$${(num / 1000000).toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 })}M`;
      } else {
        return `$${(num / 1000000).toFixed(1)}M`;
      }}
      else if (format ==='money' &&scale==='B'){
        if (typeof num !== 'number' || isNaN(num)) {
          return "";
        }
        if (num < 0) {
  
          if (Math.abs(num) >= 10000000000) {
            return `-$${(Math.abs(num) / 1000000000).toLocaleString(undefined, { minimumFractionDigits: 1, maximumFractionDigits: 1 })}B`;
          } else {
            return `-$${(Math.abs(num) / 1000000000).toFixed(2)}B`;
          }
        } 
  
        else if (num >= 10000000000) {
          return `$${(num / 1000000000).toLocaleString(undefined, { minimumFractionDigits: 1, maximumFractionDigits: 1 })}B`;
        } else {
          return `$${(num / 1000000000).toFixed(2)}B`;
        }}
  };
  const theme = useTheme();
  const location = useLocation(); // If using React Router
  const tableContainerRef = useRef(null);
  const [annualData, setAnnualData] = useState([]);
  const [qtrData, setQtrData] = useState([]);
  const [keyStats, setKeyStats] = useState([]);
  const [annualMapping,setAnnualMapping] = useState([]);
  const [qtrMapping,setQtrMapping] = useState([]);
  const [alignment, setAlignment] = React.useState('annual');
  const [filteredAnnualData, setFilteredAnnualData] = useState([]);
  const [filteredQtrData, setFilteredQtrData] = useState([]);
  const [alignmentMetrics,setAlignmentMetrics] = useState('revenueMetrics')
  const [alignmentGraph,setAlignmentGraph] = useState('annual')
  const [selectedTrendedMetric, setSelectedTrendedMetric] = useState('Revenue');
  const handleChange = (event, newAlignment) => {
      if (newAlignment !== null){
        setAlignment(newAlignment);
        setAlignmentGraph(newAlignment)
      }
    };
    const handleChangeGraph = (event, newAlignment) => {
      if (newAlignment !== null){
        setAlignmentGraph(newAlignment);
      }
    };
  const handleChangeMetrics = (event, newAlignment) => {
    if (newAlignment !== null){
      setAlignmentMetrics(newAlignment);
      setSelectedTrendedMetric('Revenue')
    }
  };
  const [alignmentCollapse, setAlignmentCollapse] = React.useState('expand');

  const [alignmentScale, setAlignmentScale] = React.useState('M');
  const format = [{'field':'Revenue','fontWeight':400,'tab':1,'calc':['% Growth','$ Change','% of Revenue'],'format':'money'},
  {'field':'Gross Margin','fontWeight':400,'tab':1,'calc':['% Growth','$ Change','% of Revenue'],'format':'money'},
  {'field':'Non-Cloud Revenue','fontWeight':400,'tab':1,'calc':['% Growth','$ Change','% of Revenue'],'format':'money'},
  {'field':'Cloud Revenue','fontWeight':400,'tab':1,'calc':['% Growth','$ Change','% of Revenue'],'format':'money'},
  {'field':'Calculated Billings','fontWeight':400,'tab':1,'calc':['% Growth','$ Change','% of Revenue'],'format':'money'},
  {'field':'Current Bookings','fontWeight':400,'tab':1,'calc':['% Growth','$ Change','% of Revenue'],'format':'money'},
  {'field':'Bookings','fontWeight':400,'tab':1,'calc':['% Growth','$ Change','% of Revenue'],'format':'money'},
  {'field':'Current RPO','fontWeight':400,'tab':1,'calc':['% Growth','$ Change'],'format':'money'},
  {'field':'RPO','fontWeight':400,'tab':1,'calc':['% Growth','$ Change'],'format':'money'},
  {'field':'COGs','fontWeight':400,'tab':1,'calc':['% Growth','$ Change','% of Revenue'],'format':'money'},
  {'field':'Sales & Marketing','fontWeight':400,'tab':1,'calc':['% Growth','$ Change','% of Revenue'],'format':'money'},
  {'field':'Research & Development','fontWeight':400,'tab':1,'calc':['% Growth','$ Change','% of Revenue'],'format':'money'},
  {'field':'Sales & Marketing','fontWeight':400,'tab':1,'calc':['% Growth','$ Change','% of Revenue'],'format':'money'},
  {'field':'General & Administrative','fontWeight':400,'tab':1,'calc':['% Growth','$ Change','% of Revenue'],'format':'money'},
  {'field':'Operating Income','fontWeight':400,'tab':1,'calc':['% Growth','$ Change','% of Revenue'],'format':'money'},
  {'field':'Net Income','fontWeight':400,'tab':1,'calc':['% Growth','$ Change','% of Revenue'],'format':'money'},
  {'field':'Operating Cash Flow','fontWeight':400,'tab':1,'calc':['% Growth','$ Change','% of Revenue'],'format':'money'},
  {'field':'Capital Expenditures','fontWeight':400,'tab':1,'calc':['% Growth','$ Change','% of Revenue'],'format':'money'},
  {'field':'Shares Outstanding','fontWeight':400,'tab':1,'calc':['% Growth','$ Change'],'format':'value'},
  {'field':'Customers >$100k','fontWeight':400,'tab':1,'calc':['% Growth','$ Change'],'format':'value'},
  {'field':'Customers >$1M','fontWeight':400,'tab':1,'calc':['% Growth','$ Change'],'format':'value'},
  {'field':'Payback Period','fontWeight':400,'tab':1,'calc':['$ Change'],'format':'value'},
  {'field':'Cloud Mix','fontWeight':400,'tab':1,'calc':['$ Change'],'format':'percent'},
  {'field':'Free Cash Flow','fontWeight':400,'tab':1,'calc':['% Growth','$ Change','% of Revenue'],'format':'money'},
  {'field':'ARR','fontWeight':400,'tab':1,'calc':['% Growth','$ Change','% of Revenue'],'format':'money'},
  {'field':'Cloud ARR','fontWeight':400,'tab':1,'calc':['% Growth','$ Change','% of Revenue'],'format':'money'},
  {'field':'Non-Cloud ARR','fontWeight':400,'tab':1,'calc':['% Growth','$ Change','% of Revenue'],'format':'money'},
  {'field':'Rule of 40','fontWeight':400,'tab':1,'calc':['$ Change'],'format':'percent'},
  {'field':'RPO Duration','fontWeight':400,'tab':1,'calc':['$ Change'],'format':'value'},
  {'field':'Current RPO Mix','fontWeight':400,'tab':1,'calc':['$ Change'],'format':'percent'},
  {'field':'Net Revenue Retention','fontWeight':400,'tab':1,'calc':['$ Change'],'format':'percent'},
  ]
  const viewFilter = [
    {'filter':'revenueMetrics','metrics':['ARR','Cloud ARR','Non-Cloud ARR','Calculated Billings','Current Bookings','Bookings','Current RPO','RPO','Non-Cloud Revenue','Cloud Revenue','Revenue','Cloud Mix','RPO Duration','Current RPO Mix','Customers >100k','Customers >1M','Payback Period','Rule of 40','Net Revenue Retention']},
    {'filter':'profitMetrics','metrics':['Revenue','COGs','Sales & Marketing','General & Administrative','Operating Income','Net Income','Operating Cash Flow','Capital Expenditures','Shares Outstanding']},
    {'filter':'allMetrics','metrics':['ARR','Cloud ARR','Non-Cloud ARR','Calculated Billings','Current Bookings','Bookings','Current RPO','RPO','Non-Cloud Revenue','Cloud Revenue','Revenue','Cloud Mix','RPO Duration','Current RPO Mix','COGs','Sales & Marketing','General & Administrative','Operating Income','Net Income','Operating Cash Flow','Capital Expenditures','Shares Outstanding','Customers >100k','Customers >1M','Payback Period','Rule of 40','Net Revenue Retention']},
  
  ]
  const filterDataByMetrics = () => {
    const filteredFields = viewFilter.find(filter => filter.filter === alignmentMetrics).metrics;
    const filteredAnnualData = annualData.filter(item => filteredFields.includes(item.field));
    setFilteredAnnualData(filteredAnnualData);
    const filteredQtrData = qtrData.filter(item => filteredFields.includes(item.field));
    setFilteredQtrData(filteredQtrData);
  };
  
  useEffect(() => {
    const fetchData = async () => {
      try {
        // Ensure ticker is a string and log it for debugging
        const dynamicDataAnnual = await import(`../data/stockpage/financial/annual/${ticker['ticker']}.json`);
        const dynamicDataQtr = await import(`../data/stockpage/financial/quarterly/${ticker['ticker']}.json`);
        const dynamicKeyStats = await import(`../data/stockpage/keystatistics/${ticker['ticker']}.json`);
        const keyStatsFilterCriteria = ['Market Cap','Enterprise Value','Revenue Growth', 'EV / Revenue', 'EV / Gross Margin','Price / Free Cash Flow','Close Date']; // Example metrics to include
        // Set the data in state, assuming dynamicData.default is an array of arrays as per provided data format
        if (Array.isArray(dynamicDataAnnual.default.data)) {
          setAnnualData(dynamicDataAnnual.default.data);
          setAnnualMapping(dynamicDataAnnual.default.mappingTable);
          // Check the last revenue value and set the scale to 'B' if it's greater than $10B
          const revenueData = dynamicDataAnnual.default.data.find(item => item.field === 'Revenue');
          if (revenueData) {
            const lastRevenue = revenueData[Object.keys(revenueData).pop()]; // Get the last value
            if (lastRevenue > 1000000000) { // Check if the last revenue is greater than $10B
              setAlignmentScale('B');
            }
          }
        }
        else {
          console.error('Unexpected data format:', dynamicDataAnnual);
        } 
        if (Array.isArray(dynamicDataQtr.default.data)) {
          setQtrData(dynamicDataQtr.default.data);
          setQtrMapping(dynamicDataQtr.default.mappingTable);
        }
        else {
          console.error('Unexpected data format:', dynamicDataQtr);
        }
        if (Array.isArray(dynamicKeyStats.default[0].keyStats)) {
          setKeyStats(dynamicKeyStats.default[0].keyStats.filter(item =>
            keyStatsFilterCriteria.includes(item.field)));
        }
        else {
          console.error('Unexpected data format:', dynamicKeyStats);
        }
      } catch (error) {
        console.error(`Error fetching data for ${ticker}:, error`);
      }
    };

    fetchData();
  }, [ticker]);
  useEffect(() => {
    filterDataByMetrics();

  }, [annualData,qtrData,alignmentMetrics]);

  
  const [open, setOpen] = useState(true); // State to manage the open/closed state
  const calculateMaxSliderValue = () =>{ 
    if (alignment === 'annual'){
      return Math.min(annualMapping.length,20)
    }
    else
    {
      return Math.min(qtrMapping.length,20)
    }

  }
  useEffect(() => {
    const maxSliderValue = calculateMaxSliderValue();
  const initialSliderValue = Math.min(8, maxSliderValue);
  setValue(initialSliderValue);
  }, [annualMapping,qtrData,alignmentMetrics]);
  
  const maxSliderValue = calculateMaxSliderValue();
  const initialSliderValue = Math.min(8, maxSliderValue);
  const [sliderValue, setValue] = useState(initialSliderValue);

  
  // Function to handle button clicks and update the selected button state
  
  
  const handleChangeCollapse = (event, newAlignment) => {
    setAlignmentCollapse(newAlignment);
  };
  const handleChangeScale = (event, newAlignment) => {
    
    if (newAlignment !== null){
      setAlignmentScale(newAlignment);
    }
  };
  const handleSliderChange = (event, newValue) => {
    setValue(newValue);

  };
  const handleInputChange = (event) => {
    setValue(event.target.value === '' ? '' : Number(event.target.value));
  };
  const handleBlur = () => {
    if (sliderValue < 2 || isNaN(sliderValue)) {
      setValue(2);
    } else if (sliderValue > maxSliderValue) {
      setValue(maxSliderValue);
    }
  };

  
  
  
  const handleToggleCollapse = () => {
    setOpen(!open);
  };
  


  const annualSlicedMapping = annualMapping.slice(-sliderValue)
  const qtrSlicedMapping = qtrMapping.slice(-sliderValue)
  const qtrGraphSlicedMapping = qtrMapping.slice(-4* sliderValue)
  const mappingArray = alignment === 'annual' ? annualSlicedMapping : alignment === 'quarterly' ? qtrSlicedMapping : [];
  const filteredData = alignment === 'annual' ? filteredAnnualData : alignment === 'quarterly' ? filteredQtrData : [];

  const graphFilteredData = alignmentGraph === 'annual' ? filteredAnnualData : alignmentGraph  === 'quarterly' ? filteredQtrData : [];
  const graphMappingArray = alignment === 'annual' ? 
    alignmentGraph ==='annual'?
    annualSlicedMapping : qtrGraphSlicedMapping :
    alignment === 'quarterly' ? qtrSlicedMapping : [];

  const tableWidth = Math.min((mappingArray.length *65 + 450),1600) 
  const tableContainerWidth = Math.min(Math.max(mappingArray.length *65 + 450,1050),1600) 
  const convertToNivoFormat = (field) => {
    const filtered = graphFilteredData.find(item => item.field === field);
  if (!filtered) return [];

  // Filter the data based on the periods in annualSlicedMapping and format it for Nivo
  
  const formattedData = {
    id: field,
    data: Object.keys(filtered)
      .filter(key => key !== 'field' && graphMappingArray.some(mapping => mapping.period === key))
      .map(key => ({
        x: key,
        y: filtered[key]
      }))
  };

  return [formattedData];
  };
  const nivoData = convertToNivoFormat(selectedTrendedMetric);
  
  return(
  <Box sx={{width: tableContainerWidth + 50, maxWidth:'100%'}}>
   <Box sx={{ position: 'relative', marginTop:'6px',paddingLeft: '1%', marginLeft: '0px' }}>
   <Paper elevation={2} sx={{backgroundColor: theme.background.backgroundColor, paddingBottom: '4px', maxWidth: tableContainerWidth + 50, paddingTop: '0px', marginTop: '6px', marginBottom: '0px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
    {!open && (
          <Box
            className='label'
            sx={{
              paddingTop: '6px',
              paddingBottom: '6px',
              paddingLeft: '1.5%',
              fontWeight: 'bold',
            }}
          >
            <Box sx={{ paddingTop: '2px', fontSize: '12px',minWidth:'200px' }}>
              Expand Dashboard Filters
            </Box>
          </Box>
        )}
        <Collapse in={open} sx={{ width: '95%' }}>
    <Box sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap',justifyContent:'center' }}>
    <Paper elevation={theme.palette.mode=='dark'?4:2} sx={{ backgroundColor: theme.background.backgroundColor,width: '630px', marginLeft: '1%', paddingTop: '8px', marginTop: '6px', paddingLeft: '1%', paddingRight: '2%', paddingBottom: '0px', display: 'flex', alignItems: 'center' }}>
      <Box sx={{ maxWidth: '70px', fontSize: '12px', fontWeight: 'bold', textAlign: 'center',  paddingBottom: '10px' }}>Dashboard Filters</Box>
      <Box sx={{ display: 'flex', justifyContent: 'flex-start', flex: 1, flexWrap: 'wrap' }}>

          
          <Box>

            <Box sx={{ marginLeft: '1%', marginRight: '1%', marginBottom: 0 }}>
            <ToggleButtonGroup
              sx={{
                backgroundColor:theme.tableHeader.highlight,
                '& .Mui-selected': {
                  color: theme.toggleButton.selectedTextColor,
                  backgroundColor: theme.toggleButton.selectedBackgroundColor,
                },
              }}
              value={alignment}
              exclusive
              onChange={handleChange}
              size="small" // Add this line to make the buttons smaller
              aria-label="Time Period Toggle"
            >
            <ToggleButton style={{ textTransform: 'none' }} value="annual" sx={{ py: 0.1, px: 1.1 }}>
              <Tooltip title="Annual Data">
              <IconButton color={alignment === 'annual' ? 'primary' : 'default'}  sx={{ py: 0, px: 0 }}>
                <Box>
                < CalendarTodayIcon />
                <Typography variant="body2" align="center" sx={{ mt: -.8, fontWeight: 'bold', fontSize: '10px' }}>FY</Typography>
                </Box>
              </IconButton>
            </Tooltip>
              </ToggleButton>
              <ToggleButton style={{ textTransform: 'none' }} value="quarterly" sx={{ py: 0.1, px: 1.1 }}>
              <Tooltip title="Quarterly Data">
              <IconButton color={alignment === 'quarterly' ? 'primary' : 'default'}  sx={{ py: 0, px: 0 }}>
                <Box>
                < EventNoteIcon />
                <Typography variant="body2" align="center" sx={{ mt: -1, fontWeight: 'bold', fontSize: '10px' }}>Q4</Typography>
                </Box>
              </IconButton>
            </Tooltip>
              </ToggleButton>
            </ToggleButtonGroup>
             </Box>
             <Box sx={{ paddingBottom:'4px',marginTop:'2px',fontSize: '11px', alignItems: 'center', textAlign: 'center' }}>Time Period</Box>
             </Box>
             <Box sx={{ marginLeft: '2%', marginRight: '2%', marginBottom: 0 }}>
            <ToggleButtonGroup
              sx={{
                backgroundColor:theme.tableHeader.highlight,
                '& .Mui-selected': {
                  color: theme.toggleButton.selectedTextColor,
                  backgroundColor: theme.toggleButton.selectedBackgroundColor,

                },
              }}
              value={alignmentScale}
              exclusive
              onChange={handleChangeScale}
              size="small" // Add this line to make the buttons smaller
              aria-label="Time Period Toggle"
            >
            <ToggleButton style={{ textTransform: 'none' }} value="M" sx={{ py: 1.1, px: 1.0 }}>
              <Tooltip title="Format values in millions">
              <IconButton color={alignmentScale === 'M' ? 'primary' : 'default'}  sx={{ py: 0, px: 0 }}>
                <Box>
                <Typography align="center" sx={{ mt: -.8, fontWeight: 'bold', fontSize: '16px',paddingTop:'5px',paddingBottom:'5px'  }}>$M</Typography>
                <Typography variant="body2" align="center" sx={{ mt: -.8, fontWeight: 'bold', fontSize: '10px' }}></Typography>
                </Box>
              </IconButton>
            </Tooltip>
              </ToggleButton>
              <ToggleButton style={{ textTransform: 'none' }} value="B" sx={{ py: 1.1, px: 1.0 }}>
              <Tooltip title="Format values in billions">
              <IconButton color={alignmentScale === 'B' ? 'primary' : 'default'}  sx={{ py: 0, px: 0 }}>
                <Box>
                <Typography align="center" sx={{ mt: -.8, fontWeight: 'bold', fontSize: '16px',paddingTop:'5px',paddingBottom:'5px'  }}>$B</Typography>
                <Typography variant="body2" align="center" sx={{ mt: -.8, fontWeight: 'bold', fontSize: '10px' }}></Typography>
                </Box>
              </IconButton>
            </Tooltip>
              </ToggleButton>
            </ToggleButtonGroup>
            <Box sx={{ paddingBottom:'4px',marginTop:'2px',fontSize: '11px', alignItems: 'center', textAlign: 'center' }}>Format values</Box>
             
             </Box>
             <Box sx={{  marginLeft: '0%', marginRight: '1%' }}>
              <ToggleButtonGroup
                sx={{
                  backgroundColor:theme.tableHeader.highlight,
                  '& .Mui-selected': {
                    color: theme.toggleButton.selectedTextColor,
                    backgroundColor: theme.toggleButton.selectedBackgroundColor,
                  },
                }}
                value={alignmentMetrics}
                exclusive
                onChange={handleChangeMetrics}
                size="small"
                aria-label="Time Period Toggle"
              >

                <ToggleButton style={{ textTransform: 'none' }} value="revenueMetrics" sx={{ minWidth:'50px',py: 0.1, px: 1.1 }}>
                  <Tooltip title="Top-line & Efficiency KPIs">
                    <IconButton color={alignmentMetrics === 'revenueMetrics' ? 'primary' : 'default'}  sx={{ py: 0, px: 0 }}>
                      <Box>
                        <ShowChartIcon />
                        <Typography variant="body2" align="center" sx={{ mt: -1, fontWeight: 'bold', fontSize: '10px' }}>KPI</Typography>
                      </Box>
                    </IconButton>
                  </Tooltip>
                </ToggleButton>

                
                <ToggleButton style={{ textTransform: 'none' }} value="profitMetrics" sx={{ minWidth:'50px',py: 0.1, px: 1.1 }}>
                  <Tooltip title="Profitability Metrics">
                    <IconButton color={alignmentMetrics === 'profitMetrics' ? 'primary' : 'default'}  sx={{ py: 0, px: 0 }}>
                      <Box>
                        <AttachMoneyIcon />
                        <Typography variant="body2" align="center" sx={{ mt: -.8, fontWeight: 'bold', fontSize: '10px' }}>Profit</Typography>
                      </Box>
                    </IconButton>
                  </Tooltip>
                </ToggleButton>
                <ToggleButton style={{ textTransform: 'none' }} value="allMetrics" sx={{ minWidth:'50px',py: 0.1, px: 1.1 }}>
                  <Tooltip title="Show All Metrics">
                    <IconButton color={alignmentMetrics === 'allMetrics' ? 'primary' : 'default'}  sx={{ py: 0, px: 0 }}>
                      <Box>
                        <WidthFullIcon />
                        <Typography variant="body2" align="center" sx={{ mt: -.8, fontWeight: 'bold', fontSize: '10px' }}>All</Typography>
                      </Box>
                    </IconButton>
                  </Tooltip>
                </ToggleButton>
                
              </ToggleButtonGroup>
              <Box sx={{ marginTop:'2px',paddingBottom:'4px',fontSize: '11px', alignItems: 'center', textAlign: 'center' }}>Toggle Metrics</Box>
             </Box>
             <Box>

          <Box sx={{ width: '180px' }}>
          <Box  sx={{backgroundColor:theme.tableHeader.highlight, height:'41px',py: 0.2, px: 1 ,marginLeft:'1%', marginRight:'4%',border: `1px solid ${theme.palette.mode === 'dark' ? '#333' : '#ddd'}`, borderRadius: '4px' }}>
            <Grid container spacing={2} alignItems="center">
              <Grid item>
              <IconButton color={'default'}  sx={{ py: 0, px: 0 }}>
                <ViewTimelineIcon />
                </IconButton>
              </Grid>
              <Grid item xs>
                <Slider
                  value={typeof sliderValue === 'number' ? sliderValue : 0}
                  onChange={handleSliderChange}
                  aria-labelledby="input-slider"
                  min={2}
                  max={maxSliderValue}
                />
              </Grid>
              <Grid item>
              <Input
                value={sliderValue}
                size="small"
                onChange={handleInputChange}
                onBlur={handleBlur}
                inputProps={{
                  step: 1,
                  min: 2,
                  max: {maxSliderValue},
                  type: 'text', // Change type to text
                  pattern: '\\d*', // Pattern to only allow digits
                  'aria-labelledby': 'input-slider',
                }}
                sx={{ width: '20px', '&::-webkit-inner-spin-button, &::-webkit-outer-spin-button': { display: 'none' } }}
              />
                
              </Grid>
            </Grid>
            </Box>
            <Box sx={{ marginTop:'2px',paddingBottom:'4px',fontSize: '11px', alignItems: 'center', textAlign: 'center' }}>Display Last {sliderValue} {alignment === "annual" ? "Years" : "Quarters"}</Box>
            
          </Box>
          </Box>
             </Box>
          </Paper>
          
   
          <Paper elevation={theme.palette.mode=='dark'?4:2} sx={{ backgroundColor: theme.background.backgroundColor,width: '380px', marginLeft: '1%', paddingTop: '8px', marginTop: '6px', paddingLeft: '1%', paddingRight: '2%', paddingBottom: '0px', display: 'flex', alignItems: 'center' }}>
      <Box sx={{ maxWidth: '40px', fontSize: '12px', fontWeight: 'bold', textAlign: 'center',  paddingBottom: '10px' }}>Chart Filters</Box>
      <Box sx={{ display: 'flex', justifyContent: 'flex-start', flex: 1, flexWrap: 'wrap' }}>
          <Box sx={{  marginLeft: '2%', marginRight: '1%' }}>
            <ToggleButtonGroup
              sx={{
                backgroundColor:theme.tableHeader.highlight,
                '& .Mui-selected': {
                  color: theme.toggleButton.selectedTextColor,
                  backgroundColor: theme.toggleButton.selectedBackgroundColor,
                }
              }}
              value={alignmentGraph}
              exclusive
              size="small"
              onChange={handleChangeGraph}
              aria-label="Graph Toggle"
            >
              <ToggleButton style={{ textTransform: 'none' }} value="annual" disabled={alignment === "quarterly"} sx={{ py: 0.1, px: 1.1 }}>
                <Tooltip title="Annual Data">
                  <IconButton color={alignmentGraph === 'annual' ? 'primary' : 'default'} sx={{ py: 0, px: 0, color: alignment === 'quarterly' ? 'text.disabled' : 'primary' }}>
                    <Box>
                      <CalendarTodayIcon />
                      <Typography variant="body2" align="center" sx={{ mt: -.8, fontWeight: 'bold', fontSize: '10px', color: alignment === "quarterly" ? 'text.disabled' : 'primary' }}>FY</Typography>
                    </Box>
                  </IconButton>
                </Tooltip>
              </ToggleButton>
              <ToggleButton style={{ textTransform: 'none' }} value="quarterly" sx={{ py: 0.1, px: 1.1 }}>
                <Tooltip title="Quarterly Data">
                  <IconButton color={alignmentGraph === 'quarterly' ? 'primary' : 'default'} sx={{ py: 0, px: 0 }}>
                    <Box>
                      <EventNoteIcon />
                      <Typography variant="body2" align="center" sx={{ mt: -1, fontWeight: 'bold', fontSize: '10px', color: 'primary' }}>Q4</Typography>
                    </Box>
                  </IconButton>
                </Tooltip>
              </ToggleButton>
            </ToggleButtonGroup>
            <Box sx={{ paddingBottom:'4px',marginTop: '2px', fontSize: '11px', alignItems: 'center', textAlign: 'center' }}>Time Period</Box>
          </Box>
          <Box>
            <FormControl sx={{ paddingLeft: '1%', m: 0, width: '200px' }}>
              <Select
                value={selectedTrendedMetric}
                onChange={(event) => setSelectedTrendedMetric(event.target.value)}
                displayEmpty
                size="small"
                sx={{ fontSize:'13px',height: '41px',backgroundColor:theme.tableHeader.highlight}}
                inputProps={{ 'aria-label': 'Without label' }}
              >
                
                {filteredData.map((item, index) => (
                <MenuItem sx={{ fontSize:'13px'}} key={index} value={item.field}>
                  {item.field}
                </MenuItem>
              ))}
              </Select>
              <Box sx={{ paddingBottom:'4px',marginTop: '2px', fontSize: '11px', alignItems: 'center', textAlign: 'center'}}>Selected Metric</Box>
            </FormControl>
          </Box>
        </Box>
      </Paper>
          </Box>
          <Box sx={{ marginTop:'6px'}}></Box>
        </Collapse>
        <IconButton
        sx={{
          p: 0.1,
          fontSize: 15,
          marginTop: '2px',
          marginRight: '2px',


        }}
        onClick={handleToggleCollapse}
        aria-label="toggle-collapse"
      >
        {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
      </IconButton>
        </Paper>
    </Box>
    <Box sx={{ maxWidth: tableContainerWidth + 50,marginLeft:'1%',display: 'flex',justifyContent: 'flex-start',flexWrap: shouldWrap2 ? 'wrap' : 'nowrap'}}>
    <Paper elevation={2} sx={{ paddingTop:'5px',paddingBottom:'5px',flex:1,maxWidth: shouldWrap2 ? '100%' : '280px', minWidth:'200px',marginRight:'.5%',marginTop:'6px',paddingLeft:'.5%',paddingRight:'.5%'}}>
      <Box sx={{color: theme.palette.primary.main,fontSize:'20px', fontWeight:'600'}}>Valuation Metrics</Box>
      <TableContainer component={Paper} sx={{ maxWidth:'300px',marginTop:'5px'}}>
        <Table size='small' aria-label="a dense table">
          <TableHead>
          <TableRow sx={{backgroundColor: theme.tableHeader.color,fontWeight: '700',fontSize: '30'}}>
              <TableCell  sx={{paddingTop: '3px',paddingBottom: '3px'}} align="left">Metrics</TableCell>
              <TableCell sx={{paddingTop: '3px',paddingBottom: '3px'}} align="center"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
        {keyStats.map((row, index) => (
          <TableRow
            key={row.field}
            sx={{
              backgroundColor: index % 2 === 0 ? theme.tableHeader.highlight : theme.background.default,
              '&:last-child td, &:last-child th': { border: 0 },
              position: 'relative',
              ':hover': {
                backgroundColor: theme.tableHeader.highlightDark,
                cursor: 'pointer',
              },
            }}
          >
            <TableCell sx={{ paddingTop: '3px', paddingBottom: '3px' }} align="left">
              {row.field}
              {row.subField && <div className="sublabel">{row.subField}</div>}
            </TableCell>
            <TableCell sx={{ paddingTop: '3px', paddingBottom: '3px' }} align="right">{row.value}</TableCell>
          </TableRow>
        ))}
      </TableBody>
        </Table>
      </TableContainer>
      </Paper>
        
        <Paper elevation={2} sx={{  flex: 1,paddingTop:'5px',marginTop:'6px',paddingLeft:'1%',minWidth:'300px',maxWidth:'100%'}}>
        <Box sx={{color: theme.palette.primary.main,fontSize:'20px', fontWeight:'600'}}>{selectedTrendedMetric} </Box>
        

          
          <Box style={{ flex: 1,height:'35vh',width:'100%', position: 'relative' }}> 
          <MyResponsiveLine data={nivoData} theme = {theme} />
              <img
              src={require('../data/images/logo.png')}
              alt="Website Logo"
              style={{
                position: 'absolute',
                bottom: 50, // Adjust the distance from the bottom
                right: 62, // Adjust the distance from the right
                width: '130px', // Set the desired width for the postage stamp
                height: 'auto', // Maintain aspect ratio
                opacity: 0.4, // Set the desired opacity for the watermark
              }}
            />
        </Box>
        </Paper>
        </Box>
    <Paper elevation={3} sx={{ maxWidth:tableContainerWidth+50, paddingBottom:'0px',paddingTop: '6px', marginTop: '6px', flex: 1, paddingLeft: '2%',marginLeft:'1%',paddingRight:'1%' }}>
      <Box>
        <Box sx={{ color: theme.palette.primary.main, fontSize: '24px', fontWeight: '600' }}>Trended Metrics
        </Box>
      </Box>
      <TableContainer component={Paper} sx={{ paddingBottom:'0px',cursor: 'pointer', marginTop: '4px', overflowY: 'auto', overflowX: 'auto', maxWidth: tableWidth }} ref={tableContainerRef}>
        
  <Table size='small' aria-label="acquisition table">
    <TableHead>
      <TableRow sx={{ backgroundColor: theme.tableHeader.color, fontWeight: 'bold', position: 'sticky', top: 0, zIndex: 2 }}>
        <TableCell sx={{ minWidth:'220px',textAlign: 'left', backgroundColor: theme.tableHeader.color, fontWeight: 'bold', left: 0,position:'sticky' }}>
          <Typography variant="subtitle2" sx={{ marginBottom: '-3px' }}>{alignment=='annual'?'Fiscal Year':'Fiscal Quarter'}</Typography>
        <Typography variant="body2" color="textSecondary" sx={{fontWeight:400,fontSize:'10px'}}>{alignment=='annual'?'Year Ended':'Quarter Ended'}</Typography></TableCell> 
        {mappingArray.map((item) => (
          <TableCell key={item.period} sx={{ textAlign: 'right', backgroundColor: theme.tableHeader.color, fontWeight: 'bold', paddingRight: '20px' }}>
          <Typography variant="subtitle2" sx={{ marginBottom: '-3px' }}>{item.period}</Typography>
          <Typography variant="body2" color="textSecondary" sx={{fontWeight:400,fontSize:'10px'}}>{item.endDate}</Typography>
        </TableCell>
        ))}

      </TableRow>
    </TableHead>
    <TableBody sx={{ position: 'relative', zIndex: 1 }}>
      {filteredData.flatMap((row, index) => [
        <TableRow 
          key={`row-${index}`} 
          onClick={() => setSelectedTrendedMetric(row.field)}
          sx={{backgroundColor: index % 2 === 0 ? theme.tableHeader.highlight: theme.background.default,
            '&:last-child td, &:last-child th': { border: 0 },
            position: 'relative',
            '.freeze-column': {
              backgroundColor: index % 2 === 1 ? theme.tableHeader.highlight: theme.background.default,
            },
            ':hover': {
              backgroundColor: theme.tableHeader.highlightDark,
              cursor: 'pointer',
              '.freeze-column': {
                backgroundColor: theme.tableHeader.highlightDark,
              },
            },
          }}>
          <TableCell sx={{ backgroundColor:'inherit', fontWeight: format.find(item => item.field === row.field)?.fontWeight || 'normal', paddingLeft: format.find(item => item.field === row.field)?.tab*2, paddingTop: '2px', paddingBottom: '0px', textAlign: 'left',  position: 'sticky', left: 0 }}>{row.field}</TableCell> {/* Add position: sticky and left: 0 */}
          {mappingArray.map((item) => (
            <TableCell key={`data-${index}-${item.period}`} sx={{ fontWeight: format.find(item => item.field === row.field)?.fontWeight || 'normal', paddingTop: '3px',paddingBottom: '3px', textAlign: 'right' }}>{format.find(item => item.field === row.field)?.format ==='money'?formatNumber(row[item.period],format.find(item => item.field === row.field)?.format,alignmentScale):formatNumber(row[item.period],format.find(item => item.field === row.field)?.format)}</TableCell>
          ))}
        </TableRow>,

      ])}
    </TableBody>
  </Table>
</TableContainer>

      <Box sx={{ paddingBottom: '1vh' }}></Box>
    </Paper>
  </Box>
      )}
      export default NewStockPage;