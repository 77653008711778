import React, { useState, useEffect } from 'react';
import '../index.css';
import { useLocation } from 'react-router-dom';
import ReactGA from 'react-ga4';
import { Box,Tabs,Tab,Paper } from '@mui/material';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { useTheme } from "@mui/material/styles";
import StockModelView from '../components/StockModelView';
import CompSheetData from '../data/charts/compsheet.json'
import CompSheetComponent from '../components/CompSheetComponent';
import MetricsValuation from '../components/MetricsValuation';
import SAKeyMetrics from '../components/SAKeyMetrics';
import SAPrivate from '../components/SAPrivate';

import SAAbout from '../components/SAAbout';
import itemListData from '../data/companylistsearch.json';
import LoginNotice from '../components/LoginNotice';

import { useAuth0 } from "@auth0/auth0-react"; // Import useAuth0

const StockPage = ({ ticker }) => {
  const { isAuthenticated, loginWithRedirect } = useAuth0(); // Use the useAuth0 hook
  useEffect(() => {
    // Redirect to login if the user is not authenticated
   //if (!isAuthenticated) {
  //   loginWithRedirect();
  //  }
  }, [isAuthenticated, loginWithRedirect]); // Dependencies ensure this runs when isAuthenticated or loginWithRedirect changes

  const theme = useTheme();
  const location = useLocation(); // If using React Router
  
  useEffect(() => {
    ReactGA.send({ hittype: 'pageview', page: location.pathname });
  }, [location.pathname]);



  // Decide whether to display the logo based on the window width
  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);

    // If you want to scroll to the top every time the component updates, you can include an empty dependency array []
  }, []); // The empty dependency array ensures that this effect runs only once when the component mounts


    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };


    window.addEventListener('resize', handleResize);

    // Cleanup the event listener when the component unmounts
    return () => {
      window.removeEventListener('resize', handleResize);
    };
    
  }, []);
  
  const shouldDisplayLogo = windowWidth > 790;
  //<ToggleButton value = 'test'></ToggleButton>
  let logoSource = `/logos/${ticker}/logo.png`;
  const [selectedButton, setSelectedButton] = useState(0);

  // Function to handle button clicks and update the selected button state
  const handleButtonClick = (index) => {
    setSelectedButton(index);
  };

  if (theme.palette.mode === 'dark') {
    logoSource = `/logos/${ticker}/logodark.png`;
  }
  const findCategoryByTicker = (ticker) => {
    for (let company of itemListData) {
      if (company.ticker === ticker) {
        return company.category;
      }
    }
    return null; // Return null if ticker is not found
  };
  const findCompanyName = (ticker) => {
    for (let company of itemListData) {
      if (company.ticker === ticker) {
        return company.companyName;
      }
    }
    return null; // Return null if ticker is not found
  };
  const findHasCapTable = (ticker) => {
    for (let company of itemListData) {
      if (company.ticker === ticker) {
        return company.hasCapTable;
      }
    }
    return null; // Return null if ticker is not found
  };
  const findIsPrivate = (ticker) => {
    for (let company of itemListData) {
      if (company.ticker === ticker) {
        return company.isPrivate;
      }
    }
    return null; // Return null if ticker is not found
  };
  // Get the category for the given ticker
  const category = findCategoryByTicker(ticker);
  //if (!isAuthenticated) {
  //  return null; // Optionally, you can show a loading spinner or message here
  //}
  const companyName = findCompanyName(ticker);
  const hasCapTable = findHasCapTable(ticker);
  const isPrivate = findIsPrivate(ticker);

  useEffect(() => {
    if (hasCapTable === false) {
      setSelectedButton(1)
    }
    else
    {
      setSelectedButton(5)
    }
  }, [ticker]);
  return (

    <Box sx={{maxWidth:'100%'}}>
    <Paper  sx={{paddingTop:'4px',backgroundColor:theme.background.backgroundColor,display:'flex', justifyContent:'center'}} elevation={3} >
   
      <Box sx={{maxWidth:'100%',display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>

      <Box sx={{maxWidth:'100%'}}>
      <Box sx={{marginBottom:'0px',color: theme.palette.primary.main, fontSize: '36px',fontWeight:'600',display:'flex', justifyContent:'center'}} >Security Analysis</Box>
      <Box sx={{marginBottom:'4px', fontStyle:'italic',fontSize:'16px',fontWeight:'600',display:'flex', justifyContent:'center'}} >
      {isPrivate ? (
          <span>Private: {companyName}</span>
        ) : (
          <span>{companyName}: {ticker}</span>
        )}
        </Box>
      
      <Box sx={{maxWidth:'100%',display:'flex', justifyContent:'center'}}>
      <Tabs variant="scrollable"
      value={selectedButton} // Bind the Tabs component to the selected tab
      onChange={(e, newValue) => setSelectedButton(newValue)} // Update selectedButton when tab changes
        scrollButtons
        allowScrollButtonsMobile
        elevation={0} position="static" sx={{ maxWidth:'100%',marginLeft:'0%', backgroundColor: 'transparent' ,display:'flex', justifyContent:'center'}}>
        {true&&(<Tab
        label="About"
        
        style={{ textTransform: 'none' }}
          sx={{
            borderBottom: selectedButton === 0 ? `3px solid ${theme.palette.primary.main}` : 'none', 
            backgroundColor: selectedButton === 0 ? theme.toggleButton.selectedBackgroundColor : 'transparent',
            color: selectedButton === 0 ? theme.button.color : 'inherit',            
          }}
          onClick={() => handleButtonClick(0)}
        >
        </Tab>)}
        {true&&!hasCapTable &&(<Tab
        label="Key Metrics"
        
        style={{ textTransform: 'none' }}
          sx={{
            borderBottom: selectedButton === 1 ? `3px solid ${theme.palette.primary.main}` : 'none', 
            backgroundColor: selectedButton === 1 ? theme.toggleButton.selectedBackgroundColor : 'transparent',
            color: selectedButton === 1 ? theme.button.color : 'inherit',            
          }}
          onClick={() => handleButtonClick(1)}
        >
        </Tab>)}
        {true&&!hasCapTable &&(
        <Tab
        label="Financial Model"
        style={{ textTransform: 'none' }}
          sx={{
            borderBottom: selectedButton === 2 ? `3px solid ${theme.palette.primary.main}` : 'none', 
            backgroundColor: selectedButton === 2 ? theme.toggleButton.selectedBackgroundColor : 'transparent',
            color: selectedButton === 2 ? theme.button.color : 'inherit',            
          }}
          onClick={() => handleButtonClick(2)}
        >
        </Tab>)}
        {true&&!hasCapTable&& !isPrivate &&(
        <Tab
        label="Comp Sheet"
        style={{ textTransform: 'none' }}
          sx={{
            borderBottom: selectedButton === 3 ? `3px solid ${theme.palette.primary.main}` : 'none', 
            backgroundColor: selectedButton === 3 ? theme.toggleButton.selectedBackgroundColor : 'transparent',
            color: selectedButton === 3 ? theme.button.color : 'inherit',            
          }}
          onClick={() => handleButtonClick(3)}
        >
        </Tab>)}
        {true &&!hasCapTable && !isPrivate&&(
        <Tab
        label="Valuation Plot"
        style={{ textTransform: 'none' }}
          sx={{
            borderBottom: selectedButton === 4 ? `3px solid ${theme.palette.primary.main}` : 'none', 
            backgroundColor: selectedButton === 4 ? theme.toggleButton.selectedBackgroundColor : 'transparent',
            color: selectedButton === 4 ? theme.button.color : 'inherit',            
          }}
          onClick={() => handleButtonClick(4)}
        >
        </Tab>)}
        {true &&hasCapTable&&(
        <Tab
        label="Private Finacials"
        style={{ textTransform: 'none' }}
          sx={{
            borderBottom: selectedButton === 5 ? `3px solid ${theme.palette.primary.main}` : 'none', 
            backgroundColor: selectedButton === 5 ? theme.toggleButton.selectedBackgroundColor : 'transparent',
            color: selectedButton === 5 ? theme.button.color : 'inherit',            
          }}
          onClick={() => handleButtonClick(5)}
        >
        </Tab>)}
      </Tabs>
      </Box>
      </Box>


        {shouldDisplayLogo && (
          <Box sx={{paddingTop:'5px',paddingLeft:'10px',paddingRight:'10px',maxWidth:'290px' }}>
            <img 
              src= {logoSource}  
              alt={`${ticker} Icon`}
              style={{maxHeight:'85px',maxWidth:'95%'}}
            />
          </Box>
        )}
        </Box>
        
        </Paper>
        

        <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',

        }}
      >
      <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>

  {selectedButton === 0 && true && <SAAbout ticker={ticker} />}
  {selectedButton === 1 && isAuthenticated && !hasCapTable&& <SAKeyMetrics ticker={ticker} />}
  {selectedButton === 2 && isAuthenticated && !hasCapTable &&<StockModelView ticker={ticker} />}
  {selectedButton === 3 && isAuthenticated && !hasCapTable && !isPrivate && <CompSheetComponent categoryInput={[category]} trendedData={CompSheetData} tickerInput={ticker} />}
  {selectedButton === 4 && isAuthenticated && !hasCapTable && !isPrivate && <MetricsValuation categoryInput={[category]} />}
  {selectedButton === 5 && hasCapTable && <SAPrivate ticker={ticker} />}
  {(selectedButton !== 5&&selectedButton !== 0) &&  !isAuthenticated && <LoginNotice />}

  </Box>
  </Box>
  </Box>

  );
};

export default StockPage;