import React, { useEffect } from 'react';

const TwitterFollowButton = () => {
  useEffect(() => {
    // Load Twitter widgets.js once the component is mounted
    const script = document.createElement('script');
    script.src = 'https://platform.twitter.com/widgets.js';
    script.async = true;
    document.head.appendChild(script);

    return () => {
      // Cleanup: remove the script when the component is unmounted
      document.head.removeChild(script);
    };
  }, []);

  return (
    <div>
      {/* Add Twitter follow button HTML here */}
      <a
        margin-right="2%"
        href="https://twitter.com/software__iq"
        className="twitter-follow-button"
        data-show-count="false"
        data-size="large"
        data-show-screen-name="false"
      >
        Follow Us On X
      </a>
      {/* End of Twitter follow button HTML */}
    </div>
  );
};

export default TwitterFollowButton;