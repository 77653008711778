import React, { useState, useEffect,useRef } from 'react';
import '../index.css';
import { useLocation } from 'react-router-dom';
import { CalendarToday as CalendarTodayIcon, EventNote as EventNoteIcon } from '@mui/icons-material';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import {FormControl,Collapse,Paper,Table,TableBody,TableCell,TableContainer,TableHead,TableRow,ToggleButton,Slider,Grid,Input, Box, Typography,Tooltip,IconButton,ToggleButtonGroup,MenuItem,Select, } from '@mui/material';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { useTheme } from '@emotion/react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import CalculateIcon from '@mui/icons-material/Calculate';
import SummarizeIcon from '@mui/icons-material/Summarize';
import ViewTimelineIcon from '@mui/icons-material/ViewTimeline';


const NewStockPage = (ticker) => {
  const theme = useTheme();
  const location = useLocation(); // If using React Router
  const tableContainerRef = useRef(null);



  const [data, setData] = useState([]);
  const [alignment, setAlignment] = React.useState('annual');

  const handleChange = (event, newAlignment) => {
      setAlignment(newAlignment);
    };
  const [alignmentCollapse, setAlignmentCollapse] = React.useState('expand');

  const [alignmentScale, setAlignmentScale] = React.useState('M');
  const format = [{'field':'Revenue','fontWeight':500,'tab':1,'calc':['% Growth','$ Change','% of Revenue'],'format':'money'},
  {'field':'Gross Margin','fontWeight':500,'tab':1,'calc':['% Growth','$ Change','% of Revenue'],'format':'money'},
  {'field':'Non-Cloud Revenue','fontWeight':400,'tab':2,'calc':['% Growth','$ Change','% of Revenue'],'format':'money'},
  {'field':'Cloud Revenue','fontWeight':400,'tab':2,'calc':['% Growth','$ Change','% of Revenue'],'format':'money'},
  {'field':'Calculated Billings','fontWeight':500,'tab':1,'calc':['% Growth','$ Change','% of Revenue'],'format':'money'},
  {'field':'Current Bookings','fontWeight':500,'tab':1,'calc':['% Growth','$ Change','% of Revenue'],'format':'money'},
  {'field':'Bookings','fontWeight':500,'tab':1,'calc':['% Growth','$ Change','% of Revenue'],'format':'money'},
  {'field':'Current RPO','fontWeight':500,'tab':1,'calc':['% Growth','$ Change'],'format':'money'},
  {'field':'RPO','fontWeight':500,'tab':1,'calc':['% Growth','$ Change'],'format':'money'},
  {'field':'COGs','fontWeight':400,'tab':2,'calc':['% Growth','$ Change','% of Revenue'],'format':'money'},
  {'field':'Sales & Marketing','fontWeight':400,'tab':2,'calc':['% Growth','$ Change','% of Revenue'],'format':'money'},
  {'field':'Research & Development','fontWeight':400,'tab':2,'calc':['% Growth','$ Change','% of Revenue'],'format':'money'},
  {'field':'Sales & Marketing','fontWeight':400,'tab':2,'calc':['% Growth','$ Change','% of Revenue'],'format':'money'},
  {'field':'General & Administrative','fontWeight':400,'tab':2,'calc':['% Growth','$ Change','% of Revenue'],'format':'money'},
  {'field':'Operating Income','fontWeight':500,'tab':1,'calc':['% Growth','$ Change','% of Revenue'],'format':'money'},
  {'field':'Net Income','fontWeight':500,'tab':1,'calc':['% Growth','$ Change','% of Revenue'],'format':'money'},
  {'field':'Operating Cash Flow','fontWeight':500,'tab':1,'calc':['% Growth','$ Change','% of Revenue'],'format':'money'},
  {'field':'Capital Expenditures','fontWeight':400,'tab':2,'calc':['% Growth','$ Change','% of Revenue'],'format':'money'},
  {'field':'Shares Outstanding','fontWeight':400,'tab':1,'calc':['% Growth','$ Change'],'format':'value'},
  {'field':'Customers >$100k','fontWeight':400,'tab':1,'calc':['% Growth','$ Change'],'format':'value'},
  {'field':'Payback Period','fontWeight':400,'tab':1,'calc':['$ Change'],'format':'value'},
  {'field':'Cloud Mix','fontWeight':400,'tab':1,'calc':['$ Change'],'format':'percent'},
  {'field':'Free Cash Flow','fontWeight':500,'tab':1,'calc':['% Growth','$ Change','% of Revenue'],'format':'money'},
  {'field':'ARR','fontWeight':500,'tab':1,'calc':['% Growth','$ Change','% of Revenue'],'format':'money'},
  {'field':'Cloud ARR','fontWeight':400,'tab':2,'calc':['% Growth','$ Change','% of Revenue'],'format':'money'},
  {'field':'Non-Cloud ARR','fontWeight':400,'tab':2,'calc':['% Growth','$ Change','% of Revenue'],'format':'money'},
  ]
  const validFields = format.map(item => item.field);
      
  useEffect(() => {
    const fetchData = async () => {
      try {
        const dynamicData = await import(`../data/stockpage/financial/${alignment}/${ticker['ticker']}.json`);
  
        if (Array.isArray(dynamicData.default.data)) {
          const validFields = format.map(item => item.field);  // Extract valid fields from format
          const filteredData = dynamicData.default.data.filter(item => validFields.includes(item.field));  // Filter data
          const mappingArray = dynamicData.default.mappingTable;
          setData(filteredData);
          setMapping(mappingArray)
          const revenueData = filteredData.find(item => item.field === 'Revenue');
          if (revenueData) {
            const lastRevenue = revenueData[Object.keys(revenueData).pop()];
            if (lastRevenue > 5000000000) {
              setAlignmentScale('B');
            }
          }
        } else {
          console.error('Unexpected data format:', dynamicData);
        }
      } catch (error) {
        console.error(`Error fetching data for ${ticker}:`, error);
      }
    };

    fetchData();
  }, [ticker, alignment]);
  const [open, setOpen] = useState(true); // State to manage the open/closed state
  const [rowCalculationMethods, setRowCalculationMethods] = useState({
    'ARR': '% Growth', 
    'Cloud ARR': '% Growth', 
    'Non-Cloud ARR': '% Growth', 
    'Calculated Billings': '% Growth',
    'Current Bookings': '% Growth', 
    'Bookings': '% Growth',  
    'Current RPO': '% Growth', 
    'RPO': '% Growth', 
    'Non-Cloud Revenue': '% Growth', 
    'Cloud Revenue': '% Growth', 
    'Revenue': '% Growth',
    'Gross Margin': '% of Revenue',
    'COGs': '% of Revenue',
    'Sales & Marketing': '% of Revenue',
    'Research & Development': '% of Revenue',
    'General & Administrative': '% of Revenue',
    'Operating Income': '% of Revenue',
    'Net Income': '% of Revenue',
    'Operating Cash Flow': '% of Revenue',
    'Capital Expenditures': '% of Revenue',
    'Free Cash Flow': '% of Revenue',
    'Shares Outstanding': '% Growth',
    'Customers >$100k': '% Growth',
    'Payback Period': '$ Change',
    'Cloud Mix': '$ Change',
  });

  
  const years = data
  .filter(item => item.field === 'Revenue') // Step 1: Filter elements with field === 'Revenue'
  .reduce((acc, item) => {                  // Step 2: Reduce to accumulate keys
    Object.keys(item).forEach(key => {
      if (key !== 'field' && !acc.includes(key)) {
        acc.push(key);                      // Step 3: Add key if not already included
      }
    });
    return acc;
  }, []);
  const [filteredYears, setFilteredYears] = useState(years);


  const [mappingArray,setMapping] = useState([]);
  

  const calculateMaxSliderValue = () =>{ 
    if (alignment === 'annual'){
      return Math.min(mappingArray.length,20)
    }
    else
    {
      return Math.min(mappingArray.length,20)
    }

  }
  useEffect(() => {
    const maxSliderValue = calculateMaxSliderValue();
  const initialSliderValue = Math.min(8, maxSliderValue);
  setValue(initialSliderValue);
  }, [mappingArray]);
  const maxSliderValue = calculateMaxSliderValue();
  const initialSliderValue = Math.max(6, maxSliderValue);
  
  const [sliderValue, setValue] = useState(initialSliderValue);
  // Function to handle button clicks and update the selected button state
  const slicedMapping = mappingArray.slice(-sliderValue)
  
  const handleChangeCollapse = (event, newAlignment) => {
    setAlignmentCollapse(newAlignment);
  };
  const handleChangeScale = (event, newAlignment) => {
    setAlignmentScale((prevAlignment) => {
      // If the newAlignment is already selected, return the previous alignment to keep it selected
      if (prevAlignment === newAlignment) {
        return prevAlignment;
      }
      // Otherwise, update the alignment to the new value
      return newAlignment;
    });
  };
  const handleSliderChange = (event, newValue) => {
    setValue(newValue);

  };
  const handleInputChange = (event) => {
    setValue(event.target.value === '' ? '' : Number(event.target.value));
  };
  const handleBlur = () => {
    if (sliderValue < 2 || isNaN(sliderValue)) {
      setValue(2);
    } else if (sliderValue > maxSliderValue) {
      setValue(maxSliderValue);
    }
  };

  
  const getPreviousQuarter = (year) => {
    if (alignment === 'annual') {
      return `FY${parseInt(year.slice(2)) - 1}`;
    } else {
      const quarterYear = year.split('-')[1];
      const quarter = year.split('-')[0];
      let prevQuarter = quarter;
      let prevYear = quarterYear;
  
      // Update previous quarter and year based on current quarter
      switch (quarter) {
        case 'Q1':
          prevQuarter = 'Q4';
          break;
        case 'Q2':
          prevQuarter = 'Q1';
          break;
        case 'Q3':
          prevQuarter = 'Q2';
          break;
        case 'Q4':
          prevQuarter = 'Q3';
          break;
        default:
          break;
      }
  
      // If the previous quarter is Q4, decrement the year
      if (prevQuarter === 'Q4') {
        prevYear = parseInt(quarterYear) - 1;
      }
  
      return `${prevQuarter}-${prevYear}`;
    }
  };
  const getPreviousYear = (year) => {
    if (alignment === 'annual') {
      return `FY${parseInt(year.slice(2)) - 1}`;
    } else {
      const quarterYear = year.split('-')[1];
      const quarter = year.split('-')[0];
      let prevQuarter = quarter;
      let prevYear = quarterYear;
  
      // Update previous quarter and year based on current quarter
      switch (quarter) {
        case 'Q1':
          prevQuarter = 'Q1';
          break;
        case 'Q2':
          prevQuarter = 'Q2';
          break;
        case 'Q3':
          prevQuarter = 'Q3';
          break;
        case 'Q4':
          prevQuarter = 'Q4';
          break;
        default:
          break;
      }

      prevYear = parseInt(quarterYear) - 1;
  
      return `${prevQuarter}-${prevYear}`;
    }
  };
  const calculateYoYGrowth = (current, previous) => {
    if (current === undefined || previous === undefined) return '';
    if (previous === 0) return '';
    return formatPercentage((current - previous) / previous);
  };
  const calculatePercentageOfRevenue = (current, revenue) => {
    if (current === undefined || revenue === undefined) return '';
    if (revenue === 0) return ''; // Avoid division by zero
    return formatPercentage(current / revenue)
  };
  const calculateYoYValue = (current, revenue,format) => {
    if (current === undefined || revenue === undefined) return '';
    if (revenue === 0) return ''; // Avoid division by zero
    const growth = current - revenue
    if (format ==='percent'){
      if (growth >0){
        return `+${formatNumber(current - revenue,format,alignmentScale)}pts`;
      }
      else{
      return `${formatNumber(current - revenue,format,alignmentScale)}pts`;
      }
    }
    if (growth >0){
      return `+${formatNumber(current - revenue,format,alignmentScale)}`;
    }
    else{
    return `${formatNumber(current - revenue,format,alignmentScale)}`;
    }
  };
  const formatPercentage = (num) =>{
    if (num < 1){
      return `${(num* 100).toFixed(1)}%`;
    }
    else{
      return `${(num* 100).toFixed(0)}%`;
    }
    
  }
  const formatNumber = (num,format,scale,curr) => {
    
    if (format ==='value'){
      if (typeof num !== 'number' || isNaN(num)) {
        return "";
      }

      if (num < 0) {
        if (Math.abs(num) >= 1000000000) {
          return `-${(Math.abs(num) / 1000000).toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 })}M`;
        } else if (Math.abs(num) > 1000000) {
          return `-${(Math.abs(num) / 1000000).toFixed(1)}M`} 
        else {
          return `${(num).toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 })}`
        }
      } else if (num >= 1000000000) {
        return `${(num / 1000000).toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 })}M`;
      } else if (num > 1000000) {
        return `${(num / 1000000).toFixed(1)}M`;
      } else{
        return `${(num).toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 })}`
      }
    }
    else if (format ==='percent'){
      if (typeof num !== 'number' || isNaN(num)) {
        return "";
      }
      if (num < 1){
        return `${(num* 100).toFixed(1)}%`;
      }
      else{
        return `${(num* 100).toFixed(0)}%`;
      }
    }
    else if (format ==='money'&&scale==='M'){
      if (typeof num !== 'number' || isNaN(num)) {
        return "";
      }
      if (num < 0) {

        if (Math.abs(num) >= 1000000000) {
          return `-$${(Math.abs(num) / 1000000).toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 })}M`;
        } else {
          return `-$${(Math.abs(num) / 1000000).toFixed(1)}M`;
        }
      } 

      else if (num >= 1000000000) {
        return `$${(num / 1000000).toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 })}M`;
      } else {
        return `$${(num / 1000000).toFixed(1)}M`;
      }}
      else if (format ==='money' &&scale==='B'){
        if (typeof num !== 'number' || isNaN(num)) {
          return "";
        }
        if (num < 0) {
  
          if (Math.abs(num) >= 10000000000) {
            return `-$${(Math.abs(num) / 1000000000).toLocaleString(undefined, { minimumFractionDigits: 1, maximumFractionDigits: 1 })}B`;
          } else {
            return `-$${(Math.abs(num) / 1000000000).toFixed(2)}B`;
          }
        } 
  
        else if (num >= 10000000000) {
          return `$${(num / 1000000000).toLocaleString(undefined, { minimumFractionDigits: 1, maximumFractionDigits: 1 })}B`;
        } else {
          return `$${(num / 1000000000).toFixed(2)}B`;
        }}
  };
  const handleChangeCalculationMethod = (e, field) => {
    const { value } = e.target;
    setRowCalculationMethods(prevState => ({
      ...prevState,
      [field]: value // Update state based on unique identifier (field)
    }));
  };
  const handleToggleCollapse = () => {
    setOpen(!open);
  };
  const tableWidth = slicedMapping.length *85 + 400 
  const tableWidthContainer = Math.max(tableWidth,800) 
  return(
  <Box sx={{width: tableWidthContainer + 50,maxWidth:'100%'}}>
   <Box sx={{ position: 'relative', marginTop:'6px',paddingLeft: '1%', marginLeft: '0px' }}>
   <Paper elevation={2} sx={{backgroundColor: theme.background.backgroundColor, paddingBottom: '4px', maxWidth: tableWidthContainer + 50, paddingTop: '0px', marginTop: '6px', marginBottom: '0px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
    {!open && (
          <Box
            className='label'
            sx={{
              paddingTop: '6px',
              paddingBottom: '6px',
              paddingLeft: '1.5%',
              fontWeight: 'bold',
            }}
          >
            <Box sx={{ paddingTop: '2px', fontSize: '12px',minWidth:'200px' }}>
              Expand Dashboard Filters
            </Box>
          </Box>
        )}
        <Collapse in={open} sx={{ width: '95%' }}>
    <Box sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap',justifyContent:'center' }}>
    <Paper elevation={theme.palette.mode=='dark'?4:2} sx={{ backgroundColor: theme.background.backgroundColor,width: '390px', marginLeft: '1%', paddingTop: '8px', marginTop: '6px', paddingLeft: '1%', paddingRight: '2%', paddingBottom: '0px', display: 'flex', alignItems: 'center' }}>
      <Box sx={{ maxWidth: '50px', fontSize: '12px', fontWeight: 'bold', textAlign: 'center',  paddingBottom: '10px' }}>Period Filters</Box>
      <Box sx={{ display: 'flex', justifyContent: 'flex-start', flex: 1, flexWrap: 'wrap' }}>

          <Box>

          <Box sx={{ width: '180px' }}>
          <Box  sx={{backgroundColor:theme.tableHeader.highlight, height:'41px',py: 0.2, px: 1 ,marginLeft:'1%', marginRight:'4%',border: `1px solid ${theme.palette.mode === 'dark' ? '#333' : '#ddd'}`, borderRadius: '4px' }}>
            <Grid container spacing={2} alignItems="center">
              <Grid item>
              <IconButton color={'default'}  sx={{ py: 0, px: 0 }}>
                <ViewTimelineIcon />
                </IconButton>
              </Grid>
              <Grid item xs>
                <Slider
                  value={typeof sliderValue === 'number' ? sliderValue : 0}
                  onChange={handleSliderChange}
                  aria-labelledby="input-slider"
                  min={2}
                  max={maxSliderValue}
                />
              </Grid>
              <Grid item>
              <Input
                value={sliderValue}
                size="small"
                onChange={handleInputChange}
                onBlur={handleBlur}
                inputProps={{
                  step: 1,
                  min: 2,
                  max: {maxSliderValue},
                  type: 'text', // Change type to text
                  pattern: '\\d*', // Pattern to only allow digits
                  'aria-labelledby': 'input-slider',
                }}
                sx={{ width: '20px', '&::-webkit-inner-spin-button, &::-webkit-outer-spin-button': { display: 'none' } }}
              />
                
              </Grid>
            </Grid>
            </Box>
            <Box sx={{ marginTop:'2px',paddingBottom:'4px',fontSize: '11px', alignItems: 'center', textAlign: 'center' }}>Display Last {sliderValue} {alignment === "annual" ? "Years" : "Quarters"}</Box>
            
          </Box>
          </Box>
          <Box>

            <Box sx={{ marginLeft: '1%', marginRight: '1%', marginBottom: 0 }}>
            <ToggleButtonGroup
              sx={{
                backgroundColor:theme.tableHeader.highlight,
                '& .Mui-selected': {
                  color: theme.toggleButton.selectedTextColor,
                  backgroundColor: theme.toggleButton.selectedBackgroundColor,
                },
              }}
              value={alignment}
              exclusive
              onChange={handleChange}
              size="small" // Add this line to make the buttons smaller
              aria-label="Time Period Toggle"
            >
            <ToggleButton style={{ textTransform: 'none' }} value="annual" sx={{ py: 0.1, px: 1.1 }}>
              <Tooltip title="Annual Data">
              <IconButton color={alignment === 'annual' ? 'primary' : 'default'}  sx={{ py: 0, px: 0 }}>
                <Box>
                < CalendarTodayIcon />
                <Typography variant="body2" align="center" sx={{ mt: -.8, fontWeight: 'bold', fontSize: '10px' }}>FY</Typography>
                </Box>
              </IconButton>
            </Tooltip>
              </ToggleButton>
              <ToggleButton style={{ textTransform: 'none' }} value="quarterly" sx={{ py: 0.1, px: 1.1 }}>
              <Tooltip title="Quarterly Data">
              <IconButton color={alignment === 'quarterly' ? 'primary' : 'default'}  sx={{ py: 0, px: 0 }}>
                <Box>
                < EventNoteIcon />
                <Typography variant="body2" align="center" sx={{ mt: -1, fontWeight: 'bold', fontSize: '10px' }}>Q4</Typography>
                </Box>
              </IconButton>
            </Tooltip>
              </ToggleButton>
            </ToggleButtonGroup>
             </Box>
             <Box sx={{ paddingBottom:'4px',marginTop:'2px',fontSize: '11px', alignItems: 'center', textAlign: 'center' }}>Time Period</Box>
             </Box>
             </Box>
          </Paper>
          
   
          <Paper elevation={theme.palette.mode=='dark'?4:2} sx={{ backgroundColor: theme.background.backgroundColor,width: '300px', marginLeft: '1%', paddingTop: '8px', marginTop: '6px', paddingLeft: '1%', paddingRight: '2%', paddingBottom: '0px', display: 'flex', alignItems: 'center' }}>
            <Box sx={{ maxWidth: '55px', fontSize: '12px', fontWeight: 'bold', textAlign: 'center',  paddingBottom: '10px' }}>Field Selection</Box>
            <Box sx={{ display: 'flex', justifyContent: 'flex-start', flex: 1, flexWrap: 'wrap' }}>

            <Box sx={{ marginLeft: '2%', marginRight: '2%', marginBottom: 0 }}>
            <ToggleButtonGroup
              sx={{
                backgroundColor:theme.tableHeader.highlight,
                '& .Mui-selected': {
                  color: theme.toggleButton.selectedTextColor,
                  backgroundColor: theme.toggleButton.selectedBackgroundColor,
                },
              }}
              value={alignmentCollapse}
              exclusive
              onChange={handleChangeCollapse}
              size="small" // Add this line to make the buttons smaller
              aria-label="Time Period Toggle"
            >
            <ToggleButton style={{ textTransform: 'none' }} value="expand" sx={{ py: 0.0, px: 1.1 }}>
              <Tooltip title="Calculation Field">
              <IconButton color={alignmentCollapse === 'expand' ? 'primary' : 'default'}  sx={{ py: 0, px: 0 }}>
                <Box>
                < CalculateIcon />
                <Typography variant="body2" align="center" sx={{ mt: -.8, fontWeight: 'bold', fontSize: '10px' }}>Calc</Typography>
                </Box>
              </IconButton>
            </Tooltip>
              </ToggleButton>
              <ToggleButton style={{ textTransform: 'none' }} value="collapse" sx={{ py: 0.0, px: 1.1 }}>
              <Tooltip title="Summary Values">
              <IconButton color={alignmentCollapse === 'collapse' ? 'primary' : 'default'}  sx={{ py: 0, px: 0 }}>
                <Box>
                < SummarizeIcon />
                <Typography variant="body2" align="center" sx={{ mt: -1, fontWeight: 'bold', fontSize: '10px' }}>Fields</Typography>
                </Box>
              </IconButton>
            </Tooltip>
              </ToggleButton>
            </ToggleButtonGroup>
            <Box sx={{paddingBottom:'4px', marginTop:'2px',fontSize: '11px', alignItems: 'center', textAlign: 'center' }}>Expand/Collapse</Box>
             
             </Box>
             <Box sx={{ marginLeft: '2%', marginRight: '2%', marginBottom: 0 }}>
            <ToggleButtonGroup
              sx={{
                backgroundColor:theme.tableHeader.highlight,
                '& .Mui-selected': {
                  color: theme.toggleButton.selectedTextColor,
                  backgroundColor: theme.toggleButton.selectedBackgroundColor,

                },
              }}
              value={alignmentScale}
              exclusive
              onChange={handleChangeScale}
              size="small" // Add this line to make the buttons smaller
              aria-label="Time Period Toggle"
            >
            <ToggleButton style={{ textTransform: 'none' }} value="M" sx={{ py: 1.0, px: 1.0 }}>
              <Tooltip title="Format values in millions">
              <IconButton color={alignmentScale === 'M' ? 'primary' : 'default'}  sx={{ py: 0, px: 0 }}>
                <Box>
                <Typography align="center" sx={{ mt: -.8, fontWeight: 'bold', fontSize: '16px',paddingTop:'5px',paddingBottom:'5px'  }}>$M</Typography>
                <Typography variant="body2" align="center" sx={{ mt: -.8, fontWeight: 'bold', fontSize: '10px' }}></Typography>
                </Box>
              </IconButton>
            </Tooltip>
              </ToggleButton>
              <ToggleButton style={{ textTransform: 'none' }} value="B" sx={{ py: 1.0, px: 1.0 }}>
              <Tooltip title="Format values in billions">
              <IconButton color={alignmentScale === 'B' ? 'primary' : 'default'}  sx={{ py: 0, px: 0 }}>
                <Box>
                <Typography align="center" sx={{ mt: -.8, fontWeight: 'bold', fontSize: '16px',paddingTop:'5px',paddingBottom:'5px'  }}>$B</Typography>
                <Typography variant="body2" align="center" sx={{ mt: -.8, fontWeight: 'bold', fontSize: '10px' }}></Typography>
                </Box>
              </IconButton>
            </Tooltip>
              </ToggleButton>
            </ToggleButtonGroup>
            <Box sx={{ paddingBottom:'4px',marginTop:'2px',fontSize: '11px', alignItems: 'center', textAlign: 'center' }}>Format values</Box>
             
             </Box>
             </Box>
          </Paper>
          </Box>
          <Box sx={{ marginTop:'6px'}}></Box>
        </Collapse>
        <IconButton
        sx={{
          p: 0.1,
          fontSize: 15,
          marginTop: '2px',
          marginRight: '2px',


        }}
        onClick={handleToggleCollapse}
        aria-label="toggle-collapse"
      >
        {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
      </IconButton>
        </Paper>
    </Box>
    <Paper elevation={3} sx={{ maxWidth:tableWidthContainer+50, paddingBottom:'0px',paddingTop: '6px', marginTop: '6px', flex: 1, paddingLeft: '1%',marginLeft:'1%',paddingRight:'1%' }}>
      <Box>
        <Box sx={{ color: theme.palette.primary.main, fontSize: '24px', fontWeight: '600' }}>Financial Model
        </Box>
      </Box>
      <TableContainer component={Paper} sx={{ paddingBottom:'5px',cursor: 'pointer', marginTop: '4px', overflowY: 'auto', overflowX: 'auto', maxWidth: tableWidth }} ref={tableContainerRef}>
        
      <Table size='small' aria-label="acquisition table">
  <TableHead>
    <TableRow sx={{ backgroundColor: theme.tableHeader.color, fontWeight: 'bold', position: 'sticky', top: 0, zIndex: 2 }}>
    <TableCell sx={{ minWidth:'220px',textAlign: 'left', backgroundColor: theme.tableHeader.color, fontWeight: 'bold', left: 0,position:'sticky' }}>
          <Typography variant="subtitle2" sx={{ marginBottom: '-3px' }}>{alignment=='annual'?'Fiscal Year':'Fiscal Quarter'}</Typography>
        <Typography variant="body2" color="textSecondary" sx={{fontWeight:400,fontSize:'10px'}}>{alignment=='annual'?'Year Ended':'Quarter Ended'}</Typography></TableCell> 
      {slicedMapping.map((item) => (
        <TableCell key={item.period} sx={{ textAlign: 'right', backgroundColor: theme.tableHeader.color, fontWeight: 'bold', paddingRight: '20px' }}>
          <Typography variant="subtitle2" sx={{ marginBottom: '-3px' }}>{item.period}</Typography>
          <Typography variant="body2" color="textSecondary" sx={{fontWeight:400,fontSize:'10px'}}>{item.endDate}</Typography>
        </TableCell>
      ))}
    </TableRow>
  </TableHead>
  <TableBody sx={{ position: 'relative', zIndex: 1 }}>
    {data.flatMap((row, index) => [
      <TableRow key={`row-${index}`} sx={{ backgroundColor:format.find(item => item.field === row.field)?.fontWeight===500? theme.tableHeader.group: alignmentCollapse==='expand'? theme.tableHeader.highlight:theme.background.default,fontWeight: 'bold', ':hover': {
        backgroundColor: theme.tableHeader.highlightDark}}}>
        <TableCell sx={{ border: alignmentCollapse === 'expand' ? 'default' : 'none',backgroundColor:'inherit', fontWeight: format.find(item => item.field === row.field)?.fontWeight || 'normal', paddingLeft: format.find(item => item.field === row.field)?.tab*2, paddingTop: '2px', paddingBottom: '0px', textAlign: 'left', position: 'sticky', left: 0 }}>{row.field}</TableCell> {/* Add position: sticky and left: 0 */}
        {slicedMapping.map((item, i) => (
          <TableCell key={`data-${index}-${i}`} sx={{ border: alignmentCollapse === 'expand' ? 'default' : 'none',fontWeight: format.find(item => item.field === row.field)?.fontWeight || 'normal', paddingTop: '2px',paddingBottom: '0px', textAlign: 'right' }}>
            {format.find(item => item.field === row.field)?.format ==='money' ? formatNumber(row[item.period], format.find(item => item.field === row.field)?.format, alignmentScale) : formatNumber(row[item.period], format.find(item => item.field === row.field)?.format)}
          </TableCell>
        ))}
      </TableRow>,
      alignmentCollapse==='expand' && (
      <TableRow key={`growth-${index}`} sx={{backgroundColor:theme.background.default, fontStyle: 'italic',':hover': {
        backgroundColor: theme.tableHeader.highlightDark}}}>
        <TableCell sx={{ backgroundColor:'inherit', paddingTop: '0px',paddingBottom: '0px',paddingLeft: format.find(item => item.field === row.field)?.tab*1.5, textAlign: 'left', border: 'none', position: 'sticky', left: 0 }}>
          <FormControl sx={{ minWidth: 120, border: 'none' }}>
            <Select 
              sx={{  fontWeight:250,fontSize:'14px',height: '20px', border:'none', boxShadow: 'none',
              '.MuiOutlinedInput-notchedOutline': {
                border: 0,}}}
              size={'small'} value={rowCalculationMethods[row.field] || '% Growth'} // Access state based on unique identifier (field)
              onChange={(e) => handleChangeCalculationMethod(e, row.field)} // Pass field as identifier
            >
              {format.find(item => item.field === row.field)?.calc.includes("% Growth") && (
              <MenuItem sx={{ fontSize:'14px',paddingTop: '0px',paddingBottom: '0px'}} value={'% Growth'}>YoY % Change</MenuItem>)}
              {format.find(item => item.field === row.field)?.calc.includes("$ Change") && (
              <MenuItem sx={{ fontSize:'14px',paddingTop: '0px',paddingBottom: '0px'}} value={'$ Change'}>YoY Change</MenuItem>)}
              {format.find(item => item.field === row.field)?.calc.includes("% Growth") && alignment==='quarterly' && (
              <MenuItem sx={{ fontSize:'14px',paddingTop: '0px',paddingBottom: '0px'}} value={'% QoQ Growth'}>QoQ % Change</MenuItem>)}
              {format.find(item => item.field === row.field)?.calc.includes("$ Change") && alignment==='quarterly' && (
              <MenuItem sx={{ fontSize:'14px',paddingTop: '0px',paddingBottom: '0px'}} value={'$ QoQ Change'}>QoQ Change</MenuItem>)}
              {format.find(item => item.field === row.field)?.calc.includes("% of Revenue") && (
              <MenuItem sx={{ fontSize:'14px',paddingTop: '0px',paddingBottom: '0px'}} value={'% of Revenue'}>% of Revenue</MenuItem>)}
            </Select>
          </FormControl>
        </TableCell>
        {slicedMapping.map((item, i) => (
          <TableCell key={`growth-data-${index}-${i}`} sx={{ fontWeight:250,paddingTop: '0px',paddingBottom: '0px',textAlign: 'right', border: 'none' }}>
            {rowCalculationMethods[row.field] === '% Growth' // Update to use row.field as identifier
              ? calculateYoYGrowth(row[item.period], row[getPreviousYear(item.period)])
              : rowCalculationMethods[row.field] === '$ Change' ? calculateYoYValue(row[item.period], row[getPreviousYear(item.period)], format.find(item => item.field === row.field)?.format)
              : rowCalculationMethods[row.field] === '% QoQ Growth' ? calculateYoYGrowth(row[item.period], row[getPreviousQuarter(item.period)])
              : rowCalculationMethods[row.field] === '$ QoQ Change' ? calculateYoYValue(row[item.period], row[getPreviousQuarter(item.period)], format.find(item => item.field === row.field)?.format)
              : calculatePercentageOfRevenue(row[item.period], data.find(item => item.field === 'Revenue')[item.period])}
          </TableCell>
        ))}
      </TableRow>),
      <TableRow key={`spacer-${index}`} sx={{fontStyle: 'italic','&:hover': {backgroundColor: 'inherit'}}}>
        <TableCell sx={{border: alignmentCollapse === 'expand' ? 'default' : 'none',paddingTop:format.find(item => item.field === row.field)?.tab>1?'1px':'3px',paddingBottom: format.find(item => item.field === row.field)?.tab>1?'2px':'3px',paddingLeft: '18px',textAlign: 'left'}}/>
        {slicedMapping.map((item, i) => (
          <TableCell key={`spacer-data-${index}-${i}`} sx={{border: alignmentCollapse === 'expand' ? 'default' : 'none',paddingTop:format.find(item => item.field === row.field)?.tab>1?'1px':'3px',paddingBottom: format.find(item => item.field === row.field)?.tab>1?'2px':'3px',paddingLeft: '18px',textAlign: 'left'}}/>
        ))}
      </TableRow>
    ])}
  </TableBody>
</Table>
</TableContainer>

      <Box sx={{ paddingBottom: '1vh' }}></Box>
    </Paper>
  </Box>
      )}
      export default NewStockPage;