import React from 'react';
import { IconButton,AppBar, Typography, useTheme,Link } from '@mui/material';
import TwitterFollowButton from "../components/TwitterFollowButton";
import LinkedinFollowButton from './LinkedinFollowButton';

import Brightness4Icon from '@mui/icons-material/Brightness4';
import Box from '@mui/material/Box';

function Footer({ toggleTheme })  {
  const theme = useTheme();


  return (
    <AppBar sx={{maxWidth:'100%', backgroundColor: theme.background.backgroundColor, color: theme.palette.mode === 'light' ? 'black' : 'white', paddingTop: '10px', marginTop: '30px', paddingBottom:'30px' }} position="static">
      
        <Typography sx={{ paddingLeft:'1%'}}variant="body1">
          SoftwareIQ - BETA Access
          <Box sx={{ display: 'flex', alignItems: 'center', paddingLeft:'1%',width: '96%', justifyContent: 'space-between',gap:'10%' }}>
          <Box>
            <Box> 
              About Us:
              </Box>
              <Box>
              <Link href="/privacy" color="inherit" underline="always">
                Privacy Policy 
              </Link>
              </Box>
              <Box>
              <Link href="/termsandconditions" color="inherit" underline="always">
                Terms and Conditions
              </Link>
              </Box>
            </Box>
            
            <Box>
              Follow us on Social Media:
            
            <TwitterFollowButton />
            <LinkedinFollowButton />
            </Box>
            <IconButton sx={{ marginLeft: 'auto' }} onClick={toggleTheme}>
            <Brightness4Icon />
          </IconButton>
          </Box>
          
        </Typography>

    </AppBar>
  );
}

export default Footer;