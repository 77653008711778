import React, { useEffect } from 'react';

const LinkedInFollowButton = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://platform.linkedin.com/in.js';
    script.async = true;
    script.defer = true;
    script.type = 'text/javascript';
    document.head.appendChild(script);
    return () => {
      document.head.removeChild(script);
    };
  }, []);

  return (
    <div>
      <script type="IN/FollowCompany" data-id="99443724"></script>
    </div>
  );
};

export default LinkedInFollowButton;
