import React from "react";
import { useState,useEffect } from "react";
import { useLocation } from 'react-router-dom';
import '../index.css'
import ReactGA from 'react-ga4';
import { Box,Paper,Tabs,Tab } from "@mui/material";
import { useTheme } from '@mui/material/styles';
import Index from "../components/Index";
import MetricBenchmarking from "../components/MetricBenchmarking";
import CloudService from "../components/CloudService";

import MetricsSegmentation from "../components/MetricsSegmentation"

function Benchmarking(){
  const theme = useTheme();
  const location = useLocation(); // If using React Router

  useEffect(() => {
    ReactGA.send({ hitType: 'pageview', page: location.pathname });
  }, [location.pathname]);
  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);

    // If you want to scroll to the top every time the component updates, you can include an empty dependency array []
  }, []); // The empty dependency array ensures that this effect runs only once when the component mounts
  const [selectedButton, setSelectedButton] = useState(0);

  // Function to handle button clicks and update the selected button state
  const handleButtonClick = (index) => {
    setSelectedButton(index);
  };

  return(
    <>
    

    <Paper  sx={{backgroundColor:theme.background.backgroundColor,display:'flex', justifyContent:'center'}} elevation={3} >
    <Box sx={{maxWidth:'100%'}}>
      <Box sx={{display:'flex',maxWidth:'100%', justifyContent:'center',paddingTop:'8px',marginBottom:'0px',marginLeft:'2%',color: theme.palette.primary.main, fontSize: '36px',fontWeight:'600'}} >Market Benchmarking</Box>
      <Tabs elevation={0} 
            value={selectedButton} // Bind the Tabs component to the selected tab
            onChange={(e, newValue) => setSelectedButton(newValue)} // Update selectedButton when tab changes
            variant="scrollable"
            scrollButtons
            allowScrollButtonsMobile
            sx={{backgroundColor: 'transparent' }}>
      
        { <Tab
        label="Valuation"
        style={{ textTransform: 'none' }}
          sx={{
            borderBottom: selectedButton === 0 ? `3px solid ${theme.palette.primary.main}` : 'none', 
            backgroundColor: selectedButton === 0 ? theme.toggleButton.selectedBackgroundColor : 'transparent',
            color: selectedButton === 0 ? theme.button.color : 'inherit',            
          }}
          onClick={() => handleButtonClick(0)}
        >
        </Tab>}
        
        {<Tab
        label="Segmentation"
        style={{ textTransform: 'none' }}
          sx={{
            borderBottom: selectedButton === 1 ? `3px solid ${theme.palette.primary.main}` : 'none', 
            backgroundColor: selectedButton === 1 ? theme.toggleButton.selectedBackgroundColor : 'transparent',
            color: selectedButton === 1 ? theme.button.color : 'inherit',            
          }}
          onClick={() => handleButtonClick(1)}
        >
        </Tab>}
        {<Tab
        label="Public Cloud"
        style={{ textTransform: 'none' }}
          sx={{
            borderBottom: selectedButton === 2 ? `3px solid ${theme.palette.primary.main}` : 'none', 
            backgroundColor: selectedButton === 2 ? theme.toggleButton.selectedBackgroundColor : 'transparent',
            color: selectedButton === 2 ? theme.button.color : 'inherit',            
          }}
          onClick={() => handleButtonClick(2)}
        >
        </Tab>}
        
      </Tabs>
      
    
      </Box>
    </Paper>
    <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',

        }}
      >
      <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>

    {selectedButton === 0 && <MetricBenchmarking />}
    {selectedButton === 1 && <MetricsSegmentation />}
    {selectedButton === 2 && <CloudService />}
    </Box>
    </Box>

    </>
    )
}
export default Benchmarking