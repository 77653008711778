import React from "react";
import kbmData from '../data/charts/valuation.json';
import { useLocation } from 'react-router-dom';
import {FaStore,FaDatabase, FaIndustry, FaDesktop, FaBuilding,FaCoins,FaFileInvoice,FaComments,FaCode,FaServer,FaShieldAlt,  FaUsers,FaHammer,FaNetworkWired} from 'react-icons/fa';
import { useState,useEffect } from "react";

import '../index.css'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { Switch,FormControlLabel,Select,MenuItem,ListItemText,ListItemIcon,useMediaQuery,Typography,Collapse,IconButton,Paper,List,Checkbox,Box } from "@mui/material";
import AppsIcon from '@mui/icons-material/Apps';
import { useTheme } from '@mui/material/styles';
import ChartScatterplot from './ChartScatterplot.jsx';
import LabelIcon from '@mui/icons-material/Label';


function Dashboard({categoryInput}){
  const theme = useTheme();
  const location = useLocation(); // If using React Router
  const [checkedCategory, setCheckedCategory] = React.useState([]);
  const [expandedApp, setExpandedApp] = useState(false); 
  const [expandedInfra, setExpandedInfra] = useState(false); 
  const [expandedVert, setExpandedVert] = useState(false); 
  const [metric,setMetric] = React.useState(1)
  const [yMetric,setYMetric] = React.useState(1)
  const [sliderIcon, setSliderIcon] = useState(true);
  const [sliderLabel, setSliderLabel] = useState(true);
  useEffect(() => {
    if (Array.isArray(categoryInput)) {
      setCheckedCategory(categoryInput);
    } else {
      console.error('categoryInput should be an array');
      setCheckedCategory([20]);
    }
  }, [categoryInput]);
  const metricList =[
    {"id":1,"format":"percent","dbField":"revenue","field":"Revenue"},
    {"id":2,"format":"percent","dbField":"grossMargin","field":"Gross Margin"},
    {"id":3,"format":"percent","dbField":"currentCalculatedBillings","field":"Calculated Billings"},
    {"id":4,"format":"percent","dbField":"currentRpo","field":"Current RPO"},
    {"id":5,"format":"percent","dbField":"rpo","field":"RPO"},
    {"id":6,"format":"percent","dbField":"arr","field":"ARR"},
    {"id":7,"format":"percent","dbField":"ruleOf40","field":"Rule of 40"}
  ]
  const valuationList =[
    {"id":1,"format":"percent","dbField":"evToSalesNTM","field":"EV / Sales NTM"},
    {"id":2,"format":"percent","dbField":"evToGrossMarginNTM","field":"EV / GM NTM"},
    {"id":3,"format":"percent","dbField":"evToSalesTTM","field":"EV / Sales TTM"},
    {"id":4,"format":"percent","dbField":"evToGrossMarginTTM","field":"EV / GM TTM"},
  ]
  const category = [
    {
      id: 1,
      subIndustry:'Application',
      filter: 'Application',
      label: 'Application Software',
      icon: <FaDesktop />,
    },
    {
      id: 10,
      subIndustry:'Application',
      filter: 'CRM, Marketing, and Support',
      label: 'CRM, Marketing & Support',
      icon: <FaStore />,
    },
    {
      id: 11,
      subIndustry:'Application',
      filter: 'HR, Finance & Supply Chain',
      label: 'HR, Finance & SCM',
      icon: <FaUsers />,
    },
    {
      id: 12,
      subIndustry:'Application',
      filter: 'Productivity & Content Creation',
      label: 'Productivity',
      icon: <FaFileInvoice />,
    },
    {
      id: 2,
      subIndustry:'Infrastructure',
      filter: 'Infrastructure',
      label: 'Infrastructure Software',
      icon: <FaDatabase />,
    },
    
    {
      id: 20,
      subIndustry:'Infrastructure',
      filter: 'Database & Data Platforms',
      label: 'Database & Data Platforms',
      icon: <FaDatabase />,
    },
    {
      id: 21,
      subIndustry:'Infrastructure',
      filter: 'Dev Tools & Low Code',
      label: 'Dev Tools & Low Code',
      icon: <FaCode />,
    },
    {
      id: 22,
      subIndustry:'Infrastructure',
      filter: 'IT Operations',
      label: 'IT Operations',
      icon: <FaServer />,
    },
    {
      id: 23,
      subIndustry:'Infrastructure',
      filter: 'Security & Endpoint Management',
      label: 'Security & UEM',
      icon: <FaShieldAlt />,
    },
    {
      id: 24,
      subIndustry:'Infrastructure',
      filter: 'Networking & CDN',
      label: 'Networking & CDN',
      icon: <FaNetworkWired />,
    },
    {
      id: 25,
      subIndustry:'Infrastructure',
      filter: 'CPaaS',
      label: 'CPaaS',
      icon: <FaComments />,
    },
    {
      id: 3,
      subIndustry:'Vertical',
      filter: 'Vertical',
      label: 'Vertical Software',
      icon: <FaIndustry />,
    },
    {
      id: 30,
      subIndustry:'Vertical',
      filter: 'Financials',
      label: 'Financials',
      icon: <FaCoins />,
    },
    {
      id: 31,
      subIndustry:'Vertical',
      filter: 'Engineering & Industrials',
      label: 'Engineering & Industrials',
      icon: <FaHammer />,
    },
    {
      id: 32,
      subIndustry:'Vertical',
      filter: 'Other Vertical',
      label: 'Other Vertical',
      icon: <FaIndustry />,
    },
    {
      id: 4,
      subIndustry:'Diversified',
      filter: 'Diversified',
      label: 'Diversified Software',
      icon: <FaBuilding />,
    },
  ];
  const handleIconSliderChange = (event, newValue) => {
    setSliderIcon(newValue);

  };
  const handleLabelSliderChange = (event, newValue) => {
    setSliderLabel(newValue);

  };
  const handleMetricChange = (event) => {
    
    setMetric(event.target.value);
};  
const handleYMetricChange = (event) => {
  setYMetric(event.target.value);
};  
const handleCheckedChangeCategory = (event) => {
  const { value: selectedItems } = event.target;
  
  setCheckedCategory(prevCheckedCategory => {
      // Check if '1' has been newly checked
      const oneChecked = selectedItems.includes(1) && !prevCheckedCategory.includes(1);
      const oneUnchecked = !selectedItems.includes(1) && prevCheckedCategory.includes(1);
      const twoChecked = selectedItems.includes(2) && !prevCheckedCategory.includes(2);
      const twoUnchecked = !selectedItems.includes(2) && prevCheckedCategory.includes(2);
      const threeChecked = selectedItems.includes(3) && !prevCheckedCategory.includes(3);
      const threeUnchecked = !selectedItems.includes(3) && prevCheckedCategory.includes(3);

      // Initialize the new state with the selected items
      let newState = [...selectedItems];

      if (oneChecked) {
          newState.push(10,11,12); 
      }
      if (oneUnchecked) {
        newState = newState.filter(item => ![10, 11, 12].includes(item));
      }
      if (twoChecked) {
        newState.push(20,21,22,23,24,25); 
      }
      if (twoUnchecked) {
        newState = newState.filter(item => ![20,21,22,23,24,25].includes(item));
      }
      if (threeChecked) {
        newState.push(30,31,32); 
      }
      if (threeUnchecked) {
        newState = newState.filter(item => ![30,31,32].includes(item));
      }
      if (!oneChecked && !oneUnchecked && newState.includes(10) && newState.includes(11) && newState.includes(12)&&!newState.includes(1)) {
        newState.push(1);
      }
      if (!oneChecked && !oneUnchecked && !newState.includes(10) && !newState.includes(11) && !newState.includes(12)&&newState.includes(1)) {
        newState = newState.filter(item => ![1].includes(item));
      }
      if (!twoChecked && !twoUnchecked && newState.includes(20) && newState.includes(21) && newState.includes(22) && newState.includes(23) && newState.includes(24) && newState.includes(25)&&!newState.includes(2)) {
        newState.push(2);
      }
      if (!twoChecked && !twoUnchecked && !newState.includes(20) && !newState.includes(21) && !newState.includes(22)&& !newState.includes(23)&& !newState.includes(24)&& !newState.includes(25)&&newState.includes(2)) {
        newState = newState.filter(item => ![2].includes(item));
      }
      if (!threeChecked && !threeUnchecked && newState.includes(30) && newState.includes(31) && newState.includes(32)&&!newState.includes(3)) {
        newState.push(3);
      }
      if (!threeChecked && !threeUnchecked && !newState.includes(30) && !newState.includes(31) && !newState.includes(32)&&newState.includes(3)) {
        newState = newState.filter(item => ![3].includes(item));
      }
      return newState;
  });
}

  const handleExpandAppToggle = (e) => {
    e.stopPropagation(); // Stop event propagation to prevent filtering
    setExpandedApp(!expandedApp); // Toggle the expansion state
  };
  const handleExpandInfraToggle = (e) => {
    e.stopPropagation(); // Stop event propagation to prevent filtering
    setExpandedInfra(!expandedInfra); // Toggle the expansion state
  };
  const handleExpandVertToggle = (e) => {
    e.stopPropagation(); // Stop event propagation to prevent filtering
    setExpandedVert(!expandedVert); // Toggle the expansion state
  };

    useEffect(() => {
        // Scroll to the top of the page when the component mounts
        window.scrollTo(0, 0);
    
        // If you want to scroll to the top every time the component updates, you can include an empty dependency array []
      }, []); // The empty dependency array ensures that this effect runs only once when the component mounts
    
    const [open, setOpen] = useState(true); // State to manage the open/closed state


    
    const filteredData = kbmData
      .filter((company) => {
        // Check if the category exists and matches any of the checked categories
        return checkedCategory.some((item) =>
          company.category === category.find((i) => i.id === item)?.filter
        );
      })
      .map((company) => {
        const xValue = company.valuesX[0][metricList.find((item) => item.id === metric).dbField];
        const yValue = company.valuesY[0][valuationList.find((item) => item.id === yMetric).dbField];

        if (typeof xValue !== 'undefined' && !isNaN(xValue)) {
          return {
            ticker: company.ticker, // Remap to "ticker"
            x: xValue, // Use metric dbField for x
            y: yValue, // Remap to "y"
          };
        }
      })
      .filter((item) => item !== undefined); // Filter out undefined entries
    const outputData = [{
      "id": "group A",
      "data": filteredData
  }];

      
        const shouldWrap = useMediaQuery('(max-width: 1200px)');
        const handleToggleCollapse = () => {
            setOpen(!open);
          };
          const chartMaxWidth = '2000px';
          const getFormatForMetric = (metricId) => {
            const metricItem = metricList.find(item => item.id === metricId);
            return metricItem ? metricItem.format : null;
        };
        const format = getFormatForMetric(metric);

        const currentMetric = metricList.find(item => item.id === metric)?.field
        const currentValuation = valuationList.find(item => item.id === yMetric)?.field
        
    //data = data.sort((a, b) => b["Net Revenue Retention"] - a["Net Revenue Retention"]);  
  return(

    <Box sx={{width:chartMaxWidth,maxWidth:'100%'}} >
    <Paper elevation={2} sx={{ backgroundColor: theme.background.backgroundColor,marginLeft:'1%',paddingBottom: '4px', maxWidth: chartMaxWidth, paddingTop: '0px', marginTop: '6px', marginBottom: '0px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        {!open && (
          <Box
            className='label'
            sx={{
              paddingTop: '6px',
              paddingBottom: '6px',
              paddingLeft: '1.5%',
              fontWeight: 'bold',
            }}
          >
            <Box sx={{ paddingTop: '2px', fontSize: '12px',minWidth:'200px' }}>
              Expand Dashboard Filters
            </Box>
          </Box>
        )}
        <Collapse in={open} sx={{ width: '95%' }}>
        <Box sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' ,justifyContent:'center'}}>
        <Paper elevation={theme.palette.mode=='dark'?4:2} sx={{ backgroundColor: theme.background.backgroundColor,width: '400px', marginLeft: '1%', paddingTop: '8px', marginTop: '6px', paddingLeft: '1%', paddingRight: '2%', paddingBottom: '0px', display: 'flex', alignItems: 'center' }}>
          <Box sx={{ maxWidth: '65px', fontSize: '12px', fontWeight: 'bold', textAlign: 'center',  paddingBottom: '10px' }}>Dashboard Filters</Box>
          <Box sx={{ display: 'flex', justifyContent: 'flex-start', flex: 1, flexWrap: 'wrap' }}>
          
              <Box sx={{marginBottom: '0px', marginLeft: '2%', marginRight: '1%' }}>
                <List size='small' disablePadding sx={{ backgroundColor:theme.tableHeader.highlight,width:'300px', display: 'flex', flexDirection: 'row'}}>
                <Select size="small" 
                  sx={{width:'300px'}}
                  multiple
                  value={checkedCategory} // Use checkedCategory here
                  onChange={handleCheckedChangeCategory} // Use handleCheckedChangeCategory here
                  renderValue={(selected) => {
                    if (selected.length === 0) {
                      return "No Items Selected";
                    }
                    else if (selected.includes(10) && selected.includes(11) && selected.includes(12)&&selected.includes(20) && selected.includes(21) && selected.includes(22)&& selected.includes(23)&& selected.includes(24)&& selected.includes(25)&&selected.includes(30) && selected.includes(31) && selected.includes(32)&& selected.includes(4)) {
                      return <Typography variant="body2">All Categories: No Filters Applied</Typography>;
                    }
                    else if (selected.includes(10) && selected.includes(11) && selected.includes(12)&&!selected.includes(2)&&!selected.includes(3)&&!selected.includes(4)&&!selected.includes(20)&&!selected.includes(21)&&!selected.includes(22)&&!selected.includes(23)&&!selected.includes(24)&&!selected.includes(25)&&!selected.includes(30)&&!selected.includes(31)&&!selected.includes(32)){
                      return <Typography variant="body2">Application Software</Typography>;
                    }
                    else if (selected.includes(20) && selected.includes(21) && selected.includes(22)&& selected.includes(23)&& selected.includes(24)&& selected.includes(25)&&!selected.includes(1)&&!selected.includes(3)&&!selected.includes(4)&&!selected.includes(10)&&!selected.includes(11)&&!selected.includes(12)&&!selected.includes(30)&&!selected.includes(31)&&!selected.includes(32)){
                      return <Typography variant="body2">Infrastructure Software</Typography>;
                    }
                    else if (selected.includes(30) && selected.includes(31) && selected.includes(32)&&!selected.includes(2)&&!selected.includes(1)&&!selected.includes(4)&&!selected.includes(20)&&!selected.includes(21)&&!selected.includes(22)&&!selected.includes(23)&&!selected.includes(24)&&!selected.includes(25)&&!selected.includes(10)&&!selected.includes(11)&&!selected.includes(12)){
                      return <Typography variant="body2">Vertical Software</Typography>;
                    }
                    else if (selected.length === 1) {
                      const selectedItem = category.find(item => item.id === selected[0]); // Use category here
                      return <Typography variant="body2">{selectedItem.label}</Typography>;
                    } else {
                      return <Typography variant="body2">(multiple items selected)</Typography>;
                    }
                  }}
                  MenuProps={{
                    PaperProps: {
                      style: {
                        maxHeight: 250,
                        width: 'auto',
                      },
                    },
                  }}
                >
                  {category.slice(0,1).map((item) => (
                    <MenuItem size="small" key={item.id} value={item.id} sx={{ height:'40px'}}>
                      
                      <ListItemIcon size="small" >
                        {item.icon}
                      </ListItemIcon>
                      
                      <ListItemText primary={
                        <Typography variant="body2"> {/* Set variant="body2" for smaller text */}
                          {item.label}
                        </Typography>
                      } />
                      <IconButton size="small" onClick={handleExpandAppToggle}>  
                      {expandedApp ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                      </IconButton> 
                      <Checkbox  indeterminate={(checkedCategory.indexOf(1)!== -1 && 
                        (checkedCategory.indexOf(10)=== -1||checkedCategory.indexOf(11)=== -1||checkedCategory.indexOf(12)=== -1))
                        ||(checkedCategory.indexOf(1)=== -1 && 
                        (checkedCategory.indexOf(10)!== -1||checkedCategory.indexOf(11)!== -1||checkedCategory.indexOf(12)!== -1))} 
                        size="small" checked={checkedCategory.indexOf(item.id) !== -1} /> {/* Use checkedCategory here */}
                    </MenuItem>
                  ))}
                  {expandedApp && category.slice(1,4).map((item) => (
                    <MenuItem size="small" key={item.id} value={item.id} sx={{height:'26px'}}>
                    <Box sx={{width:'18px'}}></Box> 
                    <ListItemIcon  size="small" >
                      {item.icon}
                    </ListItemIcon>
                    
                    <ListItemText  primary={
                      <Typography variant="body2"> {/* Set variant="body2" for smaller text */}
                        {item.label}
                      </Typography>
                    } />
                    <Checkbox size="small" checked={checkedCategory.indexOf(item.id) !== -1} /> {/* Use checkedCategory here */}
                  </MenuItem>                 
                  ))}
                  {category.slice(4,5).map((item) => (
                    <MenuItem size="small" key={item.id} value={item.id} sx={{ height:'40px'}}>
                      
                    <ListItemIcon size="small" >
                      {item.icon}
                    </ListItemIcon>
                    <ListItemText primary={
                      <Typography variant="body2"> {/* Set variant="body2" for smaller text */}
                        {item.label}
                      </Typography>
                    } />
                    <IconButton size="small" onClick={handleExpandInfraToggle}>  
                      {expandedInfra ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                      </IconButton> 
                    <Checkbox indeterminate={(checkedCategory.indexOf(2)!== -1 && 
                        (checkedCategory.indexOf(20)=== -1||checkedCategory.indexOf(21)=== -1||checkedCategory.indexOf(22)=== -1||checkedCategory.indexOf(23)=== -1||checkedCategory.indexOf(24)=== -1||checkedCategory.indexOf(25)=== -1))
                        ||(checkedCategory.indexOf(2)=== -1 && 
                        (checkedCategory.indexOf(20)!== -1||checkedCategory.indexOf(21)!== -1||checkedCategory.indexOf(22)!== -1||checkedCategory.indexOf(23)!== -1||checkedCategory.indexOf(24)!== -1||checkedCategory.indexOf(25)!== -1))} 
                        size="small" checked={checkedCategory.indexOf(item.id) !== -1} /> {/* Use checkedCategory here */}
                  </MenuItem>
                  ))}
                  {expandedInfra && category.slice(5,11).map((item) => (
                    <MenuItem size="small" key={item.id} value={item.id} sx={{ height:'26px'}}>
                    <Box sx={{width:'18px'}}></Box> 
                    <ListItemIcon size="small" >
                      {item.icon}
                    </ListItemIcon>
                    <ListItemText primary={
                      <Typography variant="body2"> {/* Set variant="body2" for smaller text */}
                        {item.label}
                      </Typography>
                    } />
                    <Checkbox size="small" checked={checkedCategory.indexOf(item.id) !== -1} /> {/* Use checkedCategory here */}
                  </MenuItem>
                  ))}
                  {category.slice(11,12).map((item) => (
                    <MenuItem size="small" key={item.id} value={item.id} sx={{ height:'40px'}}>
                      
                    <ListItemIcon size="small" >
                      {item.icon}
                    </ListItemIcon>
                    <ListItemText primary={
                      <Typography variant="body2"> {/* Set variant="body2" for smaller text */}
                        {item.label}
                      </Typography>
                    } />
                    <IconButton size="small" onClick={handleExpandVertToggle}>  
                      {expandedVert ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                      </IconButton> 
                    <Checkbox indeterminate={(checkedCategory.indexOf(3)!== -1 && 
                        (checkedCategory.indexOf(30)=== -1||checkedCategory.indexOf(31)=== -1||checkedCategory.indexOf(32)=== -1))
                        ||(checkedCategory.indexOf(3)=== -1 && 
                        (checkedCategory.indexOf(30)!== -1||checkedCategory.indexOf(31)!== -1||checkedCategory.indexOf(32)!== -1))} 
                        size="small" checked={checkedCategory.indexOf(item.id) !== -1} /> {/* Use checkedCategory here */}
                  </MenuItem>
                  ))}
                  {expandedVert && category.slice(12,15).map((item) => (
                    <MenuItem size="small" key={item.id} value={item.id} sx={{ height:'26px'}}>
                      <Box sx={{width:'18px'}}></Box> 
                    <ListItemIcon size="small" >
                      {item.icon}
                    </ListItemIcon>
                    <ListItemText primary={
                      <Typography variant="body2"> {/* Set variant="body2" for smaller text */}
                        {item.label}
                      </Typography>
                    } />
                    <Checkbox size="small" checked={checkedCategory.indexOf(item.id) !== -1} /> {/* Use checkedCategory here */}
                  </MenuItem>
                  ))}
                  {category.slice(15,16).map((item) => (
                    <MenuItem size="small" key={item.id} value={item.id} sx={{ height:'40px'}}>
                      
                    <ListItemIcon size="small" >
                      {item.icon}
                    </ListItemIcon>
                    <ListItemText primary={
                      <Typography variant="body2"> {/* Set variant="body2" for smaller text */}
                        {item.label}
                      </Typography>
                    } />
                    <Checkbox size="small" checked={checkedCategory.indexOf(item.id) !== -1} /> {/* Use checkedCategory here */}
                  </MenuItem>
                  ))}
                </Select>
                </List>
                <Box sx={{paddingBottom:'4px', marginTop:'2px',fontSize: '11px', alignItems: 'center', textAlign: 'center' }}>Filter by Software Category</Box>
              </Box>
        </Box>
        </Paper>
        <Paper elevation={theme.palette.mode=='dark'?4:2} sx={{backgroundColor: theme.background.backgroundColor, width: '450px', marginLeft: '1%', paddingTop: '8px', marginTop: '6px', paddingLeft: '1%', paddingRight: '2%', paddingBottom: '0px', display: 'flex', alignItems: 'center' }}>
          <Box sx={{ maxWidth: '45px', fontSize: '12px', fontWeight: 'bold', textAlign: 'center',  paddingBottom: '10px' }}>Axis Filters</Box>
          <Box sx={{ display: 'flex', justifyContent: 'flex-start', flex: 1, flexWrap: 'wrap' }}>
          
              <Box sx={{ marginBottom: '0px', marginLeft: '2%', marginRight: '1%' }}>
        <Select
                size="small"
                value={metric}
                onChange={handleMetricChange}
                sx={{backgroundColor:theme.tableHeader.highlight,width:'180px'}}
            >
                {metricList.map((item) => (
                    <MenuItem size="small" key={item.id} value={item.id}>
                        {item.field}
                    </MenuItem>
                ))}
            </Select>
            <Box sx={{ paddingBottom:'4px',marginTop:'2px',fontSize: '11px', alignItems: 'center', textAlign: 'center' }}>Select X-Axis Metric</Box>
            </Box>
            </Box>
            <Box sx={{ marginBottom: '0px', marginLeft: '2%', marginRight: '1%' }}>
            <Select
                size="small"
                value={yMetric}
                onChange={handleYMetricChange}
                sx={{backgroundColor:theme.tableHeader.highlight,width:'180px'}}
            >
                {valuationList.map((item) => (
                    <MenuItem size="small" key={item.id} value={item.id}>
                        {item.field}
                    </MenuItem>
                ))}
            </Select>
            <Box sx={{ paddingBottom:'4px',marginTop:'2px',fontSize: '11px', alignItems: 'center', textAlign: 'center' }}>Select Y-Axis Metric</Box>

            </Box>
            </Paper>
           
            <Paper elevation={theme.palette.mode=='dark'?4:2} sx={{backgroundColor: theme.background.backgroundColor, width: '380px', marginLeft: '1%', paddingTop: '8px', marginTop: '6px', paddingLeft: '1%', paddingRight: '2%', paddingBottom: '0px', display: 'flex', alignItems: 'center' }}>
            <Box sx={{ maxWidth: '70px', fontSize: '12px', fontWeight: 'bold', textAlign: 'center',  paddingBottom: '10px' }}>Display Properties</Box>
            <Box sx={{ display: 'flex', justifyContent: 'flex-start', flex: 1, flexWrap: 'wrap' }}>
            
                <Box sx={{ marginBottom: '0px', marginLeft: '2%', marginRight: '1%' }}>
              <Box sx={{ backgroundColor:theme.tableHeader.highlight,width:'120px',display: 'flex', justifyContent: 'flex-start', alignItems: 'center',border: `1px solid ${theme.palette.mode === 'dark' ? '#333' : '#ddd'}`, borderRadius: '4px' }}>
                <Box sx={{width:'10px'}}></Box>
              {sliderIcon ? <AppsIcon />: <AppsIcon />}
                <FormControlLabel
                  control={<Switch checked={sliderIcon} onChange={handleIconSliderChange} />}
                  label={
                    <Typography variant="body2" sx={{ fontWeight: 'bold', fontSize: '10px' }}> {/* Adjust variant as needed */}
                      {sliderIcon ? 'Showing Icons' : 'Showing Nodes'}
                    </Typography>
                  }
                />
              </Box>
                <Box sx={{ paddingBottom:'4px',marginTop:'2px',fontSize: '11px', alignItems: 'center', textAlign: 'center' }}>
                  Icon View Toggle
                  </Box>
             </Box>
             <Box sx={{ marginLeft: '1%', marginRight: '1%', marginBottom: 0 }}>
              <Box sx={{ backgroundColor:theme.tableHeader.highlight,width:'120px',display: 'flex', justifyContent: 'flex-start', alignItems: 'center',border: `1px solid ${theme.palette.mode === 'dark' ? '#333' : '#ddd'}`, borderRadius: '4px' }}>
                <Box sx={{width:'10px'}}></Box>
              {sliderLabel ? <LabelIcon />: <LabelIcon />}
                <FormControlLabel
                  control={<Switch checked={sliderLabel} onChange={handleLabelSliderChange} />}
                  label={
                    <Typography variant="body2" sx={{ fontWeight: 'bold', fontSize: '10px' }}> {/* Adjust variant as needed */}
                      {sliderLabel ? 'Showing Labels' : 'No Labels'}
                    </Typography>
                  }
                />
              </Box>
                <Box sx={{ paddingBottom:'4px',marginTop:'2px',fontSize: '11px', alignItems: 'center', textAlign: 'center' }}>
                  Label Toggle
                  </Box>
             </Box>
              </Box>
            </Paper>
         </Box>
         <Box sx={{ marginTop: '6px' }}></Box>
          </Collapse>

          <IconButton
            sx={{
              p: 0.1,
              fontSize: 15,
              marginTop: '2px',
              marginRight: '2px',


            }}
            onClick={handleToggleCollapse}
            aria-label="toggle-collapse"
          >
            {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </IconButton>
        </Paper>

    
    <Paper sx={{
      minWidth:'400px',
      maxWidth: chartMaxWidth,
      marginLeft: '1%',
      marginTop:'6px',
      paddingTop: '5px',
      paddingRight:'1%',
      position: 'relative'}} elevation={3}>
      <Box sx={{color: theme.palette.primary.main,fontSize:'30px', fontWeight:'600',paddingTop:'4px',paddingLeft:'2%'}}>{currentMetric} Growth vs. {currentValuation}</Box>
    
      <Box sx={{width: '98%',
        maxWidth: chartMaxWidth,
        height: open?'64vh':'71vh',
        marginLeft: '1%',
        paddingTop: '5px',
        paddingRight:'1%',
        position: 'relative'}}>
        {<ChartScatterplot data={outputData} theme={theme} xLabel={currentMetric} yLabel={currentValuation} hasLabel={sliderLabel} hasIcon={sliderIcon}/>}
        <img
              src={require('../data/images/logo.png')}
              alt="Website Logo"
              style={{
                position: 'absolute',
                top: 15, // Adjust the distance from the bottom
                right: 10, // Adjust the distance from the right
                width: '130px', // Set the desired width for the postage stamp
                height: 'auto', // Maintain aspect ratio
                opacity: 0.1, // Set the desired opacity for the watermark
              }}
            />
            </Box>
    </Paper>
    
    </Box>

    )
}
export default Dashboard