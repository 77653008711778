import { ResponsiveTreeMap } from '@nivo/treemap';

import { useNavigate } from 'react-router-dom';

const ChartSegment = ({ data, value,theme }) => {
    const navigate = useNavigate();
    const isDarkMode = theme.palette.mode === 'dark';
  
    const themeGraph = {
      textColor: isDarkMode ? 'white' : 'black',
      grid: {
        line: {
          stroke: isDarkMode ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 0, 0, 0.1)',
        },
      },
      axis: {
        legend: {
          text: {
            fill: isDarkMode ? 'white' : 'black',
          },
        },
      },
      markers: {
        lineColor: isDarkMode ? 'white' : 'black',
        textColor: isDarkMode ? 'white' : 'black',
      },
      tooltip: {
        container: {
          background: isDarkMode ? '#323232' : '#FFFFFF', // Dark or light mode background color
          color: isDarkMode ? 'white' : 'black', // Text color in the tooltip
        },
      },
    };
    const formatValue = (value) => {
      if (value ===""){
        return value
      }
      const absValue = Math.abs(value);
      
      if (absValue >= 1e11) {
        // Format as billions with three significant digits
        return `${(value / 1e9).toFixed(0)}B`;
      }else if (absValue >= 1e9) {
        // Format as billions with three significant digits
        return `${(value / 1e9).toFixed(1)}B`;
      } else if (absValue >= 1e7) {
          // Format as billions with three significant digits
          return `${(value / 1e6).toFixed(0)}M`;
      } else if (absValue >= 1e6) {
        // Format as millions with three significant digits
        return `${(value / 1e6).toFixed(1)}M`;
      } else if (absValue >= 1e3) {
        // Format as thousands with three significant digits
        return `${(value / 1e3).toFixed(1)}K`;
      } else if (absValue >= 3) {
        // Format as is with three significant digits
        return value.toFixed(2);
      }
      else if (absValue === 0) {
        return value.toFixed(0);
      }
      else if (absValue >= 1){
        
        return `${(value * 100).toFixed(0)}%`;
      }
      else {
        return `${(value * 100).toFixed(1)}%`;
      }
    };
    const handleClick = (node) => {
      navigate(`/${node.data.name}`);
  };

  
    return (
      <ResponsiveTreeMap
        data={data}
        identity="name"
        value={value}
        innerPadding={1}
        margin={{ top: 10, right: 10, bottom: 10, left: 10 }}
        label="id"
        tile="binary"
        // colorBy={'name'}
        motionConfig="stiff"
        valueFormat={(value) => formatValue(value)}
        labelSkipSize={25}
        labelTextColor={{
          from: 'color',
          modifiers: [['darker', 3]],
        }}
        parentLabelTextColor={{
          from: 'color',
          modifiers: [['darker', 3]],
        }}
        colors={theme.palette.mode === 'dark' ? { scheme: 'paired' } : { scheme: 'pastel1' }}
        nodeOpacity={0.66}
        animate={true}
        borderColor={{
          from: 'color',
          modifiers: [['darker', 0.3]],
        }}
        theme={themeGraph}
        onClick={handleClick} // Add click handler here
        
      />
    );
  };
  export default ChartSegment