import React from 'react';
import '../index.css';
import { Box, Typography, Button, Paper } from '@mui/material';
import { useTheme } from '@emotion/react';
import LoginIcon from '@mui/icons-material/Login';

import { useAuth0 } from "@auth0/auth0-react";

const LoginNotice = () => {
  const theme = useTheme();
  const { loginWithRedirect} = useAuth0();
  const handleSignupClick = () => {
    loginWithRedirect({
      screen_hint: 'signup',
    });
  };

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '67vh',
      }}
    >
      <Paper
        elevation={3}
        sx={{
          padding: theme.spacing(8),
          textAlign: 'center',
          borderRadius: theme.shape.borderRadius,
        }}
      >
        <LoginIcon
          sx={{
            fontSize: 60,
            color: theme.palette.primary.main,
            marginBottom: theme.spacing(2),
          }}
        />
        <Typography variant="h4" gutterBottom>
          Security Analysis
        </Typography>
        <Typography variant="body1" gutterBottom>
          Please log in or create an account to view company KPIs
        </Typography>
        <Box sx={{ marginTop: theme.spacing(2) }}>
          <Button
            variant="contained"
            color="primary"
            sx={{ marginRight: theme.spacing(2) }}
            onClick={() => loginWithRedirect()}
          >
            Login
          </Button>
          <Button
            variant="outlined"
            color="primary"
            onClick={handleSignupClick}
          >
            Create Account
          </Button>
        </Box>
      </Paper>
    </Box>
  );
};

export default LoginNotice;