import React, { useState, useEffect } from 'react';
import {BrowserRouter as Router, Routes, Route,Navigate} from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
import Header from './components/Header';
import Home from './pages/Home';
import EarningsCalendar from './pages/EarningsCalendar';
import CompSheet from './pages/CompSheet';
import Privacy from './pages/Privacy'; 
import Terms from './pages/Terms'; 
import NewDashboard from './pages/DashboardNew';
import NewStockPage from './pages/NewStockPage';
import ReactGA from "react-ga4";
import { lightTheme, darkTheme } from './components/theme'; // Adjust the path as needed
import { ThemeProvider } from '@emotion/react';
import stockTickers from './data/companylist.json'; // Adjust the path as needed
import Footer from './components/Footer';
import AcquisitionTable from './pages/AcquisitionTable';
import UnicornTable from './pages/UnicornTable';
import Benchmarking from './pages/Benchmarking';
import Coverage from './pages/Coverage'
import IndustryMetrics from './pages/IndustryMetrics'
import Benchmarks from './pages/Benchmarks'

import MarketCoverage from './subpages/CoverageMarket';

import MetricsValuation from './subpages/MetricsValuation';
import CompSheetNew from './subpages/CoverageCompSheet';
import VC from './subpages/CoverageVC';
import PE from './subpages/CoveragePEAcquisition';
import Valuation from './subpages/BenchmarksValuation';

function App() {
  const [themeMode, setThemeMode] = useState(() => {
    // Check the initial color scheme
    const darkModeMediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
    return darkModeMediaQuery.matches ? 'dark' : 'light';
  });

  const toggleTheme = () => {
    setThemeMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'));
  };

  useEffect(() => {
    // Add or remove the 'dark-mode' class based on the theme mode
    document.body.classList.toggle('dark-mode', themeMode === 'dark');
  }, [themeMode]);
  ReactGA.initialize("G-T93EWL2RPH");
  return (
    <ThemeProvider theme={themeMode === 'dark' ? darkTheme : lightTheme}>
    <>
      <Router>
        <Header themeMode={themeMode} />
          <Routes>
            <Route path='/' element={<Home />} />
            <Route path='/earnings/calendar' element={<EarningsCalendar />} />

            <Route path='/industrymetrics' element={<Navigate to="/industrymetrics/dashboard" />} />
            <Route path='/industrymetrics' element={<IndustryMetrics />}>
              <Route path='dashboard' element={<MarketCoverage />} />
              <Route path='valuation' element={<MetricsValuation />} />


              {/* Add more nested routes for other subpages as needed */}
            </Route>
            <Route path='/old' element={<NewDashboard />}></Route>
            <Route path='/compsheet' element={<CompSheet />} />
            <Route path='/acquisitions' element={<AcquisitionTable />} />
            <Route path='/benchmarking' element={<Benchmarking />} />


            <Route path='/privacy' element={<Privacy />} />
            <Route path='/unicorn' element={<UnicornTable />} />
            <Route path='/termsandconditions' element={<Terms />} />
            <Route path='/benchmarks' element={<Navigate to="/benchmarks/valuation" />} />
            <Route path='/benchmarks' element={<Benchmarks />}>
              <Route path='valuation' element={<Valuation />} />
              </Route>
            <Route path='/coverage' element={<Navigate to="/coverage/all" />} />
            <Route path='/coverage' element={<Coverage />}>
              <Route path='all' element={<MarketCoverage />} />
              <Route path='compsheet' element={<CompSheetNew />} />
              <Route path='vc' element={<VC />} />
              <Route path='acquisitions' element={<PE />} />

              {/* Add more nested routes for other subpages as needed */}
            </Route>

            {stockTickers.map(ticker => (
              <Route key={ticker} path={`/new/${ticker}`} element={<NewStockPage ticker={ticker} />} />
            ))}
            {stockTickers.map(ticker => (
              <Route key={ticker} path={`/${ticker}`} element={<NewStockPage ticker={ticker} />} />
            ))}
          </Routes>
          <Footer themeMode={themeMode} toggleTheme={toggleTheme} />
      </Router>
      <ToastContainer />
    </>
    </ThemeProvider>
  );
}

export default App;
