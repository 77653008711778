import React, { useState, useEffect } from 'react';
import '../index.css';
import { useLocation } from 'react-router-dom';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import {FaGitAlt,FaUserSecret,FaCloud,FaCogs,FaStore,FaDatabase, FaIndustry, FaDesktop, FaBuilding,FaCoins,FaFileInvoice,FaComments,FaCode,FaServer,FaShieldAlt, FaUsers,FaHammer,FaNetworkWired} from 'react-icons/fa';

import {Icon,useMediaQuery,Paper,Table,TableBody,TableCell,TableContainer,TableHead,TableRow, Box, Typography } from '@mui/material';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { useTheme } from '@emotion/react';




const NewStockPage = (ticker) => {
  const category = [
    {
      id: 1,
      subIndustry:'Application',
      filter: 'Application',
      label: 'Application Software',
      icon: <FaDesktop />,
    },
    {
      id: 10,
      subIndustry:'Application',
      filter: 'CRM, Marketing, and Support',
      label: 'CRM, Marketing & Support',
      icon: <FaStore />,
    },
    {
      id: 11,
      subIndustry:'Application',
      filter: 'HR, Finance & Supply Chain',
      label: 'HR, Finance & SCM',
      icon: <FaUsers />,
    },
    {
      id: 12,
      subIndustry:'Application',
      filter: 'Productivity & Content Creation',
      label: 'Productivity',
      icon: <FaFileInvoice />,
    },
    {
      id: 2,
      subIndustry:'Infrastructure',
      filter: 'Infrastructure',
      label: 'Infrastructure Software',
      icon: <FaCogs />,
    },
    
    {
      id: 20,
      subIndustry:'Infrastructure',
      filter: 'Database & Data Platforms',
      label: 'Database & Data Platforms',
      icon: <FaDatabase />,
    },
    {
      id: 21,
      subIndustry:'Infrastructure',
      filter: 'Dev Tools & Low Code',
      label: 'Dev Tools & Low Code',
      icon: <FaCode />,
    },
    {
      id: 22,
      subIndustry:'Infrastructure',
      filter: 'IT Operations',
      label: 'IT Operations',
      icon: <FaServer />,
    },
    {
      id: 23,
      subIndustry:'Infrastructure',
      filter: 'Security & Endpoint Management',
      label: 'Security & UEM',
      icon: <FaShieldAlt />,
    },
    {
      id: 24,
      subIndustry:'Infrastructure',
      filter: 'Networking & CDN',
      label: 'Networking & CDN',
      icon: <FaNetworkWired />,
    },
    {
      id: 25,
      subIndustry:'Infrastructure',
      filter: 'CPaaS',
      label: 'CPaaS',
      icon: <FaComments />,
    },
    {
      id: 3,
      subIndustry:'Vertical',
      filter: 'Vertical',
      label: 'Vertical Software',
      icon: <FaIndustry />,
    },
    {
      id: 30,
      subIndustry:'Vertical',
      filter: 'Financials',
      label: 'Financials',
      icon: <FaCoins />,
    },
    {
      id: 31,
      subIndustry:'Vertical',
      filter: 'Engineering & Industrials',
      label: 'Engineering & Industrials',
      icon: <FaHammer />,
    },
    {
      id: 32,
      subIndustry:'Vertical',
      filter: 'Other Vertical',
      label: 'Other Vertical',
      icon: <FaIndustry />,
    },
    {
      id: 4,
      subIndustry:'Diversified',
      filter: 'Diversified',
      label: 'Diversified Software',
      icon: <FaBuilding />,
    },
  ];
  const findIcon = (subIndustry, filter) => {
    const categoryItem = category.find(item => item.subIndustry === subIndustry && item.filter === filter);
    return categoryItem ? categoryItem.icon : null; // Return the icon or null if not found
  };
  const shouldWrap2 = useMediaQuery('(max-width: 600px)');
  const formatPercentage = (num) =>{
    if (num < 1){
      return `${(num* 100).toFixed(1)}%`;
    }
    else{
      return `${(num* 100).toFixed(0)}%`;
    }
    
  }
  function formatNumber(num) {
    if (num === null || num === undefined) return 'N/A';
    if (num >= 1e9) {
      return `$${(num / 1e9).toFixed(1)}B`;
    } else if (num >= 1e6) {
      return `$${(num / 1e6).toFixed(0)}M`;
    } else {
      return num.toLocaleString();
    }
  }
  const theme = useTheme();
  const location = useLocation(); 
  const [table, setTable] = useState([]);
  
  const [keyStats, setKeyStats] = useState([]);
  const tableContainerWidth = 1100

  useEffect(() => {
    setTable([])
    const fetchData = async () => {
      try {
        // Ensure ticker is a string and log it for debugging
        const dynamicTable = await import(`../data/stockpage/about/${ticker['ticker']}.json`);
        const dynamicKeyStats = await import(`../data/stockpage/keystatistics/${ticker['ticker']}.json`);
        const keyStatsFilterCriteria = ['Current Status','Acquirer(s)','Close Date','Employees', 'Year Founded','Market Cap','Enterprise Value','Last Raise Date','Series','Latest Valuation','Total Equity Raised']; // Example metrics to include

        

        // Set the data in state, assuming dynamicData.default is an array of arrays as per provided data format
          setTable(dynamicTable.default);
          if (Array.isArray(dynamicKeyStats.default[0].keyStats)) {
            setKeyStats(dynamicKeyStats.default[0].keyStats.filter(item =>
              keyStatsFilterCriteria.includes(item.field)));
          }

      } catch (error) {
        console.error(`Error fetching data for ${ticker}:, error`);
      }
    }

    fetchData();
  }, [ticker]);

  

  
  // Function to handle button clicks and update the selected button state
  
  
  const descriptionParagraphs = (table.Description || '')
    .replace(/[\u2018\u2019\u201B\uFFFD]/g, "'") // Normalize non-standard apostrophes
    .split('\n') // Split by new lines for paragraphs
    .filter(paragraph => paragraph.trim() !== ''); // Remove empty paragraphs

  const categoryIcon = findIcon(table["Sub Industry"], table["Category"]);
  const softwareCategoryIcon = findIcon(table["Sub Industry"], table["Sub Industry"]);
  const deploymentIcon = table['Deployment'] === 'On-Prem/Hybrid' ? <FaServer/>:<FaCloud/>
  const openSourceIcon = table['Tech Stack'] === 'Open Source' ? <FaGitAlt/>:<FaUserSecret/>
  return(
    
  <Box sx ={{width: tableContainerWidth+50,maxWidth:'100%',minHeight:'70vh'}}>
    <Paper elevation={2} sx={{flexWrap:'wrap',marginLeft:'1%',backgroundColor: theme.background.backgroundColor, paddingBottom: '4px', maxWidth: tableContainerWidth + 50, paddingTop: '0px', marginTop: '6px', marginBottom: '0px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
    <Paper sx= {{width:'200px', margin:'6px'}}>
      <Typography sx={{backgroundColor:theme.tableHeader.color,fontSize:'14px', fontWeight:600,textAlign: 'center' }} variant='body1'>
        Software Category
        </Typography>
      <Box sx={{display:'flex',height:'50px',paddingLeft:'6px',paddingRight:'6px',justifyContent:'flex-start', maxWidth:'200px',alignItems:'center', justifyContent: 'center'}}>
      {softwareCategoryIcon && <Icon sx={{}}>{softwareCategoryIcon}</Icon>}
      <Typography sx={{fontSize:'14px', fontWeight:300,marginLeft:'6px',marginRight:'6px',textAlign: 'center' }} variant='body1'>
        {table["Sub Industry"]} Software
        </Typography>
        </Box>
      </Paper>
      <Paper sx= {{width:'200px', margin:'6px'}}>
      <Typography sx={{backgroundColor:theme.tableHeader.color,fontSize:'14px', fontWeight:600,textAlign: 'center' }} variant='body1'>
        Market Category
        </Typography>
      <Box sx={{display:'flex',height:'50px',paddingLeft:'6px',paddingRight:'6px',justifyContent:'flex-start', maxWidth:'200px',alignItems:'center', justifyContent: 'center'}}>
      {categoryIcon && <Icon sx={{}}>{categoryIcon}</Icon>}
      <Typography sx={{fontSize:'14px', fontWeight:300,marginLeft:'6px',marginRight:'6px',textAlign: 'center' }} variant='body1'>
        {table["Category"]}
        </Typography>
        </Box>
      </Paper>
      <Paper sx= {{width:'200px', margin:'6px'}}>
      <Typography sx={{backgroundColor:theme.tableHeader.color,fontSize:'14px', fontWeight:600,textAlign: 'center' }} variant='body1'>
        Deployment Model
        </Typography>
      <Box sx={{display:'flex',height:'50px',paddingLeft:'6px',paddingRight:'6px',justifyContent:'flex-start', maxWidth:'200px',alignItems:'center', justifyContent: 'center'}}>
      { <Icon sx={{}}>{deploymentIcon}</Icon>}
      <Typography sx={{fontSize:'14px', fontWeight:300,marginLeft:'6px',marginRight:'6px',textAlign: 'center' }} variant='body1'>
        {table["Deployment"]}
        </Typography>
        </Box>
      </Paper>
      <Paper sx= {{width:'200px', margin:'6px'}}>
      <Typography sx={{backgroundColor:theme.tableHeader.color,fontSize:'14px', fontWeight:600,textAlign: 'center' }} variant='body1'>
      Source Model
        </Typography>
      <Box sx={{display:'flex',height:'50px',paddingLeft:'6px',paddingRight:'6px',justifyContent:'flex-start', maxWidth:'200px',alignItems:'center', justifyContent: 'center'}}>
      { <Icon sx={{}}>{openSourceIcon}</Icon>}
      <Typography sx={{fontSize:'14px', fontWeight:300,marginLeft:'6px',marginRight:'6px',textAlign: 'center' }} variant='body1'>
        {table["Tech Stack"]}
        </Typography>
        </Box>
      </Paper>
      

      <Box>

      </Box>
   
        </Paper>
    <Box sx={{ maxWidth: tableContainerWidth + 50,marginLeft:'1%',display: 'flex',justifyContent: 'flex-start',flexWrap: shouldWrap2 ? 'wrap' : 'nowrap'}}>
    <Paper elevation={2} sx={{ paddingTop:'5px',paddingBottom:'5px',flex:1,maxWidth: shouldWrap2 ? '100%' : '280px', minWidth:'350px',marginRight:'.5%',marginTop:'6px',paddingLeft:'1.5%',paddingRight:'1.5%'}}>
      <Box sx={{color: theme.palette.primary.main,fontSize:'20px', paddingTop:'6px',fontWeight:'600',paddingLeft:'2%'}}>Company Statistics</Box>
      <TableContainer component={Paper} sx={{ marginTop:'10px',maxWidth:'300px',marginTop:'5px'}}>
        <Table size='small' aria-label="a dense table">
          <TableHead>
          <TableRow sx={{backgroundColor: theme.tableHeader.color,fontWeight: '700',fontSize: '30'}}>
              <TableCell  sx={{paddingTop: '3px',paddingBottom: '3px'}} align="left">Metrics</TableCell>
              <TableCell sx={{paddingTop: '3px',paddingBottom: '3px'}} align="center"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
        {keyStats.map((row, index) => (
          <TableRow
            key={row.field}
            sx={{
              backgroundColor: index % 2 === 0 ? theme.tableHeader.highlight : theme.background.default,
              '&:last-child td, &:last-child th': { border: 0 },
              position: 'relative',
              ':hover': {
                backgroundColor: theme.tableHeader.highlightDark,
                cursor: 'pointer',
              },
            }}
          >
            <TableCell sx={{ paddingTop: '3px', paddingBottom: '3px' }} align="left">
              {row.field}
              {row.subField && <div className="sublabel">{row.subField}</div>}
            </TableCell>
            <TableCell sx={{ paddingTop: '3px', paddingBottom: '3px' }} align="right">{row.value}</TableCell>
          </TableRow>
        ))}
      </TableBody>
        </Table>
      </TableContainer>
      </Paper>
        
        <Paper elevation={2} sx={{  flex: 1,paddingTop:'5px',marginTop:'6px',paddingLeft:'1%',minWidth:'300px',maxWidth:'100%'}}>
        <Box sx={{color: theme.palette.primary.main,fontSize:'20px', paddingTop:'4px',fontWeight:'600',paddingBottom:'8px',paddingLeft:'2%'}}>Company Description </Box>
        

          
          <Box style={{ flex: 1,width:'100%', position: 'relative' }}> 
          <Box style={{ flex: 1,width:'100%', position: 'relative',paddingBottom:'8px',paddingRight:'7%',paddingLeft:'2%' }}> 
            
            {descriptionParagraphs.map((paragraph, index) => (
                <Typography key={index} variant="body1" sx={{fontStyle:'italic'}} paragraph>
                  {paragraph}
                </Typography>
              ))}
          </Box>
        </Box>
        </Paper>
        </Box>
    
  </Box>
      )}
      export default NewStockPage;