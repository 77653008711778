import React from "react";
import '../index.css';
import { useState,useEffect } from "react";
import { useLocation } from 'react-router-dom';
import data from '../data/charts/csp.json';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { ResponsiveLine } from '@nivo/line'
import { ListItem,useMediaQuery,ListItemIcon,Checkbox,List,ListItemButton,Collapse,Input,Paper,Box, IconButton, Typography,Grid,Slider, } from '@mui/material';
import ReactGA from 'react-ga4';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import gcpLogo from '../data/images/GCP.png'
import azureLogo from '../data/images/Azure.png'
import awsLogo from '../data/images/AWS.png'
import ociLogo from '../data/images/OCI.png'
import { useTheme } from '@mui/material/styles';
import ViewTimelineIcon from '@mui/icons-material/ViewTimeline';



const MyResponsiveLine = ({ data,theme /* see data tab */ }) => {
  const isDarkMode = theme.palette.mode === 'dark';

  const themeGraph = {
    textColor: isDarkMode ? 'white' : 'black',
    grid: {
      line: {
        stroke: isDarkMode ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 0, 0, 0.1)',
      },
    },
    axis: {
      legend: {
        text: {
          fill: isDarkMode ? 'white' : 'black', // Set legend text color to white in dark mode
          fontWeight: 'bold',
        },
      },
      ticks: {
        text: {
          fill: isDarkMode ? 'white' : 'black',
        },
      },
    },
    markers: {
      lineColor: isDarkMode ? 'white' : 'black',
      textColor: isDarkMode ? 'white' : 'black',
    },
    tooltip: {
      container: {
        background: isDarkMode ? '#323232' : '#FFFFFF',
        color: isDarkMode ? 'white' : 'black',
      },
    },
    legends: {
      text: {
        fill: isDarkMode ? 'white' : 'black', // Set legend label color to white in dark mode
      },
    },
  };
  return(
  <ResponsiveLine
      data={data}
      theme={themeGraph}
      margin={{ top: 50, right: 20, bottom: 80, left: 60 }}
      xScale={{ type: 'point' }}
      yScale={{
          type: 'linear',
          min: '0',
          max: 'auto',
          stacked: false,
          reverse: false
      }}
      yFormat=" >-.0%"
      curve="step"
      axisTop={null}
      axisRight={null}
      axisBottom={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: -45,  
          legend: 'Quarter',
          legendOffset: 60,
          legendPosition: 'middle'
      }}
      axisLeft={{
          format:' >-.0%',
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: 'YoY Growth',
          legendOffset: -50,
          legendPosition: 'middle'
      }}
      enableGridX={false}
      enableGridY={false}
      pointSize={10}
      pointColor={{ theme: 'background' }}
      pointBorderWidth={2}
      pointBorderColor={{ from: 'serieColor' }}
      pointLabelYOffset={-12}
      enableArea={true}
      areaBaselineValue={0}
      enableSlices="x"
      areaOpacity={0.05}
      useMesh={true}
      legends={[
          {
              anchor: 'top-right',
              direction: 'column',
              justify: false,
              translateX: 10,
              translateY: -40,
              itemsSpacing: 0,
              itemDirection: 'left-to-right',
              itemWidth: 95,
              itemHeight: 20,
              itemOpacity: 0.75,
              symbolSize: 12,
              symbolShape: 'circle',
              symbolBorderColor: 'rgba(0, 0, 0, .5)',
              effects: [
                  {
                      on: 'hover',
                      style: {
                          itemBackground: 'rgba(0, 0, 0, .03)',
                          itemOpacity: 1
                      }
                  }
              ]
          }
      ]}
      motionConfig="stiff"
  />
)}

function CloudService(){
  const theme = useTheme();
  const location = useLocation(); // If using React Router
  const calculateMaxSliderValue = () => {

      return 40; // Or any other default value you prefer
    };
  

  const maxSliderValue = calculateMaxSliderValue();
  
  const initialSliderValue = 16;
  const [sliderValue, setValue] = useState(initialSliderValue);

  // Function to handle button clicks and update the selected button state
  
  
  const handleSliderChange = (event, newValue) => {
    setValue(newValue);

  };
  const handleInputChange = (event) => {
    setValue(event.target.value === '' ? '' : Number(event.target.value));
  };
  const handleBlur = () => {
    if (sliderValue < 2 || isNaN(sliderValue)) {
      setValue(2);
    } else if (sliderValue > maxSliderValue) {
      setValue(maxSliderValue);
    }
  };
  useEffect(() => {
    ReactGA.send({ hitType: 'pageview', page: location.pathname });
  }, [location.pathname]);
  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);

    // If you want to scroll to the top every time the component updates, you can include an empty dependency array []
  }, []); // The empty dependency array ensures that this effect runs only once when the component mounts

    const [open, setOpen] = useState(true); // State to manage the open/closed state

      const items = [
        { id: 1, filter:'AWS',label: 'AWS', icon: awsLogo },
        { id: 2, filter:'Azure',label: 'Azure', icon: azureLogo },
        { id: 3, filter:'Google Cloud',label: 'Google Cloud', icon: gcpLogo },
        { id: 4, filter:'Oracle IaaS',label: 'Oracle IaaS', icon: ociLogo},
        // Add more items as needed
    ];

     const [checked, setChecked] = React.useState([1,2,3,4]);
     const handleToggle = (value) => () => {
         const currentIndex = checked.indexOf(value);
         const newChecked = [...checked];
     
         if (currentIndex === -1) {
           newChecked.push(value);
         } else {
           newChecked.splice(currentIndex, 1);
         }
     
         setChecked(newChecked);
     };
     const filteredData = data
      .filter(item => {
        const selectedItem = items.find(i => i.filter === item.id);
        return selectedItem && checked.includes(selectedItem.id);
      })
      .map(provider => ({
        id: provider.id,
        data: provider.data.slice(-sliderValue) // Use slice to get the last 'sliderValue' number of quarters
      }));
      const shouldWrap = useMediaQuery('(max-width: 720px)');
      const handleToggleCollapse = () => {
          setOpen(!open);
        };
    return(
        
      <>
      <Box sx={{width:'1500px',maxWidth:'100%'}}>

      <Paper elevation={2} sx={{ backgroundColor: theme.background.backgroundColor,marginLeft:'1%',paddingBottom: '4px', maxWidth: '1500px', paddingTop: '0px', marginTop: '6px', marginBottom: '0px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
{!open && (
      <Box
        className='label'
        sx={{
          paddingTop: '6px',
          paddingBottom: '6px',
          paddingLeft: '1.5%',
          fontWeight: 'bold',
        }}
      >
        <Box sx={{ paddingTop: '2px', fontSize: '12px',minWidth:'200px' }}>
          Expand Dashboard Filters
        </Box>
      </Box>
    )}
  <Collapse in={open} sx={{ width: '95%' }}>
    <Box sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap',justifyContent:'center' }}>
    <Paper elevation={theme.palette.mode=='dark'?4:2} sx={{  backgroundColor: theme.background.backgroundColor,width: '700px', marginLeft: '1%', paddingTop: '8px', marginTop: '6px', paddingLeft: '1%', paddingRight: '2%', paddingBottom: '0px', display: 'flex', alignItems: 'center' }}>
      <Box sx={{ maxWidth: '65px', fontSize: '12px', fontWeight: 'bold', textAlign: 'center',  paddingBottom: '10px' }}>Dashboard Filters</Box>
      <Box sx={{ display: 'flex', justifyContent: 'flex-start', flex: 1, flexWrap: 'wrap' }}>
       
          <Box sx={{ marginBottom: '0px', marginLeft: '2%', marginRight: '1%' }}>
          <List disablePadding sx={{ display: 'flex', flexDirection: 'row', flexWrap: shouldWrap ? 'wrap' : 'nowrap' }}>
            {items.map((item) => {
              const labelId = `checkbox-list-label-${item.id}`;

              return (
              
                <ListItem sx={{backgroundColor:theme.tableHeader.highlight,width:'140px'}} key={item.id} disablePadding size='small'>
                  <ListItemButton sx={{paddingX: '0px', backgroundColor: checked.indexOf(item.id) !== -1 ? theme.toggleButton.selectedBackgroundColor :'default', border: `0.5px solid ${theme.palette.mode === 'dark' ? '#333' : '#ddd'}`}} size='small' role={undefined} onClick={handleToggle(item.id)} dense>
                    <ListItemIcon size='small'>
                      <Checkbox
                        checked={checked.indexOf(item.id) !== -1}
                        tabIndex={-1}
                        disableRipple
                        inputProps={{ 'aria-labelledby': labelId }}
                        sx={{ marginRight: '0px' }}
                      />
                    </ListItemIcon>
                    <img src={item.icon} alt={item.label} style={{width: '24px', height: '24px', marginRight: '4px' }} /> {/* Render the image */}

                    <Typography variant="body2" sx={{ 
                      fontWeight:'bold',fontSize: '12px', 
                      paddingRight: '6px',
                      color: checked.indexOf(item.id) !== -1 ? theme.toggleButton.selectedTextColor : 'default',
                    }}>{item.label}</Typography>
                  </ListItemButton>
                </ListItem>
              );
            })}
          </List>
          <Box sx={{ paddingBottom:'4px',marginTop: '2px', fontSize: '11px', alignItems: 'center', textAlign: 'center' }}>Select Cloud Service Provider</Box>
          </Box>
          </Box>
          </Paper>
          <Paper elevation={theme.palette.mode=='dark'?4:2} sx={{ backgroundColor: theme.background.backgroundColor,width: '260px', marginLeft: '1%', paddingTop: '8px', marginTop: '6px', paddingLeft: '1%', paddingRight: '2%', paddingBottom: '0px', display: 'flex', alignItems: 'center' }}>
      <Box sx={{ maxWidth: '45px', fontSize: '12px', fontWeight: 'bold', textAlign: 'center',  paddingBottom: '10px' }}>Chart Filters</Box>
      <Box sx={{ display: 'flex', justifyContent: 'flex-start', flex: 1, flexWrap: 'wrap' }}>
          <Box sx={{  marginLeft: '2%', marginRight: '1%' }}>
          <Box sx={{ width: '180px' }}>
    <Box  sx={{ backgroundColor:theme.tableHeader.highlight,height:'43px',py: 0.3, px: 1 ,marginLeft:'1%', marginRight:'2%',border: `1px solid ${theme.palette.mode === 'dark' ? '#333' : '#ddd'}`, borderRadius: '4px' }}>
      <Grid container spacing={2} alignItems="center">
        <Grid item>
        <IconButton color={'default'}  sx={{ py: 0, px: 0 }}>
          <ViewTimelineIcon />
          </IconButton>
        </Grid>
        <Grid item xs>
          <Slider
            value={typeof sliderValue === 'number' ? sliderValue : 0}
            onChange={handleSliderChange}
            aria-labelledby="input-slider"
            min={2}
            max={maxSliderValue}
          />
        </Grid>
        <Grid item>
        <Input
          value={sliderValue}
          size="small"
          onChange={handleInputChange}
          onBlur={handleBlur}
          inputProps={{
            step: 1,
            min: 2,
            max: {maxSliderValue},
            type: 'text', // Change type to text
            pattern: '\\d*', // Pattern to only allow digits
            'aria-labelledby': 'input-slider',
          }}
          sx={{ width: '20px', '&::-webkit-inner-spin-button, &::-webkit-outer-spin-button': { display: 'none' } }}
        />
          
        </Grid>
      </Grid>
      </Box>
      <Box sx={{ marginTop:'2px',fontSize: '11px', alignItems: 'center', textAlign: 'center' }}>Display Last {sliderValue} Quarters</Box>
      
    </Box>
    </Box>
    </Box>
          </Paper>
          </Box>
          <Box sx={{ marginTop: '6px' }}></Box>
        </Collapse>

        <IconButton
          sx={{
            p: 0.1,
            fontSize: 15,
            marginTop: '2px',
            marginRight: '2px',


          }}
          onClick={handleToggleCollapse}
          aria-label="toggle-collapse"
        >
          {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </IconButton>
      </Paper>

        
      <Paper sx={{
      minWidth:'400px',
      maxWidth: '1500px',
      marginTop:'6px',
      marginLeft: '1%',
      paddingBottom: '10px',
      paddingTop: '5px',
      paddingRight:'1%',
      position: 'relative'}} elevation={3}>
      <Box sx={{color: theme.palette.primary.main,fontSize:'30px', fontWeight:'600',paddingTop:'4px',paddingLeft:'2%'}}>Cloud Provider Growth Rates</Box>
    
      <Box sx={{width: '98%',
        maxWidth: '1500px',
        height: open?'60vh':'70vh',
        marginLeft: '1%',
        paddingBottom: '10px',
        paddingTop: '5px',
        paddingRight:'1%',
        position: 'relative'}}>
        <MyResponsiveLine data={filteredData} theme={theme} />
        <img
              src={require('../data/images/logo.png')}
              alt="Website Logo"
              style={{
                position: 'absolute',
                bottom: 98, // Adjust the distance from the bottom
                left:  65, // Adjust the distance from the right
                width: '150px', // Set the desired width for the postage stamp
                height: 'auto', // Maintain aspect ratio
                opacity: 0.2, // Set the desired opacity for the watermark
              }}
            />
            </Box>
      </Paper>
      </Box>
      </>
      
    )
}
export default CloudService;