import { maxHeight } from "@mui/system";
import { ResponsiveBar } from "@nivo/bar";
import { useNavigate } from 'react-router-dom';

const ChartDashboard = ({ data, theme, format, keys /* see data tab */ }) => {
  const navigate = useNavigate();  
  const isDarkMode = theme.palette.mode === 'dark';
  
  const handleClick = (node) => {
    navigate(`/${node.data.Ticker}`);
  };
  const formatValue = (value) => {

    switch (format) {
      case 'percentage':
        return `${Math.round(value*100)}%`; // Round to nearest whole number for percentage
      case 'multiple':
        return `${(value).toFixed(1)}x`; // Assuming multiple means scaling the value and showing 1 decimal
      case 'number':
        return value.toFixed(1); // Show number with 1 decimal place
      default:
        return value; // Return value as is if format is not recognized
    }
  };
  const themeGraph = {
    textColor: isDarkMode ? 'white' : 'black',
    grid: {
      line: {
        stroke: isDarkMode ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 0, 0, 0.1)',
      },
    },
    axis: {
      legend: {
        text: {
          fill: isDarkMode ? 'white' : 'black',
        },
      },
      ticks: {
        text: {
          fill: isDarkMode ? 'white' : 'black',
        },
      },
    },
    markers: {
      lineColor: isDarkMode ? 'white' : 'black',
      textColor: isDarkMode ? 'white' : 'black',
    },
    tooltip: {
      container: {
        background: isDarkMode ? '#323232' : '#FFFFFF',
        color: isDarkMode ? 'white' : 'black',
      },
    },
  };

  // Function to apply the correct format based on type


  return (
    <ResponsiveBar
      data={data}
      keys={keys}
      indexBy="Ticker"
      valueFormat={formatValue}
      margin={{ top: 15, right: 0, bottom: 50, left: 15 }}
      padding={0.1}
      valueScale={{ type: 'linear' }}
      indexScale={{ type: 'band', round: true }}
      
      groupMode='grouped'
      colors={theme.palette.mode === 'dark' ? { scheme: 'dark2' } : { scheme: 'accent' }}
      borderColor={{
        from: 'color',
        modifiers: [['darker', 1.6]],
      }}
      axisTop={null}
      axisRight={null}
      axisBottom={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: -50,
      }}
      axisLeft={null}
      enableGridY={false}
      labelSkipWidth={12}
      motionConfig={'stiff'}
      labelSkipHeight={12}
      theme={themeGraph}
      labelTextColor={{
        from: 'color',
        modifiers: [['darker', 3]],
      }}
      role="application"
      ariaLabel="Nivo bar chart demo"
      barAriaLabel={(e) => e.id + ": " + e.formattedValue + " in country: " + e.indexValue}
      onClick={handleClick}

    />
  );
};

export default ChartDashboard;
