import React, { useState, useEffect,useRef } from 'react';
import '../index.css';
import trendedData from '../data/charts/unicorn.json'
import { useLocation } from 'react-router-dom';
import {FaChess,FaStore,FaDatabase, FaIndustry, FaDesktop, FaBuilding,FaCoins,FaFileInvoice,FaComments,FaCode,FaServer,FaShieldAlt,  FaUsers,FaHammer,FaNetworkWired} from 'react-icons/fa';

import ReactGA from 'react-ga4';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import {ListItemIcon,Collapse,Paper,Table,TableBody,TableCell,TableContainer,TableHead,TableRow,Checkbox, Box, Typography,IconButton,List,MenuItem,Select,ListItemText } from '@mui/material';

import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { useAuth0 } from "@auth0/auth0-react"; // Import useAuth0
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { useTheme } from '@emotion/react';
import { NavLink } from 'react-router-dom';
import { ArrowDownward, ArrowUpward,ArrowForward } from '@mui/icons-material';


const UnicornTable = () => {
  const { isAuthenticated, loginWithRedirect } = useAuth0(); // Use the useAuth0 hook
  useEffect(() => {
    // Redirect to login if the user is not authenticated
    //if (!isAuthenticated) {
    //  loginWithRedirect();
    //}
  }, [isAuthenticated, loginWithRedirect]); // Dependencies ensure this runs when isAuthenticated or loginWithRedirect changes

  const theme = useTheme();
  const location = useLocation(); // If using React Router
  const tableContainerRef = useRef(null);
  const [sortField, setSortField] = useState('Post-Money Valuation');
  const [sortOrder, setSortOrder] = useState('desc');
  const [alignment, setAlignment] = React.useState('growth');
  const [checkedCategory, setCheckedCategory] = React.useState([1,2,3,4,10,11,12,20,21,22,23,24,30,31,32]);
  const [checkedType, setCheckedType] = React.useState([1,2]);
  const [expandedApp, setExpandedApp] = useState(false); 
  const [expandedInfra, setExpandedInfra] = useState(false); 
  const [expandedVert, setExpandedVert] = useState(false); 


  const [open, setOpen] = useState(true); // State to manage the open/closed state
  const [isSlided, setIsSlided] = useState(false);
  
  const handleExpandAppToggle = (e) => {
    e.stopPropagation(); // Stop event propagation to prevent filtering
    setExpandedApp(!expandedApp); // Toggle the expansion state
  };
  const handleSliderChange = (event) => {
    setIsSlided(event.target.checked);
  };
  const handleExpandInfraToggle = (e) => {
    e.stopPropagation(); // Stop event propagation to prevent filtering
    setExpandedInfra(!expandedInfra); // Toggle the expansion state
  };
  const handleExpandVertToggle = (e) => {
    e.stopPropagation(); // Stop event propagation to prevent filtering
    setExpandedVert(!expandedVert); // Toggle the expansion state
  };
  const tableColumns = [
    { label: 'Company Name', field: 'Company Name', maxWidth: '60px' },
    { label: 'Last Transaction Date', field: 'Last Raise Date', maxWidth: '80px' },
    { label: 'Series', field: 'Series', maxWidth: '150px' },
    { label: 'Latest Valuation (PMV)', field: 'Post-Money Valuation', maxWidth: '50px' },
    { label: 'Last Investment', field: 'Last Investment', maxWidth: '50px' },
    { label: 'Total Money Raised', field: 'Total Money Raised', maxWidth: '50px' },
    { label: 'ARR', field: 'ARR', maxWidth: '50px' },
    { label: 'ARR Reported Date', field: 'ARR Reported Date', maxWidth: '50px' }
  ];
  const acquisitionType =[
    {
      id: 1,
      filter: 'Strategic',
      label: 'Strategic',
      icon: <FaChess />,
    },
    {
      id: 2,
      filter: 'Financial',
      label: 'Financial',
      icon: <FaCoins />,
    },
  ]
  const category = [
    {
      id: 1,
      subIndustry:'Application',
      filter: 'Application',
      label: 'Application Software',
      icon: <FaDesktop />,
    },
    {
      id: 10,
      subIndustry:'Application',
      filter: 'CRM, Marketing, and Support',
      label: 'CRM, Marketing & Support',
      icon: <FaStore />,
    },
    {
      id: 11,
      subIndustry:'Application',
      filter: 'HR, Finance & Supply Chain',
      label: 'HR, Finance & SCM',
      icon: <FaUsers />,
    },
    {
      id: 12,
      subIndustry:'Application',
      filter: 'Productivity & Content Creation',
      label: 'Productivity',
      icon: <FaFileInvoice />,
    },
    {
      id: 2,
      subIndustry:'Infrastructure',
      filter: 'Infrastructure',
      label: 'Infrastructure Software',
      icon: <FaDatabase />,
    },
    
    {
      id: 20,
      subIndustry:'Infrastructure',
      filter: 'Database & Data Platforms',
      label: 'Database & Data Platforms',
      icon: <FaDatabase />,
    },
    {
      id: 21,
      subIndustry:'Infrastructure',
      filter: 'Dev Tools & Low Code',
      label: 'Dev Tools & Low Code',
      icon: <FaCode />,
    },
    {
      id: 22,
      subIndustry:'Infrastructure',
      filter: 'IT Operations',
      label: 'IT Operations',
      icon: <FaServer />,
    },
    {
      id: 23,
      subIndustry:'Infrastructure',
      filter: 'Security & Endpoint Management',
      label: 'Security & UEM',
      icon: <FaShieldAlt />,
    },
    {
      id: 24,
      subIndustry:'Infrastructure',
      filter: 'Networking & CDN',
      label: 'Networking & CDN',
      icon: <FaNetworkWired />,
    },
    {
      id: 25,
      subIndustry:'Infrastructure',
      filter: 'CPaaS',
      label: 'CPaaS',
      icon: <FaComments />,
    },
    {
      id: 3,
      subIndustry:'Vertical',
      filter: 'Vertical',
      label: 'Vertical Software',
      icon: <FaIndustry />,
    },
    {
      id: 30,
      subIndustry:'Vertical',
      filter: 'Financials',
      label: 'Financials',
      icon: <FaCoins />,
    },
    {
      id: 31,
      subIndustry:'Vertical',
      filter: 'Engineering & Industrials',
      label: 'Engineering & Industrials',
      icon: <FaHammer />,
    },
    {
      id: 32,
      subIndustry:'Vertical',
      filter: 'Other Vertical',
      label: 'Other Vertical',
      icon: <FaIndustry />,
    },
    {
      id: 4,
      subIndustry:'Diversified',
      filter: 'Diversified',
      label: 'Diversified Software',
      icon: <FaBuilding />,
    },
  ];
        
    const handleToggleCollapse = () => {
      setOpen(!open);
    };




  const handleSort = (field) => {
    if (field === sortField) {
      // Toggle the sort order if the same field is clicked again
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    } else {
      // Set the new sort field and default to descending order
      setSortField(field);
      setSortOrder('desc');
    }
  };
  const handleChange = (event, newAlignment) => {
    if (newAlignment !== null) {
      setAlignment(newAlignment);
    }
  };

  const handleCheckedChangeCategory = (event) => {
    const { value: selectedItems } = event.target;
    
    setCheckedCategory(prevCheckedCategory => {
        // Check if '1' has been newly checked
        const oneChecked = selectedItems.includes(1) && !prevCheckedCategory.includes(1);
        const oneUnchecked = !selectedItems.includes(1) && prevCheckedCategory.includes(1);
        const twoChecked = selectedItems.includes(2) && !prevCheckedCategory.includes(2);
        const twoUnchecked = !selectedItems.includes(2) && prevCheckedCategory.includes(2);
        const threeChecked = selectedItems.includes(3) && !prevCheckedCategory.includes(3);
        const threeUnchecked = !selectedItems.includes(3) && prevCheckedCategory.includes(3);

        // Initialize the new state with the selected items
        let newState = [...selectedItems];

        if (oneChecked) {
            newState.push(10,11,12); 
        }
        if (oneUnchecked) {
          newState = newState.filter(item => ![10, 11, 12].includes(item));
        }
        if (twoChecked) {
          newState.push(20,21,22,23,24); 
        }
        if (twoUnchecked) {
          newState = newState.filter(item => ![20,21,22,23,24].includes(item));
        }
        if (threeChecked) {
          newState.push(30,31,32); 
        }
        if (threeUnchecked) {
          newState = newState.filter(item => ![30,31,32].includes(item));
        }
        if (!oneChecked && !oneUnchecked && newState.includes(10) && newState.includes(11) && newState.includes(12)&&!newState.includes(1)) {
          newState.push(1);
        }
        if (!oneChecked && !oneUnchecked && !newState.includes(10) && !newState.includes(11) && !newState.includes(12)&&newState.includes(1)) {
          newState = newState.filter(item => ![1].includes(item));
        }
        if (!twoChecked && !twoUnchecked && newState.includes(20) && newState.includes(21) && newState.includes(22) && newState.includes(23) && newState.includes(24) &&!newState.includes(2)) {
          newState.push(2);
        }
        if (!twoChecked && !twoUnchecked && !newState.includes(20) && !newState.includes(21) && !newState.includes(22)&& !newState.includes(23)&& !newState.includes(24)&&newState.includes(2)) {
          newState = newState.filter(item => ![2].includes(item));
        }
        if (!threeChecked && !threeUnchecked && newState.includes(30) && newState.includes(31) && newState.includes(32)&&!newState.includes(3)) {
          newState.push(3);
        }
        if (!threeChecked && !threeUnchecked && !newState.includes(30) && !newState.includes(31) && !newState.includes(32)&&newState.includes(3)) {
          newState = newState.filter(item => ![3].includes(item));
        }
        return newState;
    });
}
const handleCheckedType = (event) => {
  const { value: selectedItems } = event.target;
  
  setCheckedType(prevCheckedCategory => {
      let newState = [...selectedItems];

      return newState;
  });
}
const filteredData = trendedData
.filter((company) => {
  // Check if the company's category matches any of the checked categories
  return checkedCategory.includes(category.find(cat => cat.filter === company.Category)?.id);
})

const sortedData = filteredData.sort((a, b) => {
  const valueA = a[sortField];
  const valueB = b[sortField];

  // Function to parse financial values like "$3.0B", "$35M"
  const parseFinancialValue = (value) => {
    if (value === "-") return Number.NEGATIVE_INFINITY; // Use a special value for "-"
    const units = { 'B': 1e9, 'M': 1e6, 'K': 1e3 };
    const match = value.match(/^\$([\d.]+)([BMK]?)$/);
    if (!match) return parseFloat(value);
    const number = parseFloat(match[1]);
    const unit = match[2];
    return number * (units[unit] || 1);
  };

  // Handle sorting for dates
  if (sortField === 'Last Raise Date' || sortField === 'ARR Reported Date') {
    if (valueA === "-") return 1;
    if (valueB === "-") return -1;
    return sortOrder === 'asc' ? new Date(valueA) - new Date(valueB) : new Date(valueB) - new Date(valueA);
  }

  // Handle sorting for financial fields
  if (['Post-Money Valuation', 'Last Investment', 'Total Money Raised','ARR'].includes(sortField)) {
    const numA = parseFinancialValue(valueA);
    const numB = parseFinancialValue(valueB);

    if (numA === Number.NEGATIVE_INFINITY) return 1;
    if (numB === Number.NEGATIVE_INFINITY) return -1;
    return sortOrder === 'asc' ? numA - numB : numB - numA;
  }

  // Handle sorting for other fields
  if (valueA === "-") return 1;
  if (valueB === "-") return -1;
  if (valueA < valueB) return sortOrder === 'asc' ? -1 : 1;
  if (valueA > valueB) return sortOrder === 'asc' ? 1 : -1;
  return 0;
});
//if (!isAuthenticated) {
//  return null; // Optionally, you can show a loading spinner or message here
//}
return(
  <>


 <Box sx={{width:'1400px',maxWidth:'100%'}}>

      <Paper elevation={2} sx={{ marginLeft:'1%',backgroundColor: theme.background.backgroundColor,paddingBottom: '4px', maxWidth: '1400px', paddingTop: '0px', marginTop: '6px', marginBottom: '0px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
{!open && (
      <Box
        className='label'
        sx={{
          paddingTop: '6px',
          paddingBottom: '6px',
          paddingLeft: '1.5%',
          fontWeight: 'bold',
        }}
      >
        <Box sx={{ paddingTop: '2px', fontSize: '12px',minWidth:'200px' }}>
          Expand Dashboard Filters
        </Box>
      </Box>
    )}
  <Collapse in={open} sx={{ width: '95%' }}>
    <Box sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap',justifyContent:'center' }}>
    <Paper elevation={theme.palette.mode=='dark'?4:2} sx={{  backgroundColor: theme.background.backgroundColor,width: '400px', marginLeft: '1%', paddingTop: '8px', marginTop: '6px', paddingLeft: '1%', paddingRight: '2%', paddingBottom: '0px', display: 'flex', alignItems: 'center' }}>
      <Box sx={{ maxWidth: '65px', fontSize: '12px', fontWeight: 'bold', textAlign: 'center',  paddingBottom: '10px' }}>Dashboard Filters</Box>
      <Box sx={{ display: 'flex', justifyContent: 'flex-start', flex: 1, flexWrap: 'wrap' }}>
       
          <Box sx={{ marginBottom: '0px', marginLeft: '2%', marginRight: '1%' }}>
                <List size='small' disablePadding sx={{ width:'300px', display: 'flex', flexDirection: 'row'}}>
                <Select size="small" 
                  sx={{backgroundColor:theme.tableHeader.highlight,width:'300px'}}
                  multiple
                  value={checkedCategory} // Use checkedCategory here
                  onChange={handleCheckedChangeCategory} // Use handleCheckedChangeCategory here
                  renderValue={(selected) => {
                    if (selected.length === 0) {
                      return "No Items Selected";
                    }
                    else if (selected.includes(10) && selected.includes(11) && selected.includes(12)&&selected.includes(20) && selected.includes(21) && selected.includes(22)&& selected.includes(23)&& selected.includes(24)&&selected.includes(30) && selected.includes(31) && selected.includes(32)&& selected.includes(4)) {
                      return <Typography variant="body2">All Categories: No Filter Applied</Typography>;
                    }
                    else if (selected.includes(10) && selected.includes(11) && selected.includes(12)&&!selected.includes(2)&&!selected.includes(3)&&!selected.includes(4)&&!selected.includes(20)&&!selected.includes(21)&&!selected.includes(22)&&!selected.includes(23)&&!selected.includes(24)&&!selected.includes(30)&&!selected.includes(31)&&!selected.includes(32)){
                      return <Typography variant="body2">Application Software</Typography>;
                    }
                    else if (selected.includes(20) && selected.includes(21) && selected.includes(22)&& selected.includes(23)&& selected.includes(24)&&!selected.includes(1)&&!selected.includes(3)&&!selected.includes(4)&&!selected.includes(10)&&!selected.includes(11)&&!selected.includes(12)&&!selected.includes(30)&&!selected.includes(31)&&!selected.includes(32)){
                      return <Typography variant="body2">Infrastructure Software</Typography>;
                    }
                    else if (selected.includes(30) && selected.includes(31) && selected.includes(32)&&!selected.includes(2)&&!selected.includes(1)&&!selected.includes(4)&&!selected.includes(20)&&!selected.includes(21)&&!selected.includes(22)&&!selected.includes(23)&&!selected.includes(24)&&!selected.includes(10)&&!selected.includes(11)&&!selected.includes(12)){
                      return <Typography variant="body2">Vertical Software</Typography>;
                    }
                    else if (selected.length === 1) {
                      const selectedItem = category.find(item => item.id === selected[0]); // Use category here
                      return <Typography variant="body2">{selectedItem.label}</Typography>;
                    } else {
                      return <Typography variant="body2">(multiple items selected)</Typography>;
                    }
                  }}
                  MenuProps={{
                    PaperProps: {
                      style: {
                        maxHeight: 250,
                        width: 'auto',
                      },
                    },
                  }}
                >
                  {category.slice(0,1).map((item) => (
                    <MenuItem size="small" key={item.id} value={item.id} sx={{ height:'40px'}}>
                      
                      <ListItemIcon size="small" >
                        {item.icon}
                      </ListItemIcon>
                      
                      <ListItemText primary={
                        <Typography variant="body2"> {/* Set variant="body2" for smaller text */}
                          {item.label}
                        </Typography>
                      } />
                      <IconButton size="small" onClick={handleExpandAppToggle}>  
                      {expandedApp ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                      </IconButton> 
                      <Checkbox  indeterminate={(checkedCategory.indexOf(1)!== -1 && 
                        (checkedCategory.indexOf(10)=== -1||checkedCategory.indexOf(11)=== -1||checkedCategory.indexOf(12)=== -1))
                        ||(checkedCategory.indexOf(1)=== -1 && 
                        (checkedCategory.indexOf(10)!== -1||checkedCategory.indexOf(11)!== -1||checkedCategory.indexOf(12)!== -1))} 
                        size="small" checked={checkedCategory.indexOf(item.id) !== -1} /> {/* Use checkedCategory here */}
                    </MenuItem>
                  ))}
                  {expandedApp && category.slice(1,4).map((item) => (
                    <MenuItem size="small" key={item.id} value={item.id} sx={{height:'26px'}}>
                    <Box sx={{width:'18px'}}></Box> 
                    <ListItemIcon  size="small" >
                      {item.icon}
                    </ListItemIcon>
                    
                    <ListItemText  primary={
                      <Typography variant="body2"> {/* Set variant="body2" for smaller text */}
                        {item.label}
                      </Typography>
                    } />
                    <Checkbox size="small" checked={checkedCategory.indexOf(item.id) !== -1} /> {/* Use checkedCategory here */}
                  </MenuItem>                 
                  ))}
                  {category.slice(4,5).map((item) => (
                    <MenuItem size="small" key={item.id} value={item.id} sx={{ height:'40px'}}>
                      
                    <ListItemIcon size="small" >
                      {item.icon}
                    </ListItemIcon>
                    <ListItemText primary={
                      <Typography variant="body2"> {/* Set variant="body2" for smaller text */}
                        {item.label}
                      </Typography>
                    } />
                    <IconButton size="small" onClick={handleExpandInfraToggle}>  
                      {expandedInfra ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                      </IconButton> 
                    <Checkbox indeterminate={(checkedCategory.indexOf(2)!== -1 && 
                        (checkedCategory.indexOf(20)=== -1||checkedCategory.indexOf(21)=== -1||checkedCategory.indexOf(22)=== -1||checkedCategory.indexOf(23)=== -1||checkedCategory.indexOf(24)=== -1))
                        ||(checkedCategory.indexOf(2)=== -1 && 
                        (checkedCategory.indexOf(20)!== -1||checkedCategory.indexOf(21)!== -1||checkedCategory.indexOf(22)!== -1||checkedCategory.indexOf(23)!== -1||checkedCategory.indexOf(24)!== -1))} 
                        size="small" checked={checkedCategory.indexOf(item.id) !== -1} /> {/* Use checkedCategory here */}
                  </MenuItem>
                  ))}
                  {expandedInfra && category.slice(5,10).map((item) => (
                    <MenuItem size="small" key={item.id} value={item.id} sx={{ height:'26px'}}>
                    <Box sx={{width:'18px'}}></Box> 
                    <ListItemIcon size="small" >
                      {item.icon}
                    </ListItemIcon>
                    <ListItemText primary={
                      <Typography variant="body2"> {/* Set variant="body2" for smaller text */}
                        {item.label}
                      </Typography>
                    } />
                    <Checkbox size="small" checked={checkedCategory.indexOf(item.id) !== -1} /> {/* Use checkedCategory here */}
                  </MenuItem>
                  ))}
                  {category.slice(11,12).map((item) => (
                    <MenuItem size="small" key={item.id} value={item.id} sx={{ height:'40px'}}>
                      
                    <ListItemIcon size="small" >
                      {item.icon}
                    </ListItemIcon>
                    <ListItemText primary={
                      <Typography variant="body2"> {/* Set variant="body2" for smaller text */}
                        {item.label}
                      </Typography>
                    } />
                    <IconButton size="small" onClick={handleExpandVertToggle}>  
                      {expandedVert ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                      </IconButton> 
                    <Checkbox indeterminate={(checkedCategory.indexOf(3)!== -1 && 
                        (checkedCategory.indexOf(30)=== -1||checkedCategory.indexOf(31)=== -1||checkedCategory.indexOf(32)=== -1))
                        ||(checkedCategory.indexOf(3)=== -1 && 
                        (checkedCategory.indexOf(30)!== -1||checkedCategory.indexOf(31)!== -1||checkedCategory.indexOf(32)!== -1))} 
                        size="small" checked={checkedCategory.indexOf(item.id) !== -1} /> {/* Use checkedCategory here */}
                  </MenuItem>
                  ))}
                  {expandedVert && category.slice(12,15).map((item) => (
                    <MenuItem size="small" key={item.id} value={item.id} sx={{ height:'26px'}}>
                      <Box sx={{width:'18px'}}></Box> 
                    <ListItemIcon size="small" >
                      {item.icon}
                    </ListItemIcon>
                    <ListItemText primary={
                      <Typography variant="body2"> {/* Set variant="body2" for smaller text */}
                        {item.label}
                      </Typography>
                    } />
                    <Checkbox size="small" checked={checkedCategory.indexOf(item.id) !== -1} /> {/* Use checkedCategory here */}
                  </MenuItem>
                  ))}
                  
                </Select>
                </List>
                <Box sx={{  paddingBottom:'4px',marginTop:'2px',fontSize: '11px', alignItems: 'center', textAlign: 'center' }}>Filter by Software Category</Box>
              </Box>
              
              
              </Box>
              
            </Paper>
            
          </Box>
          <Box sx={{ marginTop: '6px' }}></Box>
        </Collapse>

        <IconButton
          sx={{
            p: 0.1,
            fontSize: 15,
            marginTop: '2px',
            marginRight: '2px',


          }}
          onClick={handleToggleCollapse}
          aria-label="toggle-collapse"
        >
          {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </IconButton>
        </Paper>
        
    <Paper elevation={3} sx={{maxWidth:'1500px', paddingBottom:'0px',paddingTop: '6px', marginTop: '6px', flex: 1, paddingLeft: '1%',marginLeft:'1%',paddingRight:'1%'}}>
      <Box>
        <Box sx={{ color: theme.palette.primary.main, fontSize: '24px', fontWeight: '600' }}>Top Unicorns
        </Box>

      </Box>

      <TableContainer component={Paper} sx={{ cursor: 'pointer', maxHeight: open? '69vh':'76vh', marginTop: '4px', overflowY: 'auto', overflowX: 'auto', maxWidth: '1350px' }} ref={tableContainerRef}>
        <Table size='small' aria-label="acquisition table">
          <TableHead>
            <TableRow sx={{ backgroundColor: theme.tableHeader.color, fontWeight: 'bold', position: 'sticky', top: 0, zIndex: 2 }}>
              {tableColumns.map((column, index) => (
                <TableCell key={index} onClick={() => handleSort(column.field)}   sx={{ textAlign: 'center' ,maxWidth:'75',position: 'sticky', top: 0, zIndex: 1, backgroundColor: theme.tableHeader.color, fontWeight: 'bold' }}>
                  
                  <Typography sx={{maxWidth:'75px',fontSize:'small',fontWeight:'bold', display:'flex',flexDirection:'column',justifyContent:'space-between'}}>
                      {column.label}
                        <Box sx={{display:'flex',justifyContent:'space-between'}}>
                          <ArrowUpward sx={{ opacity: (sortField === column.field && sortOrder === 'asc') ? 1 : 0.2, fontSize: 'small' }} />
                          <ArrowDownward sx={{ opacity: (sortField === column.field && sortOrder === 'desc') ? 1 : 0.2, fontSize: 'small' }} />
                        </Box>
                      </Typography>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody sx={{ position: 'relative', zIndex: 1 }}>
            {sortedData.map((company, index) => (
              <TableRow key={index} sx={{backgroundColor: index % 2 === 0 ? theme.tableHeader.highlight: theme.background.default,
                '&:last-child td, &:last-child th': { border: 0 },
                position: 'relative',
                ':hover': {
                  backgroundColor: theme.tableHeader.highlightDark,
                },
              }}>
            {tableColumns.map((column, columnIndex) => (
            <TableCell key={columnIndex} sx={columnIndex === 0 ? {
              maxWidth: '130px',
              top: 0,
              zIndex: 1,
              left: 0
            } : {}}>
              {columnIndex === 0 ? (
                <NavLink to={`/${company.Ticker}`} style={{ textDecoration: 'none', color: 'inherit', display: 'flex', alignItems: 'center' }}>
                  <span>{company['Company Name']}</span>
                  <ArrowForward sx={{ paddingLeft: '4px', opacity: 0.2, fontSize: 'small' }} />
                </NavLink>
              ) : (
                company[column.field]
              )}
            </TableCell>
          ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Box sx={{ paddingBottom: '1vh' }}></Box>
    </Paper>
    </Box>
  </>
      )}
      export default UnicornTable;