import React from "react";
import ReactGA from 'react-ga4';
import { useLocation } from 'react-router-dom';
import {  useEffect } from 'react';
import TwitterFollowButton from "../components/TwitterFollowButton";
import { alpha,Container,Link,Typography,Card,Chip,Grid,Stack,Box,Button } from '@mui/material';
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';
import BarChartIcon from '@mui/icons-material/BarChart';
import TerminalIcon from '@mui/icons-material/Terminal';
import AutoGraphIcon from '@mui/icons-material/AutoGraph';
import { useTheme } from '@mui/material/styles';

const items = [
  {
    icon: <BarChartIcon />,
    title: 'Metric Dashboard',
    description:
      'Explore key business metrics across companies and industries.',
    imageLight: `url(${require('../data/images/metric.PNG')})`,
    imageDark: `url(${require('../data/images/metricdark.PNG')})`,
    link:"/industrymetrics",
    linkLabel:"VIEW THE DASHBOARD",
  },
  {
    icon: <AutoGraphIcon />,
    title: 'Software Earnings Calendar',
    description:
      'See upcoming software reports.',
    imageLight: `url(${require('../data/images/earnings.png')})`,
    imageDark: `url(${require('../data/images/earningsdark.png')})`,
    link:"/earnings/calendar",
    linkLabel:"SEE THE CALENDAR",
  },
  {
    icon: <TerminalIcon />,
    title: 'Market Insights',
    description:
      'Visualize long-term trends in cloud service provider growth.',
    imageLight: `url(${require('../data/images/opensource.png')})`,
    imageDark: `url(${require('../data/images/opensourcedark.PNG')})`,
    link:"/cloudservice",
    linkLabel:"VIEW THE DASHBOARD",
  },
];


function Home(){
  const [selectedItemIndex, setSelectedItemIndex] = React.useState(0);

  const handleItemClick = (index) => {
    setSelectedItemIndex(index);
  };

  const selectedFeature = items[selectedItemIndex];

  const theme = useTheme();
  const location = useLocation(); // If using React Router

  useEffect(() => {
    ReactGA.send({ hitType: 'pageview', page: location.pathname });
  }, [location.pathname]);

    return(
      <>
       
      <Container
        sx={{
          position: 'relative', // Add relative positioning
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          pt: { xs: 14, sm: 20 },
          pb: { xs: 8, sm: 12 },
        }}
      >
        
        <Stack spacing={2} useFlexGap sx={{ width: { xs: '100%', sm: '70%' } }}>

        <Typography
          component="h1"
          variant="h1"
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
            alignSelf: 'center',
            textAlign: 'center',
            marginTop: '-110px',
            color: theme.palette.mode === 'dark' ? '#FFF' : 'inherit',
            fontSize: {
              xs: theme.typography.h2.fontSize, // smaller font size for xs screens
              md: theme.typography.h1.fontSize, // default font size for md screens
            },
          }}
        >
          Software
          <Typography
            component="span"
            variant="h1"
            sx={{
              color: theme.palette.mode === 'light' ? 'primary.main' : 'primary.light',
              fontSize: {
                xs: theme.typography.h2.fontSize, // smaller font size for xs screens
                md: theme.typography.h1.fontSize, // default font size for md screens
              },
            }}
          >
            Research Platform
          </Typography>
        </Typography>
          <Typography variant="body1" textAlign="center" color="text.secondary">
          Explore a investment research platform tailored for software companies, crafted by industry experts.
            <br />
            Elevate your research with a purpose-built vertical platform.
          </Typography>
          <Box sx={{ margin: '0 auto', display: 'flex', justifyContent: 'center', alignItems: 'center', paddingBottom: '4px' }}>
            <Typography variant="body1" textAlign="center" color="text.secondary" sx={{paddingRight:'10px'}}>
              Send us Feedback on Twitter:
            </Typography>
            <TwitterFollowButton />
          </Box>
        </Stack>
        <Box
          id="image"
          style={{
            marginTop: "20px",
            paddingTop: '10px',
            paddingLeft: '10px',
            paddingRight: '10px',
            paddingBottom: '10px',
            alignSelf: 'center',
            height: 'auto', // Adjust height dynamically based on image height
            width: 'auto', // Constrain the width to the width of the image
            maxWidth: '100%', // Ensure the container doesn't exceed the viewport width
            borderRadius: '10px',
            outline: '1px solid',
            outlineColor: theme.palette.mode === 'light' ? alpha('#BFCCD9', 0.5) : alpha('#9CCCFC', 0.1),
            boxShadow: theme.palette.mode === 'light' ? `0 0 12px 8px ${alpha('#9CCCFC', 0.2)}` : `0 0 24px 12px ${alpha('#033363', 0.2)}`,
          }}
        >
          <Box sx={{display:'flex',justifyContent: 'space-between', alignItems: 'center'}}>
          <Typography component="h2" variant="h4" sx={{
                color: (theme) =>
                  theme.palette.mode === 'light' ? 'primary.main' : 'primary.light',
              }}>
              Security Analysis
            </Typography>
            
            </Box>
            <Typography
              variant="body1"
              color="text.secondary"
            >
              Explore long-term trends in gaap and non-gaap metrics for software securities.
            </Typography>
          <img
            src={
              theme.palette.mode === 'light'
                  ? require('../data/images/mainpage.png')
                  : require('../data/images/mainpagedark.png')
            }
            alt="Maing Page"
            style={{
              display: 'block', // Ensure the image takes up the full width of its container
              width: '100%', // Ensure the image takes up the full width of its container
              height: '100%', // Ensure the image takes up the full height of its container
              objectFit: 'contain', // Constrain the image to fit within its container while preserving its aspect ratio
              borderRadius: '10px', // Apply border radius to match the container's border radius
            }}
          />
        </Box>
      </Container>
      <Container id="features" sx={{ py: { xs: 1, sm: 2 } }}>
      <Grid container spacing={6}>
        <Grid item xs={12} md={6}>
          <div>
            <Typography component="h2" variant="h4" color="text.primary">
              Industry Insights
            </Typography>
            <Typography
              variant="body1"
              color="text.secondary"
              sx={{ mb: { xs: 2, sm: 4 } }}
            >
              Unlock critical insights across software markets.
            </Typography>
          </div>
          <Grid container item gap={1} sx={{ display: { xs: 'auto', sm: 'none' } }}>
            {items.map(({ title }, index) => (
              <Chip
                key={index}
                label={title}
                onClick={() => handleItemClick(index)}
                sx={{
                  borderColor: (theme) => {
                    if (theme.palette.mode === 'light') {
                      return selectedItemIndex === index ? 'primary.light' : '';
                    }
                    return selectedItemIndex === index ? 'primary.light' : '';
                  },
                  background: (theme) => {
                    if (theme.palette.mode === 'light') {
                      return selectedItemIndex === index ? 'none' : '';
                    }
                    return selectedItemIndex === index ? 'none' : '';
                  },
                  backgroundColor: selectedItemIndex === index ? 'primary.main' : '',
                  '& .MuiChip-label': {
                    color: selectedItemIndex === index ? '#fff' : '',
                  },
                }}
              />
            ))}
          </Grid>
          <Box
            component={Card}
            variant="outlined"
            sx={{
              display: { xs: 'auto', sm: 'none' },
              mt: 4,
            }}
          >
            <Box
              sx={{
                backgroundImage: (theme) =>
                  theme.palette.mode === 'light'
                    ? items[selectedItemIndex].imageLight
                    : items[selectedItemIndex].imageDark,
                
                backgroundSize: '100% auto',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center center',
                minHeight: 280,
              }}
            />
            <Box sx={{ px: 2, pb: 2 }}>
              <Typography color="text.primary" variant="body2" fontWeight="bold">
                {selectedFeature.title}
              </Typography>
              <Typography color="text.secondary" variant="body2" sx={{ my: 0.5 }}>
                {selectedFeature.description}
              </Typography>
              <Link
                href={selectedFeature.link} 
                color="primary"
                variant="body2"
                fontWeight="bold"
                sx={{
                  display: 'inline-flex',
                  alignItems: 'center',
                  '& > svg': { transition: '0.2s' },
                  '&:hover > svg': { transform: 'translateX(2px)' },
                }}
              >
                <span>{selectedFeature.linkLabel}</span>
                <ChevronRightRoundedIcon
                  fontSize="small"
                  sx={{ mt: '1px', ml: '2px' }}
                />
              </Link>
            </Box>
          </Box>
          <Stack
            direction="column"
            justifyContent="center"
            alignItems="flex-start"
            spacing={2}
            useFlexGap
            sx={{ width: '100%', display: { xs: 'none', sm: 'flex' } }}
          >
            {items.map(({ icon, title, description, link,linkLabel }, index) => (
              <Card
                key={index}
                component={Button}
                onClick={() => handleItemClick(index)}
                sx={{
                  p: 3,
                  height: 'fit-content',
                  width: '100%',
                  background: 'none',
                  backgroundColor:
                    selectedItemIndex === index ? 'action.selected' : undefined,
                  borderColor: (theme) => {
                    if (theme.palette.mode === 'light') {
                      return selectedItemIndex === index
                        ? 'primary.light'
                        : 'grey.200';
                    }
                    return selectedItemIndex === index ? 'primary.dark' : 'grey.800';
                  },
                }}
              >
                <Box
                  sx={{
                    width: '100%',
                    display: 'flex',
                    textAlign: 'left',
                    flexDirection: { xs: 'column', md: 'row' },
                    alignItems: { md: 'center' },
                    gap: 2.5,
                  }}
                >
                  <Box
                    sx={{
                      color: (theme) => {
                        if (theme.palette.mode === 'light') {
                          return selectedItemIndex === index
                            ? 'primary.main'
                            : 'grey.300';
                        }
                        return selectedItemIndex === index
                          ? 'primary.main'
                          : 'grey.700';
                      },
                    }}
                  >
                    {icon}
                  </Box>
                  <div>
                    <Typography
                      color="text.primary"
                      variant="body2"
                      fontWeight="bold"
                    >
                      {title}
                    </Typography>
                    <Typography
                      color="text.secondary"
                      variant="body2"
                      sx={{ my: 0.5 }}
                    >
                      {description}
                    </Typography>
                    <Link
                      href={link} 
                      color="primary"
                      variant="body2"
                      fontWeight="bold"
                      sx={{
                        display: 'inline-flex',
                        alignItems: 'center',
                        '& > svg': { transition: '0.2s' },
                        '&:hover > svg': { transform: 'translateX(2px)' },
                      }}
                      onClick={(event) => {
                        event.stopPropagation();
                      }}
                    >
                      <span>{linkLabel}</span>
                      <ChevronRightRoundedIcon
                        fontSize="small"
                        sx={{ mt: '1px', ml: '2px' }}
                      />
                    </Link>
                  </div>
                </Box>
              </Card>
            ))}
          </Stack>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          sx={{ display: { xs: 'none', sm: 'flex' }, width: '100%' }}
        >
          <Card
            variant="outlined"
            sx={{
              height: '100%',
              width: '100%',
              display: { xs: 'none', sm: 'flex' },
              pointerEvents: 'none',
            }}
          >
            <Box
              sx={{
                m: 'auto',
                width: 420,
                height: 500,
                backgroundSize: '100% auto',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center center',
                backgroundImage: (theme) =>
                  theme.palette.mode === 'light'
                    ? items[selectedItemIndex].imageLight
                    : items[selectedItemIndex].imageDark,
              }}
            />
          </Card>
        </Grid>
      </Grid>
    </Container>
      </>
    )
}
export default Home