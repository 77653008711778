import React, { useState,  useRef, useMemo } from "react";
import { Collapse,IconButton,Box, Paper, Typography,Link } from "@mui/material";
import { AgGridReact } from "ag-grid-react";
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import coverage from "../data/charts/unicorn2.json";
import { useTheme } from "@mui/material/styles";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-material.css";
import CategoryFilter from "../components/FilterCategory.jsx";
import { useMediaQuery } from "@mui/material";
import { Link as RouterLink } from 'react-router-dom';
import FilterTableFields from "../components/FilterTableFields.jsx";
import ButtonExport from "../components/ButtonExport.jsx";


function MarketCoverage({open, handleToggleCollapse}) {
  
  const theme = useTheme();
  const [expandedGroups, setExpandedGroups] = useState({});

  
  const [rowData] = useState(coverage);
  const gridRef = useRef(null); // Reference for the AG Grid
  
  const isTablet = useMediaQuery('(max-width:800px)'); // Adjust breakpoint as needed
  const isMobile  = useMediaQuery('(max-width:480px)'); // Adjust breakpoint as needed

  const [selectedColumns, setSelectedColumns] = useState(isMobile?[
    "Company Name",
    "Post-Money Valuation",
    "ARR"
  ]:[
    "Company Name",
    "Employees",
    "Year Founded",
    "Last Valuation Date",
    "Series",
    "Post-Money Valuation",
    "ARR",
    "ARR Reported Date"

  ]);
  const [selectedCategory, setSelectedCategory] = useState([1,2,3,10,11,12,20,21,22,23,24,30,31,32,4]); // Change to array for multiple selections
  const filterCategories = [

      { id: 10, label: 'CRM, Marketing, and Support' },
      { id: 11, label: 'HR, Finance & Supply Chain' },
      { id: 12, label: 'Productivity & Content Creation' },
      { id: 20, label: 'Database & Data Platforms' },
      { id: 21, label: 'Dev Tools & Low Code' },
      { id: 22, label: 'IT Operations' },
      { id: 23, label: 'Security & Endpoint Management' },
      { id: 24, label: 'Networking & CDN' },
      { id: 30, label: 'Financials' },
      { id: 31, label: 'Engineering & Industrials' },
      { id: 32, label: 'Other Vertical' },
      { id: 4, label: 'Diversified'},
  ];
  

  // Function to handle category filter change
  const handleCategoryChange = (event) => {
    
    const { value: selectedItems } = event.target;

    setSelectedCategory(prevCheckedCategory => {
        // Check if '1' has been newly checked
        const oneChecked = selectedItems.includes(1) && !prevCheckedCategory.includes(1);
        const oneUnchecked = !selectedItems.includes(1) && prevCheckedCategory.includes(1);
        const twoChecked = selectedItems.includes(2) && !prevCheckedCategory.includes(2);
        const twoUnchecked = !selectedItems.includes(2) && prevCheckedCategory.includes(2);
        const threeChecked = selectedItems.includes(3) && !prevCheckedCategory.includes(3);
        const threeUnchecked = !selectedItems.includes(3) && prevCheckedCategory.includes(3);

        // Initialize the new state with the selected items
        let newState = [...selectedItems];

        if (oneChecked) {
            newState.push(10,11,12); 
        }
        if (oneUnchecked) {
          newState = newState.filter(item => ![10, 11, 12].includes(item));
        }
        if (twoChecked) {
          newState.push(20,21,22,23,24); 
        }
        if (twoUnchecked) {
          newState = newState.filter(item => ![20,21,22,23,24].includes(item));
        }
        if (threeChecked) {
          newState.push(30,31,32); 
        }
        if (threeUnchecked) {
          newState = newState.filter(item => ![30,31,32].includes(item));
        }
        if (!oneChecked && !oneUnchecked && newState.includes(10) && newState.includes(11) && newState.includes(12)&&!newState.includes(1)) {
          newState.push(1);
        }
        if (!oneChecked && !oneUnchecked && !newState.includes(10) && !newState.includes(11) && !newState.includes(12)&&newState.includes(1)) {
          newState = newState.filter(item => ![1].includes(item));
        }
        if (!twoChecked && !twoUnchecked && newState.includes(20) && newState.includes(21) && newState.includes(22) && newState.includes(23) && newState.includes(24) &&!newState.includes(2)) {
          newState.push(2);
        }
        if (!twoChecked && !twoUnchecked && !newState.includes(20) && !newState.includes(21) && !newState.includes(22)&& !newState.includes(23)&& !newState.includes(24)&&newState.includes(2)) {
          newState = newState.filter(item => ![2].includes(item));
        }
        if (!threeChecked && !threeUnchecked && newState.includes(30) && newState.includes(31) && newState.includes(32)&&!newState.includes(3)) {
          newState.push(3);
        }
        if (!threeChecked && !threeUnchecked && !newState.includes(30) && !newState.includes(31) && !newState.includes(32)&&newState.includes(3)) {
          newState = newState.filter(item => ![3].includes(item));
        }
        return newState;
    });
}

  // Filter the data based on the selected category
  const filteredData = useMemo(() => {
    if (selectedCategory.length === 0) {
      return []; // Return all data if no category is selected
    }
    
    // Get the labels corresponding to the selected category IDs
    const selectedLabels = filterCategories
      .filter(category => selectedCategory.includes(category.id))
      .map(category => category.label);
    
    return rowData.filter(row => selectedLabels.includes(row.Market));
  }, [rowData, selectedCategory, filterCategories]);
  const valuationCellRenderer = (params) => {
    let valuation = params.value/1000000000;
    valuation = valuation < 100 ? valuation.toFixed(1) : valuation.toFixed(0);
    return valuation !== null && valuation !== undefined 
      ? `$${valuation.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}B` 
      : '';
  };
  
  const companyCellRenderer = (params) => {
    const ticker = params.data?.Ticker;
    const companyName = params.value;
    const iconSrc = theme.palette.mode === 'dark'
      ? `/logos/${ticker}/icondark.png`
      : `/logos/${ticker}/icon.png`;

    return (
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <img 
          src={iconSrc} 
          alt={`${companyName} logo`} 
          style={{ 
            width: 24, 
            height: 24, 
            marginRight: 6, 
            objectFit: 'contain' 
          }} 
        />
        <Link component={RouterLink} to={`/${ticker}` }>{companyName}</Link>
      </Box>
    );
  };

  const employeesCellRenderer = (params) => {
    const employees = params.value;
    return employees ? employees.toLocaleString() : '';
  };

  const arrCellRenderer = (params) => {
    let valuation = params.value;
    
    // Check if the valuation is valid
    if (valuation === null || valuation === undefined || isNaN(valuation)) {
      return "-";
    }
    
    // Convert to billions or millions as necessary

      valuation = (valuation / 1000000).toFixed(0); // Rounds to 1 decimal for millions
      return `$${valuation.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}M`;
    
  };
  const numericalComparator = (valueA, valueB, nodeA, nodeB, isInverted) => {
    if ((valueA === null|| valueA === undefined) && (valueB === null|| valueB === undefined)) {
        return 0;
    }
    if (valueA === null|| valueA === undefined) {
        return isInverted ? -1 : 1;
    }
    if (valueB === null|| valueB === undefined) {
        return isInverted ? 1 : -1;
    }
  
    return valueA - valueB;
  }
  const dateComparator = (valueA, valueB, nodeA, nodeB, isInverted) => {
    const dateA = new Date(valueA);
    const dateB = new Date(valueB);
  
    // Handle null, undefined, or invalid dates
    if ((isNaN(dateA) && isNaN(dateB)) || (!valueA && !valueB)) {
      return 0;
    }
    if (isNaN(dateA) || !valueA) {
      return isInverted ? -1 : 1;
    }
    if (isNaN(dateB) || !valueB) {
      return isInverted ? 1 : -1;
    }
  
    // Compare the dates
    return dateA - dateB;
  };
  const dateFormater =(params)=>{
      if (params.value === null || params.value === undefined ) {
        return "-";
      }
        const date = new Date(params.value);
        return date.toLocaleDateString(); 
  
  }
  
const allColDefs = [
  { field: "Company Name", filter: "agTextColumnFilter", floatingFilter: false, cellRenderer: companyCellRenderer, maxWidth:200,width:200, pinned:'left', minWidth:190,},
  { field: "Category", filter: "agTextColumnFilter", floatingFilter: false, maxWidth: 180,width:150, minWidth: 140 },
  { field: "Market", filter: "agTextColumnFilter", floatingFilter: false, maxWidth: 240,width:200, minWidth: 150 },
  { field: "Employees", filter: "agNumberColumnFilter", floatingFilter: false, cellRenderer: employeesCellRenderer, maxWidth: 180, width:100,minWidth: 90, sortingOrder: ["desc", "asc", null] },
  { field: "Year Founded", filter: "agTextColumnFilter", floatingFilter: false, maxWidth: 180,width:100, minWidth: 90, sortingOrder: ["desc", "asc", null] },
  { field: "Post-Money Valuation", filter: "agNumberColumnFilter", floatingFilter: false, cellRenderer: valuationCellRenderer, maxWidth: 180,width:120, minWidth: 90, sortingOrder: ["desc", "asc", null] },
  { field: "Series", filter: "agTextColumnFilter", floatingFilter: false, maxWidth: 200,width:150, minWidth: 90, sortingOrder: ["desc", "asc", null] },
  { field: "Last Valuation Date", filter: "agDateColumnFilter",
    

    valueFormatter: dateFormater,
    filterParams: {
      // Custom comparator for date filtering
      comparator: (filterDate, cellValue) => {
          // Ensure both values are valid dates
          if (!filterDate || !cellValue) return 0; // Not comparable
          const filterDateObj = new Date(filterDate);
          const cellDateObj = new Date(cellValue);
          
          if (cellDateObj < filterDateObj) return -1; // Cell date is before filter date
          if (cellDateObj > filterDateObj) return 1;  // Cell date is after filter date
          return 0; // Dates are equal
      }
  },
  floatingFilter: false, maxWidth: 180,width:110, minWidth: 100 },


  { field: "ARR", filter: "agNumberColumnFilter", floatingFilter: false,comparator: numericalComparator, cellRenderer: arrCellRenderer, maxWidth: 180,width:100, minWidth: 90, sortingOrder: ["desc", "asc", null] },
  { field: "ARR Reported Date", filter: "agDateColumnFilter",
    

    valueFormatter: dateFormater,comparator: dateComparator,
    filterParams: {
      // Custom comparator for date filtering
      comparator: (filterDate, cellValue) => {
          // Ensure both values are valid dates
          if (!filterDate || !cellValue) return 0; // Not comparable
          const filterDateObj = new Date(filterDate);
          const cellDateObj = new Date(cellValue);
          
          if (cellDateObj < filterDateObj) return -1; // Cell date is before filter date
          if (cellDateObj > filterDateObj) return 1;  // Cell date is after filter date
          return 0; // Dates are equal
      }
  },
  floatingFilter: false, maxWidth: 180,width:110, minWidth: 100 },


];
  // Group columns by their 'group' property

  const handleColumnsChange = (event) => {
    const { value } = event.target;

    if (Array.isArray(value)) {
      setSelectedColumns(value);
      return;
    }}

  const filteredColDefs = useMemo(() => { 
    return allColDefs.filter((colDef) => selectedColumns.includes(colDef.field));
  }, [selectedColumns,theme.palette.mode]);
  const tableWidth = Math.max(
    filteredColDefs.reduce((sum, colDef) => sum + colDef.width, 0) + 10,
    500
  );
  const defaultColDef = useMemo(() => ({
    filter: true,
    floatingFilter: false,
    sortable: true,
    resizable: true,
    suppressMenuHide: false,
    suppressHeaderMenuButton: false,
  }), []);

  return (
    <Box sx={{ height: '100%', marginBottom: '0px', display: 'flex', flexDirection: 'column' }}>


    <Box
      sx={{
        backgroundColor: theme.background.backgroundColor,
        marginBottom: '12px', // Ensure no gap
        borderTopLeftRadius: '0px',
        borderTopRightRadius: '0px',
        boxShadow: theme.palette.mode === 'light'
      ? '0px 1px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 3px 3px 0px rgba(0,0,0,0.12)' // Light mode
      : '0px 1px 1px -1px rgba(0,0,0,0.4), 0px 1px 1px 0px rgba(0,0,0,0.24), 0px 3px 3px 0px rgba(0,0,0,0.4)', // Dark mode
      }}
      elevation={0} // No default elevation, custom shadow
    >
      <Collapse in={open} sx={{ width: '100%' }}>
          <Box sx={{ position: 'relative', marginTop:'0px',paddingLeft: '0%', marginLeft: '0px' }}>
                <Box elevation={0} sx={{ paddingBottom: '4px', maxWidth: '100%', paddingTop: '0px', marginTop: '0px', marginBottom: '0px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>

                <Box sx={{ display: 'flex', width:'930px',flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'center' }}>

          
          <Box
            elevation={theme.palette.mode == 'dark' ? 0 : 0}
            sx={{
              border: '0px solid lightgrey',
              width: '100%',
              maxWidth: '660px',
              marginLeft: '6px',
              paddingTop: '4px',
              marginTop: '0px',
              paddingLeft: '6px',
              paddingRight: '8px',
              paddingBottom: '0px',
              display: 'flex',
              alignItems: 'center',
              flexWrap: 'wrap'
            }}
          >
            <Box
              sx={{
                maxWidth: isMobile?'200px':'65px',
                fontSize: '12px',
                textAlign: 'center',
                paddingBottom: '2px',
                paddingTop: '4px',
                color: theme.palette.text.secondary,
                flexShrink: 0,
              }}
            >
              Dashboard Filters
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'flex-start', flex: 1, flexWrap: 'wrap' }}>
              <Box sx={{marginTop:'2px', marginBottom: '2px', marginLeft: '2%', marginRight: '1%', flexShrink: 1 }}>
                <CategoryFilter 
                  selectedCategory={selectedCategory} 
                  handleCategoryChange={handleCategoryChange} 
                />
              </Box>
              <Box sx={{ marginBottom: '2px', marginLeft: '2%', marginRight: '1%', flexShrink: 1 }}>
                <FilterTableFields
                selectedColumns={selectedColumns}
                handleColumnsChange={handleColumnsChange}
                allColDefs={allColDefs}
                />
              </Box>
            </Box>
          </Box>
        </Box>


        <IconButton
            sx={{
              p: 0.1,
              fontSize: 15,
              marginTop: '2px',
              marginRight: '2px',


            }}
            onClick={handleToggleCollapse}
            aria-label="toggle-collapse"
          >
            {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </IconButton>
        </Box>
    </Box>
    </Collapse>
    </Box>
    


  {/* Flex container for centering the grid */}
  
  <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
  <Box sx={{ 
      flex: 1, display: 'flex', 
      justifyContent:'space-between',
      paddingBottom: '6px', 
      width: tableWidth, 
      maxWidth: "98%", 
      color: theme.palette.primary.main, 
      fontSize: '24px', 
      fontWeight: '300 ' , // Use !important to enforce the style
      textAlign: 'left', // Ensure text is left-aligned
      paddingLeft: '0.5%',
  }}
  style={{ fontWeight: '300' }} // Inline style for testing
  >
    VC-Backed Privates
    <ButtonExport
    gridRef={gridRef}
    />
  </Box>
  <Paper
    className={theme.palette.mode === 'dark' ? "ag-theme-material-dark" : "ag-theme-material"}
    style={{
      width: tableWidth, // Ensure Paper fills the available space
      maxWidth: "98%", // Limit max width to the desired table width
      height: '100%',
    "--ag-odd-row-background-color": theme.tableHeader.highlight,
    "--ag-row-hover-color": theme.tableHeader.highlightDark,
    "--ag-material-primary-color": theme.palette.primary.main,
    "--ag-material-accent-color": theme.palette.primary.main,    
    "--ag-header-cell-color": "theme.palette.text.secondary", // For text color
    "--ag-header-height": "50px", // Optional: Adjusts the header height


    }}
    sx={{
      '& .ag-header-cell': {
        backgroundColor: theme.table.header,
        color: theme.palette.text.secondary,
        letterSpacing:"normal",
        wordSpacing:"normal",
        whiteSpace: "normal", // Allow text wrapping
        lineHeight: "1.2em", // Adjust line height for better readability
        textAlign: 'center', // Optional: Center text if needed
        padding: '0px 10px', // Adjust padding as needed (top/bottom left/right)

      },
      '& .ag-header-cell-text': {
        letterSpacing:"normal",
        textTransform:"none",
        wordSpacing:"normal",
        whiteSpace: "normal", // Allow text wrapping
      },
    }}
  >
    <AgGridReact
      ref={gridRef}
      rowData={filteredData}
      columnDefs={filteredColDefs}
      defaultColDef={defaultColDef}
      headerHeight={50}
      rowHeight={30}
    />
  </Paper>
</Box>
</Box>
  );
}

export default MarketCoverage;