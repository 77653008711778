import React from "react";
import { useState,useEffect } from "react";
import { useLocation } from 'react-router-dom';
import '../index.css'
import ReactGA from 'react-ga4';
import { Box,Paper } from "@mui/material";
import { useTheme } from '@mui/material/styles';
import { useAuth0 } from "@auth0/auth0-react"; // Import useAuth0
import UnicornComponent from "../components/UnicornComponent";


function CompSheet() {
  const theme = useTheme();
  const location = useLocation();
  const { isAuthenticated, loginWithRedirect } = useAuth0(); // Use the useAuth0 hook
  useEffect(() => {
    // Redirect to login if the user is not authenticated
    //if (!isAuthenticated) {
    //  loginWithRedirect();
    //}
  }, [isAuthenticated, loginWithRedirect]); // Dependencies ensure this runs when isAuthenticated or loginWithRedirect changes

  const categoryInput = [1, 2, 3, 4, 10, 11, 12, 20, 21, 22, 23, 24, 25, 30, 31, 32];

  useEffect(() => {
    ReactGA.send({ hitType: 'pageview', page: location.pathname });
  }, [location.pathname]);

  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);
  }, []); // The empty dependency array ensures that this effect runs only once when the component mounts

  
  const [selectedButton, setSelectedButton] = useState(0);

  // Function to handle button clicks and update the selected button state
  const handleButtonClick = (index) => {
    setSelectedButton(index);
  };

  //if (!isAuthenticated) {
   // return null; // Optionally, you can show a loading spinner or message here
  //}

  return (
    <>
    <Paper sx={{ backgroundColor: theme.background.backgroundColor,marginBottom:'4px'}} elevation={3}>
            <Box sx={{display:'flex', justifyContent:'center',paddingTop: '8px', paddingBottom: '6px', marginLeft: '2%', color: theme.palette.primary.main, fontSize: '36px', fontWeight: '600' }}>
              VC-Backed Privates
            </Box>
          </Paper>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Box className="containersize" >

          <UnicornComponent />
        </Box>
      </Box>
    </>
  );
}

export default CompSheet;