import { ResponsiveScatterPlot } from '@nivo/scatterplot';
import { Paper } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { FaMarsStroke } from 'react-icons/fa';

const MyResponsiveScatterPlot = ({ data, theme, xLabel, yLabel, hasLabel, hasIcon }) => {
    const navigate = useNavigate();
    const [xTickValues, setXTickValues] = useState([]);
    const [yTickValues, setYTickValues] = useState([]);
    
    // Calculate max values for X and Y
    let maxX = Number.MIN_VALUE;
    let maxY = Number.MIN_VALUE;
    data.forEach(dataset => {
        dataset.data.forEach(point => {
            maxX = Math.max(maxX, point.x);
            maxY = Math.max(maxY, point.y);
        });
    });

    const maxXRange = maxX * 1.05;
    const maxYRange = Math.ceil(maxY * 1.05); // Round up to the nearest whole number
    const isDarkMode = theme.palette.mode === 'dark';

    const calculateTickValues = () => {
        const width = window.innerWidth;
    
        // Define how many ticks based on screen width
        const xTicks = Math.max(2, Math.floor(width / 100)); // Adjust the divisor for tick spacing
        let yTicks = Math.max(2, Math.floor(width / 100));
    
        // Calculate the step size for the y-axis
        const yStepSize = Math.ceil(maxYRange / (yTicks - 1)); // Step size ensuring even spacing and whole numbers
    
        // Generate x tick values as before
        const xTickArray = Array.from({ length: xTicks }, (_, i) => (i / (xTicks - 1)) * maxXRange);
    
        // Generate y tick values with equal step size
        const yTickArray = Array.from({ length: Math.ceil(maxYRange / yStepSize) + 1 }, (_, i) => i * yStepSize);
    
        setXTickValues(xTickArray);
        setYTickValues(yTickArray);
    };
    // Effect to calculate tick values on resize
    useEffect(() => {
        calculateTickValues();
        window.addEventListener('resize', calculateTickValues);
        return () => {
            window.removeEventListener('resize', calculateTickValues);
        };
    }, [maxXRange, maxYRange]);

    const CustomTooltip = ({ node }) => {
        const formattedX = `${(node.data.x * 100).toFixed(0)}%`;
        const formattedY = `${node.data.y.toFixed(1)}`;
        return (
            <Paper
                elevation={3}
                style={{
                    background: isDarkMode ? '#323232' : '#FFFFFF',
                    color: isDarkMode ? 'white' : 'black',
                    fontSize: 'small',
                }}
            >
                <div style={{ textAlign: 'center', paddingTop: 8, paddingBottom: 2, paddingLeft: 6, paddingRight: 6, backgroundColor: theme.tooltip.color }}>
                    <span style={{ fontWeight: 'bold' }}>{node.data.ticker}</span>
                </div>
                <div style={{ paddingLeft: 6, paddingTop: 4, paddingBottom: 2, paddingRight: 6 }}>
                    <span style={{ fontWeight: 'bold' }}>{xLabel} YoY%:</span> {formattedX}
                </div>
                <div style={{ paddingBottom: 8, paddingLeft: 6, paddingRight: 6 }}>
                    <span style={{ fontWeight: 'bold' }}>{yLabel}:</span> {formattedY}
                </div>
            </Paper>
        );
    };

    const annotations = data[0].data.map((point, index) => ({
        match: { index: index },
        noteX: 12,
        noteY: 5,
        offset: 3,
        noteTextOffset: 0,
        noteWidth: 0,
        note: point.ticker,
        type: hasIcon ? 'rect' : 'none',
        lineType: 'none',
        textStyle: {
            fontSize: 6,
            fontWeight: 'light',
            color: isDarkMode ? 'white' : 'black',
        }
    }));

    const themeGraph = {
        textColor: isDarkMode ? 'white' : 'black',
        grid: {
            line: {
                stroke: isDarkMode ? 'rgba(255, 255, 255, 0.2)' : 'rgba(0, 0, 0, 0.1)',
            },
        },
        axis: {
            legend: {
                text: {
                    fill: isDarkMode ? 'white' : 'black',
                    fontWeight: 'bold',
                },
            },
            ticks: {
                text: {
                    fill: isDarkMode ? 'white' : 'black',
                    fontWeight: 'bold',
                },
            },
        },
        markers: {
            lineColor: isDarkMode ? 'white' : 'black',
            textColor: isDarkMode ? 'white' : 'black',
        },
        tooltip: {
            container: {
                background: isDarkMode ? '#323232' : '#FFFFFF',
                color: isDarkMode ? 'white' : 'black',
            },
        },
        colors: {
            scheme: isDarkMode ? 'paired' : 'pastel1',
        },
        annotations: {
            
            text: {
                fill: isDarkMode ? 'white' : 'black',
                outlineColor: isDarkMode ? 'rgb(30,30,30)' : '#f2f2f2',
                fontWeight: '600',
                fontSize:'11px',
                outlineWidth: 2,
            },
            link: {
                strokeWidth: 0,
                outlineWidth: 0
            },
            outline: {
                outlineWidth: 0,
                strokeWidth: 0,
                stroke: isDarkMode ? 'white' : 'black',
            },
        }
    };

    const CustomNodeComponent = ({
        node,
        blendMode,
        onMouseEnter,
        onMouseMove,
        onMouseLeave,
        onClick,
    }) => {
        const { ticker } = node.data;
        const nodeSize = node.size;
        
        return (
            <g transform={`translate(${node.x},${node.y})`} onClick={() => onClick(node)}>
                {/* Background rectangle behind the logo */}
                <rect
                    x={-nodeSize / 2-6}
                    y={-nodeSize / 2-6}
                    width={nodeSize+12}
                    height={nodeSize+12}
                    stroke={theme.palette.divider} // Use theme's divider color for the border
                    strokeWidth={2} // 2px border width
                    fill={theme.background.backgroundColor} // Default background from the theme
                    rx={10} // Optional rounded corners
                />
                {/* The logo image */}
                <image
                    href={isDarkMode ? `/logos/${ticker}/icondark.png` : `/logos/${ticker}/icon.png`}
                    x={-nodeSize / 2}
                    y={-nodeSize / 2}
                    width={nodeSize}
                    height={nodeSize}
                    style={{ mixBlendMode: blendMode }}
                    onMouseEnter={event => onMouseEnter?.(node, event)}
                    onMouseMove={event => onMouseMove?.(node, event)}
                    onMouseLeave={event => onMouseLeave?.(node, event)}
                />
            </g>
        );
    };

    const handleClick = node => {
        const { ticker } = node.data;
        navigate(`/${ticker}`);
    };

    return (
        <ResponsiveScatterPlot
            data={data}
            margin={{ top: 20, right: 30, bottom: 65, left: 90 }}
            xScale={{ type: 'linear', min: 0, max: maxXRange }}
            xFormat=">-.1%"
            yScale={{ 
                type: 'linear', 
                min: 0, 
                max: maxYRange, 
                format: value => value.toFixed(1)
            }}
            gridXValues={xTickValues}
            gridYValues={yTickValues}
            yFormat=">-.1f"
            blendMode="normal"
            axisTop={null}
            axisRight={null}
            axisBottom={{
                orient: 'bottom',
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                format: value => `${(value * 100).toFixed(0)}%`,
                legend: `${xLabel} Growth`,
                legendPosition: 'middle',
                legendOffset: 46,
                truncateTickAt: 0,
                tickValues: xTickValues, // Use dynamic x tick values
            }}
            axisLeft={{
                orient: 'left',
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: yLabel,
                legendPosition: 'middle',
                legendOffset: -60,
                truncateTickAt: 0,
                tickValues: yTickValues, // Use dynamic y tick values
                format: value => value.toFixed(1) // Format ticks to one decimal place
            }}
            legends={[
                {
                    anchor: 'bottom-right',
                    direction: 'column',
                    justify: false,
                    translateX: 130,
                    translateY: 0,
                    itemWidth: 100,
                    itemHeight: 12,
                    itemsSpacing: 5,
                    itemDirection: 'left-to-right',
                    symbolSize: 12,
                    symbolShape: 'circle',
                    effects: [
                        {
                            on: 'hover',
                            style: {
                                itemOpacity: 1
                            }
                        }
                    ]
                }
            ]}
            layers={[
                'grid',
                'axes',
                'nodes',
                
                'annotations',
                'markers',
                'mesh',
                'legends',
            ]}
            theme={themeGraph}
            nodeComponent={hasIcon ? CustomNodeComponent : undefined}
            annotations={hasLabel ? annotations : undefined}
            colors={theme.palette.mode === 'dark' ? { scheme: 'paired' } : { scheme: 'pastel1' }}
            nodeSize={hasIcon ? 25 : 12}
            tooltip={CustomTooltip}
            onClick={hasIcon ? handleClick : handleClick}
        />
    );
};

export default MyResponsiveScatterPlot;
