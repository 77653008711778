import React from "react";
import { useState,useEffect } from "react";
import { useLocation } from 'react-router-dom';
import {useNavigate} from 'react-router-dom';
import {useSelector} from 'react-redux';
import { format } from 'date-fns';
import '../index.css'
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { useMediaQuery } from "@mui/material";
import { ResponsiveLine } from "@nivo/line";
import { useTheme } from '@mui/material/styles';
import {FaDatabase, FaIndustry, FaDesktop, FaBuilding} from 'react-icons/fa';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import CompareIcon from '@mui/icons-material/Compare';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import BalanceIcon from '@mui/icons-material/Balance';
import { IconButton,Checkbox, Select,Typography,MenuItem,Tooltip,Collapse,List,ListItemText,ToggleButton,ToggleButtonGroup,ListItemIcon } from "@mui/material";

const MyResponsiveLine = ({ data, theme }) => {
  
  
  const isDarkMode = theme.palette.mode === 'dark';

  const themeGraph = {
    textColor: isDarkMode ? 'white' : 'black',
    grid: {
      line: {
        stroke: isDarkMode ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 0, 0, 0.1)',
      },
    },
    axis: {
      legend: {
          text: {
              fill: isDarkMode ? 'white' : 'black',
              fontWeight: 'bold',
          },
      },
      ticks: {
          text: {
              fill: isDarkMode ? 'white' : 'black',
          },
      },
  },
    markers: {
      lineColor: isDarkMode ? 'white' : 'black',
      textColor: isDarkMode ? 'white' : 'black',
    },
    tooltip: {
      container: {
        background: isDarkMode ? '#323232' : '#FFFFFF',
        color: isDarkMode ? 'white' : 'black',
      },
    },
    legends: {
      text: {
        fill: isDarkMode ? 'white' : 'black', // Set legend label color to white in dark mode
      },
    },
    
  };
const formatValue = (value) => {
  if (value ==""){
    return value
  }
  if (value >= 1e4) {
    // Format as millions with three significant digits
    return `${(value / 1e3).toFixed(0)}k`;
  } else if (value >= 1e3) {
    // Format as thousands with three significant digits
    return `${(value / 1e3).toFixed(1)}k`;
  } 
  else {
    return `${(value).toFixed(0)}`;
  }
};

return (
  <ResponsiveLine
  data={data}
  theme={themeGraph}
  margin={{ top: 80, right: 0, bottom: 50, left: 75 }}
  colors={theme.palette.mode === 'dark' ? { scheme: 'dark2' } : { scheme: 'category10' }}
  xScale={{
    type: 'time',
    format: '%m-%d-%Y', // Format the dates as "m/d/y"
    useUTC: false,
    precision: 'day'
  }}
  
  yScale={{
      type: 'linear',
      min: 0,
      max: 'auto',
      stacked: false,
      reverse: false
  }}
  yFormat=" >-.2f"
  axisTop={null}
  axisRight={null}
  axisBottom={{
      tickSize: 5,
      tickPadding: 5,
      tickRotation: 0,
      format: (date, index) => {
        const totalDates = data.reduce((acc, item) => acc + item.data.length, 0);
        if (totalDates > 120) {
          return `${format(date, 'MMM yyyy')}`; // Show month and year if more than 50 dates
        } else {
          return `${format(date, 'MM/dd/yy')}`; // Show day, month, and year otherwise
        }
      },
      legend: 'Date',
      legendOffset: 36,
      legendPosition: 'middle',
      
  }}
  axisLeft={{
      tickSize: 5,
      tickPadding: 5,
      tickRotation: 0,
      legend: 'EV / Sales NTM',
      legendOffset: -60,
      legendPosition: 'middle',
      
  }}
  pointSize={10}
  pointColor={{ theme: 'background' }}
  pointBorderWidth={2}
  pointBorderColor={{ from: 'serieColor' }}
  pointLabelYOffset={-12}
  useMesh={true}
  enableGridX={false} // Hide horizontal gridlines
  enableGridY={true} // Hide vertical gridlines
  enablePoints={false} 
  enableArea={true}
  enableSlices="x"
  areaOpacity={0.05} // Set the opacity of the area
  areaBaselineValue={0}
  legends={[
    {
        anchor: 'top-right',
        direction: 'column',
        justify: false,
        translateX: -80,
        translateY: -82,
        itemsSpacing: 0,
        itemDirection: 'left-to-right',
        itemWidth: 95,
        itemHeight: 20,
        itemOpacity: 0.75,
        symbolSize: 12,
        symbolShape: 'circle',
        symbolBorderColor: 'rgba(0, 0, 0, .5)',
        effects: [
            {
                on: 'hover',
                style: {
                    itemBackground: 'rgba(0, 0, 0, .03)',
                    itemOpacity: 1
                }
            }
        ]
    }
]}
  
/>
)
      }
function Index(){
  const items = [
    { id: "Software", label: 'SoftwareIQ Composite Index', icon: <FaBuilding /> },
    { id: "Application Software", label: 'SoftwareIQ Application Index', icon: <FaDesktop /> },
    { id: "Infrastructure Software", label: 'SoftwareIQ Infrastructure Index', icon: <FaDatabase /> },
    { id: "Vertical Software", label: 'SoftwareIQ Vertical Index', icon: <FaIndustry /> }
    // Add more items as needed
  ];
  
  const theme = useTheme();
  const location = useLocation(); // If using React Router


  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);

    // If you want to scroll to the top every time the component updates, you can include an empty dependency array []
  }, []); // The empty dependency array ensures that this effect runs only once when the component mounts
  
  const navigate = useNavigate();
    const {user} = useSelector((state)=>state.auth);
    const [open, setOpen] = useState(true); // State to manage the open/closed state

  //  useEffect(()=>{
  //      if(!user){
  //          navigate('/login');
  //      }
  //  },[user, navigate]);
  const [expanded, setExpanded] = useState(false);
  const [submenuExpanded, setSubmenuExpanded] = useState(false);
  const [checked, setChecked] = useState(["Software","Application Software","Vertical Software","Infrastructure Software"]);
  const [chartData, setChartData] = useState(null);

  useEffect(() => {
    const loadChartData = async () => {
      try {
        // Dynamically import the JSON file based on the selected item ID
        const { default: data } = await import(`../data/charts/IndexEVToSales.json`);
        // Ensure data contains valid date formats
        const validatedData = data.map(item => ({
          ...item,
          data: item.data.map(dataItem => ({
            ...dataItem,
            x: new Date(dataItem.x) // Ensure x is a valid date object
          })).filter(dataItem => !isNaN(dataItem.x)) // Filter out invalid dates
        }));
        setChartData(validatedData);
      } catch (error) {
        console.error('Error loading chart data:', error);
      }
    };
  
    loadChartData();
  }, [checked]);
  const handleToggle = (event) => {
    const { value: selectedItems } = event.target;
    
    setChecked(prevCheckedCategory => {
        let newState = [...selectedItems];
        if (newState.length === 0)
        {
          return prevCheckedCategory
        }
        
        return newState
    });
  }

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const handleSubmenuExpandClick = () => {
    setSubmenuExpanded(!submenuExpanded);
  };

    const [alignment, setAlignment] = React.useState('oneYear');
    const [alignmentMethod, setAlignmentMethod] = React.useState('mktcap');
    const [alignment2, setAlignment2] = React.useState('date');
  
    const handleChange = (event, newAlignment) => {
      setAlignment(newAlignment);
    };
    const handleChange2 = (event, newAlignment) => {
      setAlignment2(newAlignment);
    };
    const handleChangeMethod = (event, newAlignment) => {
      setAlignmentMethod(newAlignment);
    };
    
    
    
    const shouldWrap = useMediaQuery('(max-width: 790px)');
    const handleToggleCollapse = () => {
      setOpen(!open);
    }; 

    const calculateStartDate = (lastDate, alignment) => {
      if (!lastDate || isNaN(lastDate)) {
        return new Date(); // Default to the current date if lastDate is invalid
      }
    
      const date = new Date(lastDate);
      const startOfYear = new Date(date.getFullYear(), 0, 1);
    
      switch (alignment) {
        case "oneMonth":
          date.setMonth(date.getMonth() - 1);
          break;
        case "threeMonths":
          date.setMonth(date.getMonth() - 3);
          break;
        case "oneYear":
          date.setFullYear(date.getFullYear() - 1);
          break;
        case "threeYears":
          date.setFullYear(date.getFullYear() - 3);
          break;
        case "fiveYears":
          date.setFullYear(date.getFullYear() - 5);
          break;
        case "ytd":
          return startOfYear;
        default:
          date.setFullYear(date.getFullYear() - 5);
      }
    
      return date;
    };
    
    
    const getLastDate = (chartData) => {
      if (!chartData || chartData.length === 0) {
        return new Date(); // Default to the current date if chartData is null or empty
      }
    
      const allDates = chartData
        .flatMap(item => item.data.map(dataItem => new Date(dataItem.x)))
        .filter(date => !isNaN(date)); // Ensure only valid dates are considered
    
      return new Date(Math.max(...allDates));
    };
    
    const lastDate = getLastDate(chartData);
    const startDate = calculateStartDate(lastDate, alignment);
    
    const filteredData = chartData
    ? chartData
      .filter(item => {
          if (alignmentMethod === 'equal') {
            return checked.map(id => id + " EW").includes(item.id);
          } 
          else if (alignmentMethod === 'both') {
            return checked.includes(item.id) || checked.map(id => id + " EW").includes(item.id);
          }
          else {
            return checked.includes(item.id);
          }
      })
      .map(item => ({
        id: item.id,
        data: item.data.filter(dataItem => new Date(dataItem.x) >= startDate)
      }))
    : [];

  return(
      <>
      
      <Box sx={{width:'1500px',maxWidth:'100%'}} >
    <Box sx={{ position: 'relative',marginTop:'6px', paddingLeft: '1%', marginLeft: '0px' }}>
    <Paper elevation={2} sx={{ backgroundColor: theme.background.backgroundColor,paddingBottom: '4px', maxWidth: '1500px', paddingTop: '0px', marginTop: '6px', marginBottom: '0px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        {!open && (
          <Box
            className='label'
            sx={{
              paddingTop: '6px',
              paddingBottom: '6px',
              paddingLeft: '1.5%',
              fontWeight: 'bold',
            }}
          >
            <Box sx={{ paddingTop: '2px', fontSize: '12px',minWidth:'200px' }}>
              Expand Dashboard Filters
            </Box>
          </Box>
        )}
        <Collapse in={open} sx={{ width: '95%' }}>
        <Box sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap',justifyContent:'center' }}>
        <Paper elevation={theme.palette.mode=='dark'?4:2} sx={{ backgroundColor: theme.background.backgroundColor,width: '400px', marginLeft: '1%', paddingTop: '8px', marginTop: '6px', paddingLeft: '1%', paddingRight: '2%', paddingBottom: '0px', display: 'flex', alignItems: 'center' }}>
          <Box sx={{ maxWidth: '65px', fontSize: '12px', fontWeight: 'bold', textAlign: 'center',  paddingBottom: '10px' }}>Dashboard Filters</Box>
          <Box sx={{ display: 'flex', justifyContent: 'flex-start', flex: 1, flexWrap: 'wrap' }}>
          
              <Box sx={{marginBottom: '0px', marginLeft: '2%', marginRight: '1%' }}>
        <List size='small' disablePadding sx={{ width:'300px', display: 'flex', flexDirection: 'row'}}>
                <Select size="small" 
                  sx={{backgroundColor:theme.tableHeader.highlight,width:'300px'}}
                  multiple
                  value={checked} // Use checkedCategory here
                  onChange={handleToggle} // Use handleCheckedChangeCategory here
                  renderValue={(selected) => {
                    
                    if (selected.length > 1) {
                      return <Typography variant="body2">Multiple Items Selected</Typography>;
                    } else if (selected.length === 1) {
                      return <Typography variant="body2">{items.find((type) => type.id === selected[0]).label}</Typography>;
                    } 
                  }}
                  MenuProps={{
                    PaperProps: {
                      style: {
                        maxHeight: 250,
                        width: 'auto',
                      },
                    },
                  }}
                >
                  {items.map((item) => (
                    <MenuItem size="small" key={item.id} value={item.id} sx={{ height:'40px'}}>
                      
                      <ListItemIcon size="small" >
                        {item.icon}
                      </ListItemIcon>
                      
                      <ListItemText primary={
                        <Typography variant="body2"> {/* Set variant="body2" for smaller text */}
                          {item.label}
                        </Typography>
                      } />
                       
                      <Checkbox  checked={checked.indexOf(item.id) !== -1} /> {/* Use checkedCategory here */}
                    </MenuItem>
                  ))}
                  
                </Select>
                </List>
                <Box sx={{paddingBottom:'4px', marginTop:'2px',fontSize: '11px', alignItems: 'center', textAlign: 'center' }}>Select Index</Box>
          </Box>
          
          </Box>
          
          </Paper>
          <Paper elevation={theme.palette.mode=='dark'?4:2} sx={{ backgroundColor: theme.background.backgroundColor,width: '280px', marginLeft: '1%', paddingTop: '8px', marginTop: '6px', paddingLeft: '1%', paddingRight: '2%', paddingBottom: '0px', display: 'flex', alignItems: 'center' }}>
          <Box sx={{ maxWidth: '45px', fontSize: '12px', fontWeight: 'bold', textAlign: 'center',  paddingBottom: '10px' }}>Time Frame</Box>
          <Box sx={{ display: 'flex', justifyContent: 'flex-start', flex: 1, flexWrap: 'wrap' }}>
              <Box sx={{  marginLeft: '2%', marginRight: '1%' }}>
              <ToggleButtonGroup
                sx={{backgroundColor:theme.tableHeader.highlight,
                  '& .Mui-selected': {
                    color: theme.toggleButton.selectedTextColor,
                    backgroundColor: theme.toggleButton.selectedBackgroundColor,
                  },
                }}
                value={alignment}
                exclusive
                onChange={handleChange}
                size="small"
                aria-label="Time Period Toggle"
              >
                
                <ToggleButton style={{ textTransform: 'none' }} value="oneMonth" sx={{ minWidth:'10x',py: 0.2, px: 0.8 }}>
                  <Tooltip title="One Month">
                    <IconButton color={alignment === "oneMonth" ? 'primary' : 'default'}  sx={{ py: 0, px: 0 }}>
                      <Box>
                      <Typography align="center" sx={{ mt: -.8, fontWeight: 'bold', fontSize: '14px',paddingTop:'11px',paddingBottom:'7px'  }}>1M</Typography>
                      </Box>
                    </IconButton>
                  </Tooltip>
                </ToggleButton>
                <ToggleButton style={{ textTransform: 'none' }} value="threeMonths" sx={{ minWidth:'10x',py: 0.2, px: 0.8 }}>
                  <Tooltip title="Three Months">
                    <IconButton color={alignment === "threeMonths" ? 'primary' : 'default'}  sx={{ py: 0, px: 0 }}>
                      <Box>
                      <Typography align="center" sx={{ mt: -.8, fontWeight: 'bold', fontSize: '14px',paddingTop:'11px',paddingBottom:'7px'  }}>3M</Typography>
                      </Box>
                    </IconButton>
                  </Tooltip>
                </ToggleButton>
                <ToggleButton style={{ textTransform: 'none' }} value="ytd" sx={{ minWidth:'30x',py: 0.2, px: 0.4 }}>
                  <Tooltip title="Year to Date">
                    <IconButton color={alignment === "ytd" ? 'primary' : 'default'}  sx={{ py: 0, px: 0 }}>
                      <Box>
                      <Typography align="center" sx={{ mt: -.8, fontWeight: 'bold', fontSize: '14px',paddingTop:'11px',paddingBottom:'7px'  }}>YTD</Typography>
                      </Box>
                    </IconButton>
                  </Tooltip>
                </ToggleButton>
                <ToggleButton style={{ textTransform: 'none' }} value="oneYear" sx={{ minWidth:'10x',py: 0.2, px: 0.8}}>
                  <Tooltip title="One Year">
                    <IconButton color={alignment === "oneYear" ? 'primary' : 'default'}  sx={{ py: 0, px: 0 }}>
                      <Box>
                      <Typography align="center" sx={{ mt: -.8, fontWeight: 'bold', fontSize: '14px',paddingTop:'11px',paddingBottom:'7px'  }}>1Y</Typography>
                      </Box>
                    </IconButton>
                  </Tooltip>
                </ToggleButton>
                <ToggleButton style={{ textTransform: 'none' }} value="threeYears" sx={{ minWidth:'10x',py: 0.2, px: 0.8 }}>
                  <Tooltip title="Three Years">
                    <IconButton color={alignment === "threeYears" ? 'primary' : 'default'}  sx={{ py: 0, px: 0 }}>
                      <Box>
                      <Typography align="center" sx={{ mt: -.8, fontWeight: 'bold', fontSize: '14px',paddingTop:'11px',paddingBottom:'7px'  }}>3Y</Typography>
                      </Box>
                    </IconButton>
                  </Tooltip>
                </ToggleButton>
                <ToggleButton style={{ textTransform: 'none' }} value="fiveYears" sx={{ minWidth:'10x',py: 0.2, px: 0.8 }}>
                  <Tooltip title="Five Years">
                    <IconButton color={alignment === "fiveYears" ? 'primary' : 'default'}  sx={{ py: 0, px: 0 }}>
                      <Box>
                      <Typography align="center" sx={{ mt: -.8, fontWeight: 'bold', fontSize: '14px',paddingTop:'11px',paddingBottom:'7px'  }}>5Y</Typography>
                      </Box>
                    </IconButton>
                  </Tooltip>
                </ToggleButton>
              </ToggleButtonGroup>
              <Box sx={{ paddingBottom:'4px',marginTop: '2px', fontSize: '11px', alignItems: 'center', textAlign: 'center'}}>Select Period</Box>
             </Box>
             </Box>
          </Paper>
          <Paper elevation={theme.palette.mode=='dark'?4:2} sx={{ backgroundColor: theme.background.backgroundColor,width: '280px', marginLeft: '1%', paddingTop: '8px', marginTop: '6px', paddingLeft: '1%', paddingRight: '2%', paddingBottom: '0px', display: 'flex', alignItems: 'center' }}>
          <Box sx={{ maxWidth: '65px', fontSize: '12px', fontWeight: 'bold', textAlign: 'center',  paddingBottom: '10px' }}>Calculation Method</Box>
          <Box sx={{ display: 'flex', justifyContent: 'flex-start', flex: 1, flexWrap: 'wrap' }}>
              <Box sx={{  marginLeft: '2%', marginRight: '1%' }}>
              <ToggleButtonGroup
                sx={{backgroundColor:theme.tableHeader.highlight,
                  '& .Mui-selected': {
                    color: theme.toggleButton.selectedTextColor,
                    backgroundColor: theme.toggleButton.selectedBackgroundColor,
                  },
                }}
                value={alignmentMethod}
                exclusive
                onChange={handleChangeMethod}
                size="small"
                aria-label="Time Period Toggle"
              >
                <ToggleButton style={{ textTransform: 'none' }} value="mktcap" sx={{ py: 0.2, px: 1.1 }}>
                  <Tooltip title="Market Cap Weighted">
                    <IconButton color={alignmentMethod === 'mktcap' ? 'primary' : 'default'}  sx={{ py: 0, px: 0 }}>
                      <Box>
                        <AccountBalanceIcon />
                        <Typography variant="body2" align="center" sx={{ mt: -.8, fontWeight: 'bold', fontSize: '10px' }}>Mkt Cap</Typography>
                      </Box>
                    </IconButton>
                  </Tooltip>
                </ToggleButton>
                <ToggleButton style={{ textTransform: 'none' }} value="equal" sx={{ py: 0.2, px: 1.8 }}>
                  <Tooltip title="Equal Weighted">
                    <IconButton color={alignmentMethod === 'equal' ? 'primary' : 'default'}  sx={{ py: 0, px: 0 }}>
                      <Box>
                        <BalanceIcon />
                        <Typography variant="body2" align="center" sx={{ mt: -1, fontWeight: 'bold', fontSize: '10px' }}>Equal</Typography>
                      </Box>
                    </IconButton>
                  </Tooltip>
                </ToggleButton>
                <ToggleButton style={{ textTransform: 'none' }} value="both" sx={{ py: 0.2, px: 0.9 }}>
                  <Tooltip title="Compare Both">
                    <IconButton color={alignmentMethod === 'both' ? 'primary' : 'default'}  sx={{ py: 0, px: 0 }}>
                      <Box>
                        <CompareIcon />
                        <Typography variant="body2" align="center" sx={{ mt: -1, fontWeight: 'bold', fontSize: '10px' }}>Compare</Typography>
                      </Box>
                    </IconButton>
                  </Tooltip>
                </ToggleButton>
              </ToggleButtonGroup>
              <Box sx={{ paddingBottom:'4px',marginTop: '2px', fontSize: '11px', alignItems: 'center', textAlign: 'center'}}>Select Methodolgy</Box>
             </Box>
             </Box>
          </Paper>
          </Box>
          <Box sx={{ marginTop: '6px' }}></Box>
        </Collapse>
        <IconButton
            sx={{
              p: 0.1,
              fontSize: 15,
              marginTop: '2px',
              marginRight: '2px',


            }}
            onClick={handleToggleCollapse}
            aria-label="toggle-collapse"
          >
            {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </IconButton>
        </Paper>
    </Box>
    
     <Paper elevation={2} sx={{marginTop:'6px',maxWidth: '1500px', paddingTop:'10px',paddingBottom: '40px',paddingRight: '1%', marginLeft: '1%',  paddingLeft: '1%',position: 'relative'}}>
    
     <Box sx={{ color: theme.palette.primary.main, fontSize: '30px', fontWeight: '600', paddingTop: '4px', paddingLeft: '1%' }}>
        Index Valuation: EV / Sales NTM
      </Box>
      
      <Box sx={{width: '98%',
        maxWidth: '1500px',
        marginLeft: '1%',
        height: open?'59vh':'69vh',
        paddingRight:'1%',
        position: 'relative'}}>
        <MyResponsiveLine data={filteredData} theme={theme}  />
        <img
              src={require('../data/images/logo.png')}
              alt="Website Logo"
              style={{
                position: 'absolute',
                bottom: 60, // Adjust the distance from the bottom
                right: 15, // Adjust the distance from the right
                width: '130px', // Set the desired width for the postage stamp
                height: 'auto', // Maintain aspect ratio
                opacity: 0.1, // Set the desired opacity for the watermark
              }}
            />
            </Box>
      </Paper>
      
      
  
</Box>
      </>
    )
}
export default Index