import React from "react";
import kbmData from '../data/charts/growthmetrics.json';
import { useLocation } from 'react-router-dom';
import {FaStore,FaDatabase, FaIndustry, FaDesktop, FaBuilding,FaCoins,FaFileInvoice,FaComments,FaCode,FaServer,FaShieldAlt, FaUsers,FaHammer,FaNetworkWired} from 'react-icons/fa';
import { useState,useEffect } from "react";
import '../index.css'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { Select,MenuItem,ListItemText,ListItemIcon,useMediaQuery,Typography,Collapse,IconButton,Paper,ToggleButtonGroup,ToggleButton,Tooltip,List,Checkbox,Box } from "@mui/material";
import { useTheme } from '@mui/material/styles';
import DashboardChart from './ChartDashboard.jsx';


function Dashboard(){
  const theme = useTheme();
  const location = useLocation(); // If using React Router
  const [checkedCategory, setCheckedCategory] = React.useState([2,20,21,22,23,24,25]);
  const [expandedApp, setExpandedApp] = useState(false); 
  const [expandedInfra, setExpandedInfra] = useState(false); 
  const [expandedVert, setExpandedVert] = useState(false); 
  const [metric,setMetric] = React.useState(1)
  const [alignment, setAlignment] = React.useState('currQtr');
  const metricList =[
    {"id":1,"format":"percent","dbField":"revenue","field":"Revenue"},
    {"id":2,"format":"percent","dbField":"grossMargin","field":"Gross Margin"},
    {"id":3,"format":"percent","dbField":"currentCalculatedBillings","field":"Calculated Billings"},
    {"id":4,"format":"percent","dbField":"currentRpo","field":"Current RPO"},
    {"id":5,"format":"percent","dbField":"rpo","field":"RPO"},
    {"id":6,"format":"percent","dbField":"arr","field":"ARR"},
    {"id":7,"format":"percent","dbField":"cloud","field":"Cloud Revenue"},
    {"id":8,"format":"percent","dbField":"customersGt100k","field":"Customers >$100k"},
    {"id":9,"format":"percent","dbField":"customersGt1M","field":"Customers >$1M"},
  ]
  const category = [
    {
      id: 1,
      subIndustry:'Application',
      filter: 'Application',
      label: 'Application Software',
      icon: <FaDesktop />,
    },
    {
      id: 10,
      subIndustry:'Application',
      filter: 'CRM, Marketing, and Support',
      label: 'CRM, Marketing & Support',
      icon: <FaStore />,
    },
    {
      id: 11,
      subIndustry:'Application',
      filter: 'HR, Finance & Supply Chain',
      label: 'HR, Finance & SCM',
      icon: <FaUsers />,
    },
    {
      id: 12,
      subIndustry:'Application',
      filter: 'Productivity & Content Creation',
      label: 'Productivity',
      icon: <FaFileInvoice />,
    },
    {
      id: 2,
      subIndustry:'Infrastructure',
      filter: 'Infrastructure',
      label: 'Infrastructure Software',
      icon: <FaDatabase />,
    },
    
    {
      id: 20,
      subIndustry:'Infrastructure',
      filter: 'Database & Data Platforms',
      label: 'Database & Data Platforms',
      icon: <FaDatabase />,
    },
    {
      id: 21,
      subIndustry:'Infrastructure',
      filter: 'Dev Tools & Low Code',
      label: 'Dev Tools & Low Code',
      icon: <FaCode />,
    },
    {
      id: 22,
      subIndustry:'Infrastructure',
      filter: 'IT Operations',
      label: 'IT Operations',
      icon: <FaServer />,
    },
    {
      id: 23,
      subIndustry:'Infrastructure',
      filter: 'Security & Endpoint Management',
      label: 'Security & UEM',
      icon: <FaShieldAlt />,
    },
    {
      id: 24,
      subIndustry:'Infrastructure',
      filter: 'Networking & CDN',
      label: 'Networking & CDN',
      icon: <FaNetworkWired />,
    },
    {
      id: 25,
      subIndustry:'Infrastructure',
      filter: 'CPaaS',
      label: 'CPaaS',
      icon: <FaComments />,
    },
    {
      id: 3,
      subIndustry:'Vertical',
      filter: 'Vertical',
      label: 'Vertical Software',
      icon: <FaIndustry />,
    },
    {
      id: 30,
      subIndustry:'Vertical',
      filter: 'Financials',
      label: 'Financials',
      icon: <FaCoins />,
    },
    {
      id: 31,
      subIndustry:'Vertical',
      filter: 'Engineering & Industrials',
      label: 'Engineering & Industrials',
      icon: <FaHammer />,
    },
    {
      id: 32,
      subIndustry:'Vertical',
      filter: 'Other Vertical',
      label: 'Other Vertical',
      icon: <FaIndustry />,
    },
    {
      id: 4,
      subIndustry:'Diversified',
      filter: 'Diversified',
      label: 'Diversified Software',
      icon: <FaBuilding />,
    },
  ];
  const handleMetricChange = (event) => {
    setMetric(event.target.value);
};  
const handleCheckedChangeCategory = (event) => {
  const { value: selectedItems } = event.target;
  
  setCheckedCategory(prevCheckedCategory => {
      // Check if '1' has been newly checked
      const oneChecked = selectedItems.includes(1) && !prevCheckedCategory.includes(1);
      const oneUnchecked = !selectedItems.includes(1) && prevCheckedCategory.includes(1);
      const twoChecked = selectedItems.includes(2) && !prevCheckedCategory.includes(2);
      const twoUnchecked = !selectedItems.includes(2) && prevCheckedCategory.includes(2);
      const threeChecked = selectedItems.includes(3) && !prevCheckedCategory.includes(3);
      const threeUnchecked = !selectedItems.includes(3) && prevCheckedCategory.includes(3);

      // Initialize the new state with the selected items
      let newState = [...selectedItems];

      if (oneChecked) {
          newState.push(10,11,12); 
      }
      if (oneUnchecked) {
        newState = newState.filter(item => ![10, 11, 12].includes(item));
      }
      if (twoChecked) {
        newState.push(20,21,22,23,24,25); 
      }
      if (twoUnchecked) {
        newState = newState.filter(item => ![20,21,22,23,24,25].includes(item));
      }
      if (threeChecked) {
        newState.push(30,31,32); 
      }
      if (threeUnchecked) {
        newState = newState.filter(item => ![30,31,32].includes(item));
      }
      if (!oneChecked && !oneUnchecked && newState.includes(10) && newState.includes(11) && newState.includes(12)&&!newState.includes(1)) {
        newState.push(1);
      }
      if (!oneChecked && !oneUnchecked && !newState.includes(10) && !newState.includes(11) && !newState.includes(12)&&newState.includes(1)) {
        newState = newState.filter(item => ![1].includes(item));
      }
      if (!twoChecked && !twoUnchecked && newState.includes(20) && newState.includes(21) && newState.includes(22) && newState.includes(23) && newState.includes(24) && newState.includes(25)&&!newState.includes(2)) {
        newState.push(2);
      }
      if (!twoChecked && !twoUnchecked && !newState.includes(20) && !newState.includes(21) && !newState.includes(22)&& !newState.includes(23)&& !newState.includes(24)&& !newState.includes(25)&&newState.includes(2)) {
        newState = newState.filter(item => ![2].includes(item));
      }
      if (!threeChecked && !threeUnchecked && newState.includes(30) && newState.includes(31) && newState.includes(32)&&!newState.includes(3)) {
        newState.push(3);
      }
      if (!threeChecked && !threeUnchecked && !newState.includes(30) && !newState.includes(31) && !newState.includes(32)&&newState.includes(3)) {
        newState = newState.filter(item => ![3].includes(item));
      }
      return newState;
  });
}
const handleChange = (event, newAlignment) => {
  // Check if the new alignment is different from the current alignment
  if (newAlignment !== null) {
    setAlignment(newAlignment);
  }
};
  const handleExpandAppToggle = (e) => {
    e.stopPropagation(); // Stop event propagation to prevent filtering
    setExpandedApp(!expandedApp); // Toggle the expansion state
  };
  const handleExpandInfraToggle = (e) => {
    e.stopPropagation(); // Stop event propagation to prevent filtering
    setExpandedInfra(!expandedInfra); // Toggle the expansion state
  };
  const handleExpandVertToggle = (e) => {
    e.stopPropagation(); // Stop event propagation to prevent filtering
    setExpandedVert(!expandedVert); // Toggle the expansion state
  };

    useEffect(() => {
        // Scroll to the top of the page when the component mounts
        window.scrollTo(0, 0);
    
        // If you want to scroll to the top every time the component updates, you can include an empty dependency array []
      }, []); // The empty dependency array ensures that this effect runs only once when the component mounts
    
    const [open, setOpen] = useState(true); // State to manage the open/closed state


    
    // Filter data by metric first
      const filteredDateData = kbmData.find((date) => date.date === alignment);
      const val = filteredDateData.val
      const metricFilteredData = val.find((metricData) => metricData.metric === metricList.find(item => item.id === metric)?.dbField);

      const values = metricFilteredData.values;
      const sortedValues = values.sort((a,b)=>b["Value"] - a["Value"]);
      // Then filter the values array by category
      const filteredData = sortedValues.filter((company) => {
              // Check if the category exists and matches any of the checked categories
          return checkedCategory.some((item) => company.Category === category.find((i) => i.id === item)?.filter);
        });
      
        const shouldWrap = useMediaQuery('(max-width: 800px)');
        const handleToggleCollapse = () => {
            setOpen(!open);
          };
          const chartMaxWidth = Math.max((filteredData.length * 60),1100) ;
          const getFormatForMetric = (metricId) => {
            const metricItem = metricList.find(item => item.id === metricId);
            return metricItem ? metricItem.format : null;
        };
        const format = getFormatForMetric(metric);
    //data = data.sort((a, b) => b["Net Revenue Retention"] - a["Net Revenue Retention"]);  
    const filterMaxWidth = Math.max(chartMaxWidth, 850);
    console.log(filteredData)
  return(
    <>
    
    <Box sx={{width:chartMaxWidth,maxWidth:'100%'}} >
    <Box sx={{ position: 'relative' ,paddingTop:'0px'}}>
    <Paper elevation={2} sx={{ backgroundColor: theme.background.backgroundColor,marginLeft:'1%',paddingBottom: '4px', maxWidth: chartMaxWidth, paddingTop: '0px', marginTop: '6px', marginBottom: '0px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        {!open && (
          <Box
            className='label'
            sx={{
              paddingTop: '6px',
              paddingBottom: '6px',
              paddingLeft: '1.5%',
              fontWeight: 'bold',
            }}
          >
            <Box sx={{ paddingTop: '2px', fontSize: '12px',minWidth:'200px' }}>
              Expand Dashboard Filters
            </Box>
          </Box>
        )}
        <Collapse in={open} sx={{ width: '95%' }}>
        <Box sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' ,justifyContent:'center'}}>
        <Paper elevation={theme.palette.mode=='dark'?4:2} sx={{ backgroundColor: theme.background.backgroundColor,width: '680px', marginLeft: '1%', paddingTop: '8px', marginTop: '6px', paddingLeft: '1%', paddingRight: '2%', paddingBottom: '0px', display: 'flex', alignItems: 'center' }}>
          <Box sx={{ maxWidth: '65px', fontSize: '12px', fontWeight: 'bold', textAlign: 'center',  paddingBottom: '10px' }}>Dashboard Filters</Box>
          <Box sx={{ display: 'flex', justifyContent: 'flex-start', flex: 1, flexWrap: 'wrap' }}>
          
              <Box sx={{marginBottom: '0px', marginLeft: '2%', marginRight: '1%' }}>
                <List size='small' disablePadding sx={{ width:'300px', display: 'flex', flexDirection: 'row'}}>
                <Select size="small" 
                  sx={{backgroundColor:theme.tableHeader.highlight,width:'300px'}}
                  multiple
                  value={checkedCategory} // Use checkedCategory here
                  onChange={handleCheckedChangeCategory} // Use handleCheckedChangeCategory here
                  renderValue={(selected) => {
                    if (selected.length === 0) {
                      return "No Items Selected";
                    }
                    else if (selected.includes(10) && selected.includes(11) && selected.includes(12)&&selected.includes(20) && selected.includes(21) && selected.includes(22)&& selected.includes(23)&& selected.includes(24)&& selected.includes(25)&&selected.includes(30) && selected.includes(31) && selected.includes(32)&& selected.includes(4)) {
                      return <Typography variant="body2">All Categories: No Filters Applied</Typography>;
                    }
                    else if (selected.includes(10) && selected.includes(11) && selected.includes(12)&&!selected.includes(2)&&!selected.includes(3)&&!selected.includes(4)&&!selected.includes(20)&&!selected.includes(21)&&!selected.includes(22)&&!selected.includes(23)&&!selected.includes(24)&&!selected.includes(25)&&!selected.includes(30)&&!selected.includes(31)&&!selected.includes(32)){
                      return <Typography variant="body2">Application Software</Typography>;
                    }
                    else if (selected.includes(20) && selected.includes(21) && selected.includes(22)&& selected.includes(23)&& selected.includes(24)&& selected.includes(25)&&!selected.includes(1)&&!selected.includes(3)&&!selected.includes(4)&&!selected.includes(10)&&!selected.includes(11)&&!selected.includes(12)&&!selected.includes(30)&&!selected.includes(31)&&!selected.includes(32)){
                      return <Typography variant="body2">Infrastructure Software</Typography>;
                    }
                    else if (selected.includes(30) && selected.includes(31) && selected.includes(32)&&!selected.includes(2)&&!selected.includes(1)&&!selected.includes(4)&&!selected.includes(20)&&!selected.includes(21)&&!selected.includes(22)&&!selected.includes(23)&&!selected.includes(24)&&!selected.includes(25)&&!selected.includes(10)&&!selected.includes(11)&&!selected.includes(12)){
                      return <Typography variant="body2">Vertical Software</Typography>;
                    }
                    else if (selected.length === 1) {
                      const selectedItem = category.find(item => item.id === selected[0]); // Use category here
                      return <Typography variant="body2">{selectedItem.label}</Typography>;
                    } else {
                      return <Typography variant="body2">(multiple items selected)</Typography>;
                    }
                  }}
                  MenuProps={{
                    PaperProps: {
                      style: {
                        maxHeight: 250,
                        width: 'auto',
                      },
                    },
                  }}
                >
                  {category.slice(0,1).map((item) => (
                    <MenuItem size="small" key={item.id} value={item.id} sx={{ height:'40px'}}>
                      
                      <ListItemIcon size="small" >
                        {item.icon}
                      </ListItemIcon>
                      
                      <ListItemText primary={
                        <Typography variant="body2"> {/* Set variant="body2" for smaller text */}
                          {item.label}
                        </Typography>
                      } />
                      <IconButton size="small" onClick={handleExpandAppToggle}>  
                      {expandedApp ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                      </IconButton> 
                      <Checkbox  indeterminate={(checkedCategory.indexOf(1)!== -1 && 
                        (checkedCategory.indexOf(10)=== -1||checkedCategory.indexOf(11)=== -1||checkedCategory.indexOf(12)=== -1))
                        ||(checkedCategory.indexOf(1)=== -1 && 
                        (checkedCategory.indexOf(10)!== -1||checkedCategory.indexOf(11)!== -1||checkedCategory.indexOf(12)!== -1))} 
                        size="small" checked={checkedCategory.indexOf(item.id) !== -1} /> {/* Use checkedCategory here */}
                    </MenuItem>
                  ))}
                  {expandedApp && category.slice(1,4).map((item) => (
                    <MenuItem size="small" key={item.id} value={item.id} sx={{height:'26px'}}>
                    <Box sx={{width:'18px'}}></Box> 
                    <ListItemIcon  size="small" >
                      {item.icon}
                    </ListItemIcon>
                    
                    <ListItemText  primary={
                      <Typography variant="body2"> {/* Set variant="body2" for smaller text */}
                        {item.label}
                      </Typography>
                    } />
                    <Checkbox size="small" checked={checkedCategory.indexOf(item.id) !== -1} /> {/* Use checkedCategory here */}
                  </MenuItem>                 
                  ))}
                  {category.slice(4,5).map((item) => (
                    <MenuItem size="small" key={item.id} value={item.id} sx={{ height:'40px'}}>
                      
                    <ListItemIcon size="small" >
                      {item.icon}
                    </ListItemIcon>
                    <ListItemText primary={
                      <Typography variant="body2"> {/* Set variant="body2" for smaller text */}
                        {item.label}
                      </Typography>
                    } />
                    <IconButton size="small" onClick={handleExpandInfraToggle}>  
                      {expandedInfra ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                      </IconButton> 
                    <Checkbox indeterminate={(checkedCategory.indexOf(2)!== -1 && 
                        (checkedCategory.indexOf(20)=== -1||checkedCategory.indexOf(21)=== -1||checkedCategory.indexOf(22)=== -1||checkedCategory.indexOf(23)=== -1||checkedCategory.indexOf(24)=== -1||checkedCategory.indexOf(25)=== -1))
                        ||(checkedCategory.indexOf(2)=== -1 && 
                        (checkedCategory.indexOf(20)!== -1||checkedCategory.indexOf(21)!== -1||checkedCategory.indexOf(22)!== -1||checkedCategory.indexOf(23)!== -1||checkedCategory.indexOf(24)!== -1||checkedCategory.indexOf(25)!== -1))} 
                        size="small" checked={checkedCategory.indexOf(item.id) !== -1} /> {/* Use checkedCategory here */}
                  </MenuItem>
                  ))}
                  {expandedInfra && category.slice(5,11).map((item) => (
                    <MenuItem size="small" key={item.id} value={item.id} sx={{ height:'26px'}}>
                    <Box sx={{width:'18px'}}></Box> 
                    <ListItemIcon size="small" >
                      {item.icon}
                    </ListItemIcon>
                    <ListItemText primary={
                      <Typography variant="body2"> {/* Set variant="body2" for smaller text */}
                        {item.label}
                      </Typography>
                    } />
                    <Checkbox size="small" checked={checkedCategory.indexOf(item.id) !== -1} /> {/* Use checkedCategory here */}
                  </MenuItem>
                  ))}
                  {category.slice(11,12).map((item) => (
                    <MenuItem size="small" key={item.id} value={item.id} sx={{ height:'40px'}}>
                      
                    <ListItemIcon size="small" >
                      {item.icon}
                    </ListItemIcon>
                    <ListItemText primary={
                      <Typography variant="body2"> {/* Set variant="body2" for smaller text */}
                        {item.label}
                      </Typography>
                    } />
                    <IconButton size="small" onClick={handleExpandVertToggle}>  
                      {expandedVert ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                      </IconButton> 
                    <Checkbox indeterminate={(checkedCategory.indexOf(3)!== -1 && 
                        (checkedCategory.indexOf(30)=== -1||checkedCategory.indexOf(31)=== -1||checkedCategory.indexOf(32)=== -1))
                        ||(checkedCategory.indexOf(3)=== -1 && 
                        (checkedCategory.indexOf(30)!== -1||checkedCategory.indexOf(31)!== -1||checkedCategory.indexOf(32)!== -1))} 
                        size="small" checked={checkedCategory.indexOf(item.id) !== -1} /> {/* Use checkedCategory here */}
                  </MenuItem>
                  ))}
                  {expandedVert && category.slice(12,15).map((item) => (
                    <MenuItem size="small" key={item.id} value={item.id} sx={{ height:'26px'}}>
                      <Box sx={{width:'18px'}}></Box> 
                    <ListItemIcon size="small" >
                      {item.icon}
                    </ListItemIcon>
                    <ListItemText primary={
                      <Typography variant="body2"> {/* Set variant="body2" for smaller text */}
                        {item.label}
                      </Typography>
                    } />
                    <Checkbox size="small" checked={checkedCategory.indexOf(item.id) !== -1} /> {/* Use checkedCategory here */}
                  </MenuItem>
                  ))}
                  {category.slice(15,16).map((item) => (
                    <MenuItem size="small" key={item.id} value={item.id} sx={{ height:'40px'}}>
                      
                    <ListItemIcon size="small" >
                      {item.icon}
                    </ListItemIcon>
                    <ListItemText primary={
                      <Typography variant="body2"> {/* Set variant="body2" for smaller text */}
                        {item.label}
                      </Typography>
                    } />
                    <Checkbox size="small" checked={checkedCategory.indexOf(item.id) !== -1} /> {/* Use checkedCategory here */}
                  </MenuItem>
                  ))}
                </Select>
                </List>
                <Box sx={{  paddingBottom:'4px',marginTop:'2px',fontSize: '11px', alignItems: 'center', textAlign: 'center' }}>Filter by Software Category</Box>
              </Box>
              <Box sx={{marginBottom: '0px', marginLeft: '2%', marginRight: '1%' }}>
        <Select
                size="small"
                value={metric}
                onChange={handleMetricChange}
                sx={{backgroundColor:theme.tableHeader.highlight,width:'220px'}}
            >
                {metricList.map((item) => (
                    <MenuItem size="small" key={item.id} value={item.id}>
                        {item.field}
                    </MenuItem>
                ))}
            </Select>
            <Box sx={{ paddingBottom:'4px', marginTop:'2px',fontSize: '11px', alignItems: 'center', textAlign: 'center' }}>Select Metric</Box>
            </Box>
        </Box>
        
        </Paper>

        <Paper elevation={theme.palette.mode=='dark'?4:2} sx={{backgroundColor: theme.background.backgroundColor, width: '330px', marginLeft: '1%', paddingTop: '8px', marginTop: '6px', paddingLeft: '1%', paddingRight: '2%', paddingBottom: '0px', display: 'flex', alignItems: 'center' }}>
          <Box sx={{ maxWidth: '45px', fontSize: '12px', fontWeight: 'bold', textAlign: 'center',  paddingBottom: '10px' }}>Time Frame</Box>
          <Box sx={{ display: 'flex', justifyContent: 'flex-start', flex: 1, flexWrap: 'wrap' }}>
          
              <Box sx={{ marginBottom: '0px', marginLeft: '2%', marginRight: '1%' }}>
        <ToggleButtonGroup
                sx={{backgroundColor:theme.tableHeader.highlight,
                  '& .Mui-selected': {
                    color: theme.toggleButton.selectedTextColor,
                    backgroundColor: theme.toggleButton.selectedBackgroundColor,
                  },
                }}
                value={alignment}
                exclusive
                onChange={handleChange}
                size="small"
                aria-label="Time Period Toggle"
              >
                <ToggleButton style={{ textTransform: 'none' }} value="prev2Yr" sx={{ minWidth:'58px',py: 0.2, px: 1.1 }}>
                  <Tooltip title="2 Years Prior Quarter">
                    <IconButton color={alignment === "prev2Yr" ? 'primary' : 'default'}  sx={{ py: 0, px: 0 }}>
                      <Box>
                      <Typography align="center" sx={{ mt: -.8, fontWeight: 'bold', fontSize: '16px',paddingTop:'5px',paddingBottom:'8px' }}>Yr-2</Typography>
                        <Typography variant="body2" align="center" sx={{ mt: -1, fontWeight: 'bold', fontSize: '8px' }}>Prev 2 Yr</Typography>
                      </Box>
                    </IconButton>
                  </Tooltip>
                </ToggleButton>
                <ToggleButton style={{ textTransform: 'none' }} value="prevYr" sx={{ minWidth:'58px',py: 0.2, px: 1.1 }}>
                  <Tooltip title="Year Prior Quarter">
                    <IconButton color={alignment === "prevYr" ? 'primary' : 'default'}  sx={{ py: 0, px: 0 }}>
                      <Box>
                      <Typography align="center" sx={{ mt: -.8, fontWeight: 'bold', fontSize: '16px',paddingTop:'5px',paddingBottom:'8px'  }}>Yr-1</Typography>
                        <Typography variant="body2" align="center" sx={{ mt: -1, fontWeight: 'bold', fontSize: '8px' }}>Prev Yr</Typography>
                      </Box>
                    </IconButton>
                  </Tooltip>
                </ToggleButton>
                <ToggleButton style={{ textTransform: 'none' }} value="prevQtr" sx={{ minWidth:'58px',py: 0.2, px: 1.1 }}>
                  <Tooltip title="Prior Quarter">
                    <IconButton color={alignment === "prevQtr" ? 'primary' : 'default'}  sx={{ py: 0, px: 0 }}>
                      <Box>
                      <Typography align="center" sx={{ mt: -.8, fontWeight: 'bold', fontSize: '16px',paddingTop:'5px',paddingBottom:'8px'  }}>Q4</Typography>
                        <Typography variant="body2" align="center" sx={{ mt: -1, fontWeight: 'bold', fontSize: '8px' }}>Prev Qtr</Typography>
                      </Box>
                    </IconButton>
                  </Tooltip>
                </ToggleButton>
                
                <ToggleButton style={{ textTransform: 'none' }} value="currQtr" sx={{ minWidth:'58px',py: 0.2, px: 1.1 }}>
                  <Tooltip title="Last Reported Quarter">
                    <IconButton color={alignment === "currQtr" ? 'primary' : 'default'}  sx={{ py: 0, px: 0 }}>
                      <Box>
                      <Typography align="center" sx={{ mt: -.8, fontWeight: 'bold', fontSize: '16px',paddingTop:'5px',paddingBottom:'8px'  }}>Q1</Typography>
                      <Typography variant="body2" align="center" sx={{ mt: -.8, fontWeight: 'bold', fontSize: '8px' }}>Curr Qtr</Typography>
                      </Box>
                    </IconButton>
                  </Tooltip>
                </ToggleButton>
                
              </ToggleButtonGroup>
            <Box sx={{ paddingBottom:'4px',marginTop:'2px',fontSize: '11px', alignItems: 'center', textAlign: 'center' }}>Select Period</Box>
            </Box>
            </Box>
            </Paper>
            </Box>
        
    
        
            <Box sx={{ marginTop: '6px' }}></Box>
        </Collapse>
        <IconButton
            sx={{
              p: 0.1,
              fontSize: 15,
              marginTop: '2px',
              marginRight: '2px',


            }}
            onClick={handleToggleCollapse}
            aria-label="toggle-collapse"
          >
            {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </IconButton>
        </Paper>
    </Box>
    
    <Paper sx={{
      minWidth:'400px',
      maxWidth: filterMaxWidth,
      marginTop:'6px',
      paddinTop:'5px',
      marginLeft: '1%',
      paddingRight:'1%',
      position: 'relative'}} elevation={3}>
      <Box sx={{color: theme.palette.primary.main,fontSize:'30px', fontWeight:'600',paddingTop:'4px',paddingLeft:'2%'}}>{metricList.find(item => item.id === metric)?.field} Growth</Box>
    
      <Box sx={{width: '98%',
        maxWidth: chartMaxWidth,
        marginLeft: '1%',
        height: open?'59vh':'69vh',
        paddingRight:'1%',
        position: 'relative'}}>
        <DashboardChart data={filteredData} theme={theme} format={format} keys={["Value"]} />
        <img
              src={require('../data/images/logo.png')}
              alt="Website Logo"
              style={{
                position: 'absolute',
                top: 15, // Adjust the distance from the bottom
                right: 10, // Adjust the distance from the right
                width: '130px', // Set the desired width for the postage stamp
                height: 'auto', // Maintain aspect ratio
                opacity: 0.1, // Set the desired opacity for the watermark
              }}
            />
            </Box>
    </Paper>
    
    </Box>
    </>
    )
}
export default Dashboard