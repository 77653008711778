import React, { useState, useEffect } from 'react';
import '../index.css';
import { useLocation } from 'react-router-dom';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import {useMediaQuery,Paper,Table,TableBody,TableCell,TableContainer,TableHead,TableRow, Box } from '@mui/material';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { useTheme } from '@emotion/react';
import { format, parseISO } from 'date-fns';

import { ResponsiveLine } from "@nivo/line";


const MyResponsiveLine = ({ data, theme }) => {
  
  
  const isDarkMode = theme.palette.mode === 'dark';

  const themeGraph = {
    textColor: isDarkMode ? 'white' : 'black',
    grid: {
      line: {
        stroke: isDarkMode ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 0, 0, 0.1)',
      },
    },
    axis: {
      legend: {
          text: {
              fill: isDarkMode ? 'white' : 'black',
              fontWeight: 'bold',
          },
      },
      ticks: {
          text: {
              fill: isDarkMode ? 'white' : 'black',
          },
      },
  },
    markers: {
      lineColor: isDarkMode ? 'white' : 'black',
      textColor: isDarkMode ? 'white' : 'black',
    },
    tooltip: {
      container: {
        background: isDarkMode ? '#323232' : '#FFFFFF',
        color: isDarkMode ? 'white' : 'black',
      },
    },
    legends: {
      text: {
        fill: isDarkMode ? 'white' : 'black', // Set legend label color to white in dark mode
      },
    },
    
  };
  const formatValue = (value) => {
    if (value ===""){
      return value
    }
    const absValue = Math.abs(value);
    
    if (absValue >= 1e11) {
      // Format as billions with three significant digits
      return `${(value / 1e9).toFixed(0)}B`;
    }else if (absValue >= 1e9) {
      // Format as billions with three significant digits
      return `${(value / 1e9).toFixed(1)}B`;
    } else if (absValue >= 1e7) {
        // Format as billions with three significant digits
        return `${(value / 1e6).toFixed(0)}M`;
    } else if (absValue >= 1e6) {
      // Format as millions with three significant digits
      return `${(value / 1e6).toFixed(1)}M`;
    } else if (absValue >= 1e3) {
      // Format as thousands with three significant digits
      return `${(value / 1e3).toFixed(1)}K`;
    } else if (absValue >= 3) {
      // Format as is with three significant digits
      return value.toFixed(2);
    }
    else if (absValue === 0) {
      return value.toFixed(0);
    }
    else if (absValue >= 1){
      
      return `${(value * 100).toFixed(0)}%`;
    }
    else {
      return `${(value * 100).toFixed(1)}%`;
    }
  };
  

return (
  <ResponsiveLine
  data={data}
  theme={themeGraph}
  margin={{ top: 38, right: 50, bottom: 50, left: 75 }}
  colors={theme.palette.mode === 'dark' ? { scheme: 'dark2' } : { scheme: 'category10' }}
  xScale={{
    type: 'time',
    format: '%Y-%m-%d',
    useUTC: false,
    precision: 'day',
}}
  
  yScale={{
      type: 'linear',
      min: 0,
      max: 'auto',
      stacked: false,
      reverse: false
  }}
  yFormat={(value) => formatValue(value)}
  axisTop={null}
  axisRight={null}
  axisBottom={{
    tickSize: 5,
    tickPadding: 5,
    tickRotation: -45,  
    format: '%b-%y',
    tickValues: 'every 2 month',
    legendOffset: -12,
}}
  axisLeft={{
      tickSize: 5,
      tickPadding: 5,
      tickRotation: 0,
      legend: 'ARR',
      legendOffset: -60,
      legendPosition: 'middle',
      format: (value) => formatValue(value),
      
  }}
  pointSize={10}
  pointColor={{ theme: 'background' }}
  curve="cardinal"
  pointBorderWidth={2}
  pointBorderColor={{ from: 'serieColor' }}
  pointLabelYOffset={-12}
  useMesh={true}
  enableGridX={false} // Hide horizontal gridlines
  enableGridY={true} // Hide vertical gridlines
  
  enablePoints={true} 
  enableArea={true}
  enableSlices="x"
  areaOpacity={0.05} // Set the opacity of the area
  
  legends={[
    {
        anchor: 'top-right',
        direction: 'column',
        justify: false,
        translateX: -80,
        translateY: -40,
        itemsSpacing: 0,
        itemDirection: 'left-to-right',
        itemWidth: 95,
        itemHeight: 20,
        itemOpacity: 0.75,
        symbolSize: 12,
        symbolShape: 'circle',
        symbolBorderColor: 'rgba(0, 0, 0, .5)',
        effects: [
            {
                on: 'hover',
                style: {
                    itemBackground: 'rgba(0, 0, 0, .03)',
                    itemOpacity: 1
                }
            }
        ]
    }
]}
sliceTooltip={({ slice }) => {
  return (
    <Box
      sx={{
        background: isDarkMode ? '#323232' : '#FFFFFF',
        color: isDarkMode ? 'white' : 'black',
        padding: '0px',
        borderRadius: '3px',
        boxShadow: isDarkMode
          ? '0 1px 2px rgba(255, 255, 255, 0.15)'
          : '0 1px 2px rgba(0, 0, 0, 0.15)',
      }}
    >
      <div style={{display:'flex',justifyContent:'center',alignItems:'center', backgroundColor:theme.tableHeader.color,padding:'4px',fontSize: '12px', marginBottom: '5px', fontWeight: 'bold' }}>
        {format(new Date(slice.points[0].data.x), 'MM/dd/yy')}
      </div>
      {slice.points.map(point => (
        <Box key={point.id} sx={{ padding:'8px',display: 'flex', alignItems: 'center', marginBottom: '0px' }}>
          <Box
            sx={{
              width: '12px',
              height: '12px',
              backgroundColor: point.serieColor,
              borderRadius: '50%',
              marginRight: '8px',
            }}
          />
          <div style={{ fontSize: '12px' }}>
            <strong>{point.serieId}</strong>: {formatValue(point.data.y)}
          </div>
        </Box>
      ))}
    </Box>
  );
}}
  
/>
)
}



const NewStockPage = (ticker) => {

  const shouldWrap2 = useMediaQuery('(max-width: 600px)');
  const formatPercentage = (num) =>{
    if (num < 1){
      return `${(num* 100).toFixed(1)}%`;
    }
    else{
      return `${(num* 100).toFixed(0)}%`;
    }
    
  }
  function formatNumber(num) {
    if (num === null || num === undefined) return 'N/A';
    if (num >= 1e9) {
      return `$${(num / 1e9).toFixed(1)}B`;
    } else if (num >= 1e6) {
      return `$${(num / 1e6).toFixed(0)}M`;
    } else {
      return num.toLocaleString();
    }
  }
  const theme = useTheme();
  const location = useLocation(); 
  const [capTable, setCapTable] = useState([]);
  const [financials, setFinancials] = useState([]);
  const tableContainerWidth = 900

  useEffect(() => {
    setFinancials([])
    const fetchData = async () => {
      try {
        // Ensure ticker is a string and log it for debugging
        const dynamicCapTable = await import(`../data/stockpage/private/captable/${ticker['ticker']}.json`);
        

        // Set the data in state, assuming dynamicData.default is an array of arrays as per provided data format
        if (Array.isArray(dynamicCapTable.default)) {
          setCapTable(dynamicCapTable.default);
        }

        
        const dynamicFinancials = await import(`../data/stockpage/private/financials/${ticker['ticker']}.json`);
        if (Array.isArray(dynamicFinancials.default)) {

            setFinancials(dynamicFinancials.default);
  
          // Check the last revenue value and set the scale to 'B' if it's greater than $10B
        }
        else {
          console.error('Unexpected data format:', dynamicCapTable);
        } 

      } catch (error) {
        console.error(`Error fetching data for ${ticker}:, error`);
      }
    }

    fetchData();
  }, [ticker]);

  

  
  // Function to handle button clicks and update the selected button state
  
  
  const hasFinancials = financials.length > 0;


  
  return(
  <Box sx ={{width: tableContainerWidth+50,minHeight:'67vh',maxWidth:'100%'}}>
    <Box sx={{ maxWidth: tableContainerWidth + 50,marginLeft:'1%',display: 'flex',justifyContent: 'flex-start',flexWrap: shouldWrap2 ? 'wrap' : 'nowrap'}}>
    
        
        {hasFinancials && <Paper elevation={2} sx={{  flex: 1,paddingTop:'5px',marginTop:'6px',paddingLeft:'2%',minWidth:'300px',maxWidth:'100%'}}>
        <Box sx={{ color: theme.palette.primary.main, fontSize: '24px', fontWeight: '600' }}>Reported ARR
        </Box>
        

          
          <Box style={{ flex: 1,height:'35vh',width:'100%', position: 'relative' }}> 
          <MyResponsiveLine data = {financials} theme={theme}/>
              <img
              src={require('../data/images/logo.png')}
              alt="Website Logo"
              style={{
                position: 'absolute',
                bottom: 50, // Adjust the distance from the bottom
                right: 62, // Adjust the distance from the right
                width: '130px', // Set the desired width for the postage stamp
                height: 'auto', // Maintain aspect ratio
                opacity: 0.4, // Set the desired opacity for the watermark
              }}
            />
        </Box>
        </Paper>}
        </Box>
    <Paper elevation={3} sx={{ maxWidth:tableContainerWidth+50, paddingBottom:'0px',paddingTop: '6px', marginTop: '6px', flex: 1, paddingLeft: '2%',marginLeft:'1%',paddingRight:'1%',marginRight:'0%' }}>
      <Box>
        <Box sx={{ color: theme.palette.primary.main, fontSize: '24px', fontWeight: '600' }}>Cap Table
        </Box>
      </Box>
      <TableContainer component={Paper} sx={{ maxWidth: '800px', marginTop: '5px' }}>
      <Table size="small" aria-label="capitalization table">
        <TableHead>
          <TableRow sx={{ backgroundColor: theme.tableHeader.color, fontWeight: '700', fontSize: '30' }}>
            <TableCell sx={{ paddingTop: '3px', paddingBottom: '3px' }} align="left">
              Date
            </TableCell>
            <TableCell sx={{ paddingTop: '3px', paddingBottom: '3px' }} align="right">
              Funding Round
            </TableCell>
            <TableCell sx={{ paddingTop: '3px', paddingBottom: '3px' }} align="right">
              Post-Money Valuation
            </TableCell>
            <TableCell sx={{ paddingTop: '3px', paddingBottom: '3px' }} align="right">
              Amount Raised
            </TableCell>
            <TableCell sx={{ paddingTop: '3px', paddingBottom: '3px' }} align="right">
              Pre-Money Valuation
            </TableCell>

            
          </TableRow>
        </TableHead>
        <TableBody>
          {capTable.map((row, index) => (
            <TableRow
              key={row.date}
              sx={{
                backgroundColor: index % 2 === 0 ? theme.tableHeader.highlight : theme.background.default,
                '&:last-child td, &:last-child th': { border: 0 },
                ':hover': {
                  backgroundColor: theme.tableHeader.highlightDark,
                  cursor: 'pointer',
                },
              }}
            >
              <TableCell sx={{ paddingTop: '3px', paddingBottom: '3px' }} align="left">
              {format(parseISO(row.announcementDate), 'MM/dd/yyyy')}
              </TableCell>
              <TableCell sx={{ paddingTop: '3px', paddingBottom: '3px' }} align="right">
                {row.series}
              </TableCell>
              <TableCell sx={{ paddingTop: '3px', paddingBottom: '3px' }} align="right">
                {row.postMoney ? formatNumber((row.postMoney)) : 'nd.'}
              </TableCell>

              <TableCell sx={{ paddingTop: '3px', paddingBottom: '3px' }} align="right">
                {formatNumber(row.investment)}
              </TableCell>
              <TableCell sx={{ paddingTop: '3px', paddingBottom: '3px' }} align="right">
                {row.preMoney ? formatNumber((row.preMoney)) : 'nd.'}
              </TableCell>

            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>

      <Box sx={{ paddingBottom: '2vh',marginBottom:'2vh' }}></Box>
    </Paper>
  </Box>
      )}
      export default NewStockPage;