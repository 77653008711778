import React, { useState,useMemo } from 'react';
import {Link} from 'react-router-dom';
import SearchIcon from '@mui/icons-material/Search';
import {Drawer, AppBar, TextField, Box, Button, Menu, MenuItem, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import '../index.css';
import itemListData from '../data/companylistsearch.json';
import { useAuth0 } from "@auth0/auth0-react";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { useMediaQuery } from "@mui/material";
import { useTheme } from '@emotion/react';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';  


function Header({ themeMode }) {
    const [searchOpen, setSearchOpen] = useState(false);  // Add searchOpen state
    const itemList = useMemo(() => {
      // Perform any additional filtering or sorting here if needed
      return itemListData;
    }, []);
    const theme = useTheme()

    const { loginWithRedirect, logout, isAuthenticated,user,isLoading } = useAuth0();
  const LoginButton = () => {
      return (
          <Button
          style={{ textTransform: 'none' }}
              tabIndex={-1}
              
              startIcon={<AccountCircleIcon />}
              onClick={() => loginWithRedirect()}
          >
              Sign In
          </Button>
      );
  };

  const LogoutButton = () => {
      return (
          <Button
          style={{ textTransform: 'none' }}
              tabIndex={-1}
              startIcon={<AccountCircleIcon />}
              onClick={() => logout({ returnTo: window.location.origin })}
          >
              Sign Out
          </Button>
      );
  };

  const [anchorElEvents, setAnchorElEvents] = useState(null);
  const [anchorElInsights, setAnchorElInsights] = useState(null);

  const handleEventsClick = (event) => {
      setAnchorElEvents(event.currentTarget);
  };

  const handleInsightsClick = (event) => {
      setAnchorElInsights(event.currentTarget);
  };
  const [accordionOpen, setAccordionOpen] = useState({ coverage: true, industryMetrics: true }); // Default state for accordions


  const handleClose = () => {
      setAnchorElEvents(null);
      setAnchorElInsights(null);
      setAccordionOpen({ coverage: true, industryMetrics: true });
      setSearchOpen(false)
  };

  const [query, setQuery] = useState('');
  const [filteredItems, setFilteredItems] = useState([]);

  // Function to handle search input change
  const handleSearchInputChange = (event) => {
      const inputValue = event.target.value;
      setQuery(inputValue);

      // Check if the input has at least two characters
      if (inputValue.length >= 1) {
          // Filter items based on the query
          const filtered = itemList.filter((item) =>
              item.name.toLowerCase().includes(inputValue.toLowerCase())
          );
          setFilteredItems(filtered);
      } else {
          // If the input has less than two characters, reset the filteredItems
          setFilteredItems([]);
      }
  };
  const toggleAccordion = (panel) => (event) => {
    setAccordionOpen((prev) => ({ ...prev, [panel]: !prev[panel] }));
};
  // Function to generate links based on the selected item
  const generateLinks = (items, themeMode) => {
      return items.map((item, index) => (
          <li key={index}>
              <Box sx={{
                  transition: 'background-color 0.3s',
                  '&:hover': {
                      backgroundColor: themeMode === 'light' ? '#f0f0f0' : 'rgb(70, 70, 70)',
                  }
              }}>
                  <Link
                      to={item.link}
                      onClick={() => {
                          window.scrollTo(0, 0);
                          handleClose(); // Close the dropdown
                          setQuery(''); // Reset the query
                          setFilteredItems([]);
                      }}
                      style={{ color:theme.palette.secondary.main, }}
                  >
                      <Box
                          sx={{
                              display: 'flex',
                              alignItems: 'center',
                              cursor: 'pointer', // Add cursor style to indicate it's clickable
                          }}
                      >
                          <img
                              src= {themeMode === 'light'?`/logos/${item.ticker}/icon.png`:`/logos/${item.ticker}/icondark.png`}
                              alt={`${item.ticker}`}
                              className="icon"
                              style={{ maxWidth: '30px', maxHeight: '30px', paddingRight: '10px' }}
                          />
                          {"    " + item.name}
                      </Box>
                  </Link>
              </Box>
          </li>
      ));
  };

  const shouldHide = useMediaQuery('(max-width: 790px)');
  const isDesktop = useMediaQuery('(max-width: 720px)');

  return (

          <AppBar elevation={2} position="sticky" sx={{ top: 0, zIndex: 1200,backgroundColor: themeMode === 'light' ? 'rgb(220,220,220)' : 'rgb(4,4,4)' }}>
              <Box className="header" sx={{ borderBottom: themeMode === 'light' ? '1px solid #c9c9c9' : '1px solid #343434' }}>
                  {!isDesktop&&<Box sx={{ maxHeight: '28px' }} className='header-logo'>
                      <Link to='/'>
                          <img
                              src={
                                  themeMode === 'light'
                                      ? require('../data/images/logo.png')
                                      : require('../data/images/logodark.png')
                              }
                              alt="Website Logo"
                              className="logo"
                              style={{ height: '26px', width: 'auto' }}
                          />
                      </Link>
                  </Box>}
                  {true && 
                  <Box sx={{marginLeft:'auto'}}>
                    {!isLoading && isAuthenticated && (<LogoutButton />)}
                    {!isLoading && !isAuthenticated && (<LoginButton />)}
                  </Box>
                  }

              </Box>
              <Box sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  paddingTop: '2px',
                  paddingBottom: '0px',
                  borderBottom: themeMode === 'light' ? '1px solid #c9c9c9' : '1px solid #000000'
              }}>
                {isDesktop&&<Box sx={{ maxHeight: '28px' }} className='header-logo'>
                      <Link to='/'>
                          <img
                              src={
                                  themeMode === 'light'
                                      ? require('../data/images/logo.png')
                                      : require('../data/images/logodark.png')
                              }
                              alt="Website Logo"
                              className="logo"
                              style={{ height: '26px', width: 'auto' }}
                          />
                      </Link>
                  </Box>}
                {!isDesktop &&
                      <Box sx ={{paddingLeft:'2%'}}>
                          <Button
                              style={{ textTransform: 'none' }}
                              aria-controls="events-menu"
                              aria-haspopup="true"
                              onClick={handleEventsClick}
                              sx={{
                                  fontSize: '15px',
                                  color: (theme) => theme.button.color, // Use the text color from the theme
                              }}
                              endIcon={<ArrowDropDownIcon />} // Add the arrow icon to the button
                          >
                              Market Coverage
                          </Button>
                          <Menu
                              id="events-menu"
                              anchorEl={anchorElEvents}
                              keepMounted
                              open={Boolean(anchorElEvents)}
                              onClose={handleClose}
                          >
                              <Link to="/coverage/all" style={{ textDecoration: 'none', color: 'inherit' }}>
                                  <MenuItem onClick={handleClose} style={{ 'padding-top': '15px', 'padding-bottom': '15px' }}>
                                      Public and Private Coverage
                                  </MenuItem>
                              </Link>
                              <Link to="/coverage/compsheet" style={{ textDecoration: 'none', color: 'inherit' }}>
                                  <MenuItem onClick={handleClose} style={{ 'padding-top': '15px', 'padding-bottom': '15px' }}>
                                      Public Comparables
                                  </MenuItem>
                              </Link>
                              <Link to="/coverage/vc" style={{ textDecoration: 'none', color: 'inherit' }}>
                                  <MenuItem onClick={handleClose} style={{ 'padding-top': '15px', 'padding-bottom': '15px' }}>
                                      VC-Backed Privates
                                  </MenuItem>
                              </Link>
                              <Link to="/coverage/acquisitions" style={{ textDecoration: 'none', color: 'inherit' }}>
                                  <MenuItem onClick={handleClose} style={{ 'padding-top': '15px', 'padding-bottom': '15px' }}>
                                      Public Company Acquisitions
                                  </MenuItem>
                              </Link>
                          </Menu>
                      </Box>
                  }

                  {!isDesktop &&
                      <Box>
                          <Button
                              style={{ textTransform: 'none' }}
                              aria-controls="events-menu"
                              aria-haspopup="true"
                              onClick={handleInsightsClick}
                              sx={{
                                  fontSize: '15px',
                                  color: (theme) => theme.button.color, // Use the text color from the theme
                              }}
                              endIcon={<ArrowDropDownIcon />} // Add the arrow icon to the button
                          >
                              Industry Metrics
                          </Button>
                          <Menu
                              id="events-menu"
                              anchorEl={anchorElInsights}
                              keepMounted
                              open={Boolean(anchorElInsights)}
                              onClose={handleClose}
                          >
                              <Link to="/industrymetrics/dashboard" style={{ textDecoration: 'none', color: 'inherit' }}>
                                  <MenuItem onClick={handleClose} style={{ 'padding-top': '15px', 'padding-bottom': '15px' }}>
                                      Metrics Dashboard
                                  </MenuItem>
                              </Link>
                              <Link to="/industrymetrics/valuation" style={{ textDecoration: 'none', color: 'inherit' }}>
                                  <MenuItem onClick={handleClose} style={{ 'padding-top': '15px', 'padding-bottom': '15px' }}>
                                      Valuation Plot
                                  </MenuItem>
                              </Link>

                          </Menu>
                      </Box>}
                  {!isDesktop &&
                      <Box>
                          <Button
                              style={{ textTransform: 'none' }}
                              sx={{
                                  fontSize: '15px',
                                  color: (theme) => theme.button.color, // Use the text color from the theme
                              }}
                          >
                              <Link to="/benchmarking" style={{ textDecoration: 'none', color: 'inherit' }}>
                                  Benchmarks
                              </Link>
                          </Button>
                      </Box>}
        
                  
                      {!isDesktop&&<Box></Box>}
                      {!isDesktop&&<Box></Box>}
                      {!isDesktop&&<Box></Box>}
                  {!isDesktop&&<Box sx={{ marginTop: '2px', marginBottom: '2px', display: 'flex', displayContent: 'flex-start', marginRight: "2%", marginLeft: "2%" }}>
                      {!shouldHide && (
                          <Box sx={{
                              color: (theme) => theme.palette.secondary.main, // Use the text color from the theme
                              width: '70px', fontSize: '13px', marginTop:'4px',
                              fontWeight: 'bold'
                          }}>
                              Security Analysis
                          </Box>
                      )}
                      <Box sx={{ minWidth: '100px', maxWidth: '430px', alignItems: 'right', position: 'relative', display: 'block', marginLeft: '4px' }}>
                      <TextField
                        variant="outlined"
                        size="small"
                        fullWidth
                        value={query}
                        onChange={handleSearchInputChange}
                        sx={{ 
                            position: 'relative', 
                            
                        }}
                        InputProps={{
                            startAdornment: (
                            <SearchIcon sx={{ color: 'grey', marginRight: '4px' }} />
                            ),
                            sx: {
                                
                            '& .MuiInputBase-input': {
                                color: theme.palette.text.primary,
                                '&::placeholder': {
                                    fontSize: '13px',
                                color: theme.palette.text.secondary,
                                },
                            },
                            backgroundColor: themeMode === 'light' ? '#ffffff' : 'rgb(50, 50, 50)' },
                        }}
                        onClick={() => {
                            setQuery('');
                            setFilteredItems([]);
                        }}
                        InputLabelProps={{
                            sx: {
                            fontSize: '13px',
                            marginTop: '6px',
                            fontWeight: 'bold',
                            color: theme.palette.text.secondary,
                            backgroundColor: 'null'
                            },
                        }}
                        placeholder="Search by Company"
                        label=""
                        />
                          <Box className="dropdown-list" sx={{ backgroundColor:theme.tableHeader.highlight }}>
                              {generateLinks(filteredItems, themeMode)}
                          </Box>
                      </Box>
                  </Box>}
                  {isDesktop && (
                    <Box sx={{ paddingLeft: '0px', marginLeft: 'auto' }}>
                        <Button
                        style={{ padding: '0,0,0,0', textTransform: 'none' }}
                        aria-controls="search-menu"
                        aria-haspopup="true"
                        onClick={() => setSearchOpen(true)} // Open search drawer
                        >
                        <SearchIcon style={{ fontSize: '32px' }} />
                        </Button>
                        <Drawer
                        
                        elevation={0}
                        id="search-menu"
                        anchor="right"
                        keepMounted
                        open={searchOpen}  // Variable to control drawer visibility
                        onClose={() => setSearchOpen(false)}
                        sx={{
                            width: '100vw',
                            height: '100vh',
                            top: '83px',
                            '& .MuiDrawer-paper': {
                            backgroundColor:theme.tableHeader.highlight,
                            width: '100vw',
                            height: '100vh',
                            top: '83px',
                            },
                        }}
                        >
                        <Box sx={{ padding: '20px' }}>
                            <TextField
                            variant="outlined"
                            size="small"
                            fullWidth
                            value={query}
                            onChange={handleSearchInputChange}
                            InputProps={{
                                startAdornment: (
                                <SearchIcon sx={{ color: 'grey', marginRight: '4px' }} />
                                ),
                                sx: {
                                '& .MuiInputBase-input': {
                                    color: theme.palette.text.primary,
                                    '&::placeholder': {
                                    fontSize: '13px',
                                    color: theme.palette.text.secondary,
                                    },
                                },
                                backgroundColor: themeMode === 'light' ? '#ffffff' : 'rgb(50, 50, 50)',
                                },
                            }}
                            placeholder="Search by Company"
                            />
                            </Box>
                            <Box className="dropdown-list" sx={{top:80, backgroundColor:theme.tableHeader.highlight }}>
                            {filteredItems.length > 0 ? (
                                generateLinks(filteredItems, themeMode)
                            ) : (
                                <Box sx={{ padding: '10px', color: theme.palette.text.secondary }}>
                                No results found.
                                </Box>
                                )}
                        
                        </Box>
                        </Drawer>
                        </Box>
            )}
                  {isDesktop &&
                      <Box sx={{ paddingLeft: '5px' }}>
                          <Button
                              style={{ padding:'0,0,0,0',textTransform: 'none' }}
                              aria-controls="insights-menu"
                              aria-haspopup="true"
                              onClick={handleInsightsClick}
                          >
                            {anchorElInsights ? <CloseIcon style={{ fontSize: '28px' }} /> : <MenuIcon style={{ fontSize: '32px' }} />}
                              
                          </Button>
                          <Drawer
                            id="insights-menu"
                            anchor="right"
                            keepMounted
                            elevation={0}
                            open={Boolean(anchorElInsights)}
                            onClose={handleClose}
                            sx={{

                                width: '100vw',
                                height: '100vh',
                                top: '83px',
                                '& .MuiDrawer-paper': {
                                    backgroundColor:theme.tableHeader.highlight,
                                    width: '100vw',
                                    height: '100vh',
                                    top: '83px',
                                }
                            }}
                        >
                            <Accordion sx={{color:theme.palette.primary.main,backgroundColor:theme.background.backgroundColor}} expanded={accordionOpen['coverage']} onChange={toggleAccordion('coverage')}>
                                <AccordionSummary expandIcon={<ArrowDropDownIcon />}>
                                    <h4>Coverage</h4>
                                </AccordionSummary>
                                <AccordionDetails sx={{color:theme.palette.text.secondary}}>
                                        <Link to="/coverage/all" style={{ textDecoration: 'none', color: 'inherit' }}>
                                            <MenuItem onClick={handleClose}>Public and Private Coverage</MenuItem>
                                        </Link>
                                        <Link to="/coverage/compsheet" style={{ textDecoration: 'none', color: 'inherit' }}>
                                            <MenuItem onClick={handleClose}>Public Comparables</MenuItem>
                                        </Link>
                                        <Link to="/coverage/vc" style={{ textDecoration: 'none', color: 'inherit' }}>
                                            <MenuItem onClick={handleClose}>VC-Backed Privates</MenuItem>
                                        </Link>
                                        <Link to="/coverage/acquisitions" style={{ textDecoration: 'none', color: 'inherit' }}>
                                            <MenuItem onClick={handleClose}>Public Company Acquisitions</MenuItem>
                                        </Link>

                                </AccordionDetails>
                            </Accordion>

                            <Accordion sx={{color:theme.palette.primary.main,backgroundColor:theme.background.backgroundColor}} expanded={accordionOpen['industryMetrics']} onChange={toggleAccordion('industryMetrics')}>
                                <AccordionSummary expandIcon={<ArrowDropDownIcon />}>
                                    <h4>Industry Metrics</h4>
                                </AccordionSummary>
                                <AccordionDetails sx={{color:theme.palette.text.secondary}}>
                                    <Box>
                                        <Link to="/industrymetrics/dashboard" style={{ textDecoration: 'none', color: 'inherit' }}>
                                            <MenuItem onClick={handleClose}>Industry Metric Dashboard</MenuItem>
                                        </Link>
                                        <Link to="/industrymetrics/valuation" style={{ textDecoration: 'none', color: 'inherit' }}>
                                            <MenuItem onClick={handleClose}>Valuation Plot</MenuItem>
                                        </Link>
                                    </Box>
                                </AccordionDetails>
                            </Accordion>


                        </Drawer>
                      </Box>}
              </Box>
          </AppBar>

  );
};

export default Header;