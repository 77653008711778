import React, { useState, useEffect,useRef } from 'react';
import '../index.css';
import { useLocation } from 'react-router-dom';
import {FaStore,FaDatabase, FaIndustry, FaDesktop, FaBuilding,FaCoins,FaFileInvoice,FaCode,FaServer,FaShieldAlt,  FaUsers,FaHammer,FaNetworkWired} from 'react-icons/fa';
import TableChartIcon from '@mui/icons-material/TableChart';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import {ListItemIcon,Collapse,Paper,Table,TableBody,TableCell,TableContainer,TableHead,TableRow,ToggleButton,Checkbox, Box, Typography,Tooltip,IconButton,List,ToggleButtonGroup,MenuItem,Select,ListItemText, FormControlLabel,Switch } from '@mui/material';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import EventIcon from '@mui/icons-material/Event';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import WidthFullIcon from '@mui/icons-material/WidthFull';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { useTheme } from '@emotion/react';
import { NavLink } from 'react-router-dom';
import ChangeHistoryIcon from '@mui/icons-material/ChangeHistory';
import { ArrowDownward, ArrowUpward,ArrowForward } from '@mui/icons-material';


const CompSheetComponent = ({categoryInput,tickerInput,trendedData}) => {
  const theme = useTheme();
  const location = useLocation(); // If using React Router
  const tableContainerRef = useRef(null);
  const [sortBy, setSortBy] = useState('Market Cap');
  const [ticker, setTicker] = useState('');
  const [sortOrder, setSortOrder] = useState('desc'); // Default sort 
  const [alignment, setAlignment] = React.useState('growth');
  const [checkedCategory, setCheckedCategory] = React.useState([]);
  const [expandedApp, setExpandedApp] = useState(false); 
  const [expandedInfra, setExpandedInfra] = useState(false); 
  const [expandedVert, setExpandedVert] = useState(false);
  useEffect(() => {
    setTicker(tickerInput);
  
  }, [tickerInput]);

  useEffect(() => {
    if (Array.isArray(categoryInput)) {
      setCheckedCategory(categoryInput);
    } else {
      console.error('categoryInput should be an array');
      setCheckedCategory([1,2,3,4,10,11,12,20,21,22,23,24,30,31,32]);
    }
  }, [categoryInput]);


  const [open, setOpen] = useState(true); // State to manage the open/closed state
  const [isSlided, setIsSlided] = useState(false);
  
  const handleExpandAppToggle = (e) => {
    e.stopPropagation(); // Stop event propagation to prevent filtering
    setExpandedApp(!expandedApp); // Toggle the expansion state
  };
  const handleSliderChange = (event) => {
    setIsSlided(event.target.checked);
  };
  const handleExpandInfraToggle = (e) => {
    e.stopPropagation(); // Stop event propagation to prevent filtering
    setExpandedInfra(!expandedInfra); // Toggle the expansion state
  };
  const handleExpandVertToggle = (e) => {
    e.stopPropagation(); // Stop event propagation to prevent filtering
    setExpandedVert(!expandedVert); // Toggle the expansion state
  };
  const category = [
    {
      id: 1,
      subIndustry:'Application',
      filter: 'Application',
      label: 'Application Software',
      icon: <FaDesktop />,
    },
    {
      id: 10,
      subIndustry:'Application',
      filter: 'CRM, Marketing, and Support',
      label: 'CRM, Marketing & Support',
      icon: <FaStore />,
    },
    {
      id: 11,
      subIndustry:'Application',
      filter: 'HR, Finance & Supply Chain',
      label: 'HR, Finance & SCM',
      icon: <FaUsers />,
    },
    {
      id: 12,
      subIndustry:'Application',
      filter: 'Productivity & Content Creation',
      label: 'Productivity',
      icon: <FaFileInvoice />,
    },
    {
      id: 2,
      subIndustry:'Infrastructure',
      filter: 'Infrastructure',
      label: 'Infrastructure Software',
      icon: <FaDatabase />,
    },
    
    {
      id: 20,
      subIndustry:'Infrastructure',
      filter: 'Database & Data Platforms',
      label: 'Database & Data Platforms',
      icon: <FaDatabase />,
    },
    {
      id: 21,
      subIndustry:'Infrastructure',
      filter: 'Dev Tools & Low Code',
      label: 'Dev Tools & Low Code',
      icon: <FaCode />,
    },
    {
      id: 22,
      subIndustry:'Infrastructure',
      filter: 'IT Operations',
      label: 'IT Operations',
      icon: <FaServer />,
    },
    {
      id: 23,
      subIndustry:'Infrastructure',
      filter: 'Security & Endpoint Management',
      label: 'Security & UEM',
      icon: <FaShieldAlt />,
    },
    {
      id: 24,
      subIndustry:'Infrastructure',
      filter: 'Networking & CDN',
      label: 'Networking & CDN',
      icon: <FaNetworkWired />,
    },
    {
      id: 24,
      subIndustry:'Infrastructure',
      filter: 'Networking & CDN',
      label: 'Networking & CDN',
      icon: <FaNetworkWired />,
    },
    {
      id: 3,
      subIndustry:'Vertical',
      filter: 'Vertical',
      label: 'Vertical Software',
      icon: <FaIndustry />,
    },
    {
      id: 30,
      subIndustry:'Vertical',
      filter: 'Financials',
      label: 'Financials',
      icon: <FaCoins />,
    },
    {
      id: 31,
      subIndustry:'Vertical',
      filter: 'Engineering & Industrials',
      label: 'Engineering & Industrials',
      icon: <FaHammer />,
    },
    {
      id: 32,
      subIndustry:'Vertical',
      filter: 'Other Vertical',
      label: 'Other Vertical',
      icon: <FaIndustry />,
    },
    {
      id: 4,
      subIndustry:'Diversified',
      filter: 'Diversified',
      label: 'Diversified Software',
      icon: <FaBuilding />,
    },
  ];
    
    const filters = [
      {name:'growth',maxWidth:1570,filterList:['Market Cap','Enterprise Value','EV/Sales NTM','EV/GM NTM','Revenue Growth NTM%','Revenue YoY%','Gross Margin YoY%','Cloud YoY%','Calc Billings YoY%','RPO YoY%','cRPO YoY%','ARR YoY%','Customers >$100k YoY%','Customers >$1M YoY%']},
      {name:'profit',maxWidth:1200,filterList:['Market Cap','Enterprise Value','EV/Sales NTM','EV/GM NTM','GM %','S&M %','R&D %','G&A %','FCF %','Share Dilution']},
      {name:'metrics',maxWidth:1220,filterList:['Market Cap','Enterprise Value','EV/Sales NTM','EV/GM NTM','NRR','Payback Period','Rule of 40','Cloud Mix %','Current RPO Mix %','RPO Duration']},
      {name:'momentum',maxWidth:1500,filterList:['Market Cap','Enterprise Value','EV/Sales NTM','EV/GM NTM','Revenue YoY% Seq Δ','Gross Margin YoY% Seq Δ','Cloud YoY% Seq Δ','Calc Billings YoY% Seq Δ','RPO YoY% Seq Δ','cRPO YoY% Seq Δ','ARR YoY% Seq Δ','Customers >$100k YoY% Seq Δ','Customers >$1M YoY% Seq Δ']},
      {name:'all',maxWidth:4000,filterList:['Market Cap','Enterprise Value','EV/Sales NTM','EV/GM NTM','Revenue Growth NTM%','Revenue YoY%','Gross Margin YoY%','Cloud YoY%','Calc Billings YoY%','RPO YoY%','cRPO YoY%','ARR YoY%','Customers >$100k YoY%','Customers >$1M YoY%','GM %','S&M %','R&D %','G&A %','FCF %','Share Dilution','NRR','Payback Period','Rule of 40','Cloud Mix %','Current RPO Mix %','RPO Duration','Revenue YoY% Seq Δ','Gross Margin YoY% Seq Δ','Cloud YoY% Seq Δ','Calc Billings YoY% Seq Δ','RPO YoY% Seq Δ','cRPO YoY% Seq Δ','ARR YoY% Seq Δ','Customers >$100k YoY% Seq Δ','Customers >$1M YoY% Seq Δ']},
      

    ]
        
    const handleToggleCollapse = () => {
      setOpen(!open);
    };
  useEffect(() => {


    const initialMarketCapValueA = trendedData[0].metrics.find(metric => metric.field === 'Market Cap').val;
    const initialMarketCapValueB = trendedData[1].metrics.find(metric => metric.field === 'Market Cap').val;
    if (initialMarketCapValueA < initialMarketCapValueB) {
      setSortOrder('asc');
    }
  }, [location.pathname]);

  const handleSort = (field) => {
    if (field === sortBy) {
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    } else {
      setSortBy(field);
      setSortOrder('desc'); // Default to descending order for new field
    }
  };
  
  const sortedData = trendedData.sort((a, b) => {
    if (sortBy) {
      const valA = a.metrics.find(metric => metric.field === sortBy).val;
      const valB = b.metrics.find(metric => metric.field === sortBy).val;
  
      // Handling -757 values
      if (valA === -757) return 1; // valA is -757, so it should be placed below
      if (valB === -757) return -1; // valB is -757, so it should be placed below
  
      // If both values are not -757, proceed with normal comparison
      return sortOrder === 'asc' ? valA - valB : valB - valA;
    }
    return 0; // No sorting
  });
  const handleChange = (event, newAlignment) => {
    if (newAlignment !== null) {
      setAlignment(newAlignment);
    }
  };

  const handleCheckedChangeCategory = (event) => {
    const { value: selectedItems } = event.target;
    
    setCheckedCategory(prevCheckedCategory => {
        // Check if '1' has been newly checked
        const oneChecked = selectedItems.includes(1) && !prevCheckedCategory.includes(1);
        const oneUnchecked = !selectedItems.includes(1) && prevCheckedCategory.includes(1);
        const twoChecked = selectedItems.includes(2) && !prevCheckedCategory.includes(2);
        const twoUnchecked = !selectedItems.includes(2) && prevCheckedCategory.includes(2);
        const threeChecked = selectedItems.includes(3) && !prevCheckedCategory.includes(3);
        const threeUnchecked = !selectedItems.includes(3) && prevCheckedCategory.includes(3);

        // Initialize the new state with the selected items
        let newState = [...selectedItems];

        if (oneChecked) {
            newState.push(10,11,12); 
        }
        if (oneUnchecked) {
          newState = newState.filter(item => ![10, 11, 12].includes(item));
        }
        if (twoChecked) {
          newState.push(20,21,22,23,24); 
        }
        if (twoUnchecked) {
          newState = newState.filter(item => ![20,21,22,23,24].includes(item));
        }
        if (threeChecked) {
          newState.push(30,31,32); 
        }
        if (threeUnchecked) {
          newState = newState.filter(item => ![30,31,32].includes(item));
        }
        if (!oneChecked && !oneUnchecked && newState.includes(10) && newState.includes(11) && newState.includes(12)&&!newState.includes(1)) {
          newState.push(1);
        }
        if (!oneChecked && !oneUnchecked && !newState.includes(10) && !newState.includes(11) && !newState.includes(12)&&newState.includes(1)) {
          newState = newState.filter(item => ![1].includes(item));
        }
        if (!twoChecked && !twoUnchecked && newState.includes(20) && newState.includes(21) && newState.includes(22) && newState.includes(23) && newState.includes(24) &&!newState.includes(2)) {
          newState.push(2);
        }
        if (!twoChecked && !twoUnchecked && !newState.includes(20) && !newState.includes(21) && !newState.includes(22)&& !newState.includes(23)&& !newState.includes(24)&&newState.includes(2)) {
          newState = newState.filter(item => ![2].includes(item));
        }
        if (!threeChecked && !threeUnchecked && newState.includes(30) && newState.includes(31) && newState.includes(32)&&!newState.includes(3)) {
          newState.push(3);
        }
        if (!threeChecked && !threeUnchecked && !newState.includes(30) && !newState.includes(31) && !newState.includes(32)&&newState.includes(3)) {
          newState = newState.filter(item => ![3].includes(item));
        }
        return newState;
    });
}
const firstRow = sortedData
    .filter((company) => company.ticker === ticker).map((company) => ({
      ...company,
      metrics: company.metrics.filter((metric) => {
        return filters.find((filter) => filter.name === alignment)?.filterList.includes(metric.field);
      }),
    })) 

  const filteredData = sortedData
    .filter((company) => company.ticker != ticker) // Filter by ticker
    .map((company) => ({
      ...company,
      metrics: company.metrics.filter((metric) => {
        return filters.find((filter) => filter.name === alignment)?.filterList.includes(metric.field);
      }),
    }))
    .filter((company) => {
      // Check if the category exists and matches any of the checked categories
      const categoryMatch = company.category && checkedCategory.some((item) => company.category === category.find((i) => i.id === item)?.filter);
      return categoryMatch; // Return true only if both subIndustry and category match
    })
    .filter((company) => {
      const lastReportedQuarterDate = new Date('2024-06-01');
      
      return isSlided ? new Date(company.reportedDate) >= lastReportedQuarterDate : true;
    });
const maxTableWidth = filters.find(filter => filter.name === alignment)?.maxWidth

return(
  <Box sx ={{minHeight:'75vh',maxWidth:'100%'}}>


    <Paper elevation={2} sx={{backgroundColor: theme.background.backgroundColor, marginLeft:'1%',paddingBottom: '4px', maxWidth: '100%', paddingTop: '0px', marginTop: '6px', marginBottom: '0px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
      {!open && (
            <Box
              className='label'
              sx={{
                paddingTop: '6px',
                paddingBottom: '6px',
                paddingLeft: '1.5%',
                fontWeight: 'bold',
              }}
            >
              <Box sx={{ paddingTop: '2px', fontSize: '12px',minWidth:'200px' }}>
                Expand Dashboard Filters
              </Box>
            </Box>
          )}
        <Collapse in={open} sx={{ width: '95%' }}>
        <Box sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap',justifyContent:'center' }}>
        <Paper elevation={theme.palette.mode=='dark'?4:2} sx={{backgroundColor: theme.background.backgroundColor, width: '580px', marginLeft: '8px', paddingTop: '8px', marginTop: '6px', paddingLeft: '4px', paddingRight: '8px', paddingBottom: '0px', display: 'flex', alignItems: 'center' }}>
          <Box sx={{ maxWidth: '60px', fontSize: '12px', fontWeight: 'bold', textAlign: 'center',  paddingBottom: '10px' }}>Company Filters</Box>
          <Box sx={{ display: 'flex', justifyContent: 'flex-start', flex: 1, flexWrap: 'wrap' }}>
          
              <Box sx={{ marginBottom: '0px', marginLeft: '8px', marginRight: '4px' }}>
                <List size='small' disablePadding sx={{backgroundColor:theme.tableHeader.highlight, height:'38px ',width:'300px', display: 'flex', flexDirection: 'row'}}>
                <Select size="small" 
                  sx={{width:'300px'}}
                  multiple
                  value={checkedCategory} // Use checkedCategory here
                  onChange={handleCheckedChangeCategory} // Use handleCheckedChangeCategory here
                  renderValue={(selected) => {
                    if (selected.length === 0) {
                      return "No Items Selected";
                    }
                    else if (selected.includes(10) && selected.includes(11) && selected.includes(12)&&selected.includes(20) && selected.includes(21) && selected.includes(22)&& selected.includes(23)&& selected.includes(24)&&selected.includes(30) && selected.includes(31) && selected.includes(32)&& selected.includes(4)) {
                      return <Typography variant="body2">All Categories: No Filters Applied</Typography>;
                    }
                    else if (selected.includes(10) && selected.includes(11) && selected.includes(12)&&!selected.includes(2)&&!selected.includes(3)&&!selected.includes(4)&&!selected.includes(20)&&!selected.includes(21)&&!selected.includes(22)&&!selected.includes(23)&&!selected.includes(24)&&!selected.includes(30)&&!selected.includes(31)&&!selected.includes(32)){
                      return <Typography variant="body2">Application Software</Typography>;
                    }
                    else if (selected.includes(20) && selected.includes(21) && selected.includes(22)&& selected.includes(23)&& selected.includes(24)&&!selected.includes(1)&&!selected.includes(3)&&!selected.includes(4)&&!selected.includes(10)&&!selected.includes(11)&&!selected.includes(12)&&!selected.includes(30)&&!selected.includes(31)&&!selected.includes(32)){
                      return <Typography variant="body2">Infrastructure Software</Typography>;
                    }
                    else if (selected.includes(30) && selected.includes(31) && selected.includes(32)&&!selected.includes(2)&&!selected.includes(1)&&!selected.includes(4)&&!selected.includes(20)&&!selected.includes(21)&&!selected.includes(22)&&!selected.includes(23)&&!selected.includes(24)&&!selected.includes(10)&&!selected.includes(11)&&!selected.includes(12)){
                      return <Typography variant="body2">Vertical Software</Typography>;
                    }
                    else if (selected.length === 1) {
                      const selectedItem = category.find(item => item.id === selected[0]); // Use category here
                      return <Typography variant="body2">{selectedItem.label}</Typography>;
                    } else {
                      return <Typography variant="body2">(multiple items selected)</Typography>;
                    }
                  }}
                  MenuProps={{
                    PaperProps: {
                      style: {
                        maxHeight: 250,
                        width: 'auto',
                      },
                    },
                  }}
                >
                  {category.slice(0,1).map((item) => (
                    <MenuItem size="small" key={item.id} value={item.id} sx={{ height:'40px'}}>
                      
                      <ListItemIcon size="small" >
                        {item.icon}
                      </ListItemIcon>
                      
                      <ListItemText primary={
                        <Typography variant="body2"> {/* Set variant="body2" for smaller text */}
                          {item.label}
                        </Typography>
                      } />
                      <IconButton size="small" onClick={handleExpandAppToggle}>  
                      {expandedApp ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                      </IconButton> 
                      <Checkbox  indeterminate={(checkedCategory.indexOf(1)!== -1 && 
                        (checkedCategory.indexOf(10)=== -1||checkedCategory.indexOf(11)=== -1||checkedCategory.indexOf(12)=== -1))
                        ||(checkedCategory.indexOf(1)=== -1 && 
                        (checkedCategory.indexOf(10)!== -1||checkedCategory.indexOf(11)!== -1||checkedCategory.indexOf(12)!== -1))} 
                        size="small" checked={checkedCategory.indexOf(item.id) !== -1} /> {/* Use checkedCategory here */}
                    </MenuItem>
                  ))}
                  {expandedApp && category.slice(1,4).map((item) => (
                    <MenuItem size="small" key={item.id} value={item.id} sx={{height:'26px'}}>
                    <Box sx={{width:'18px'}}></Box> 
                    <ListItemIcon  size="small" >
                      {item.icon}
                    </ListItemIcon>
                    
                    <ListItemText  primary={
                      <Typography variant="body2"> {/* Set variant="body2" for smaller text */}
                        {item.label}
                      </Typography>
                    } />
                    <Checkbox size="small" checked={checkedCategory.indexOf(item.id) !== -1} /> {/* Use checkedCategory here */}
                  </MenuItem>                 
                  ))}
                  {category.slice(4,5).map((item) => (
                    <MenuItem size="small" key={item.id} value={item.id} sx={{ height:'40px'}}>
                      
                    <ListItemIcon size="small" >
                      {item.icon}
                    </ListItemIcon>
                    <ListItemText primary={
                      <Typography variant="body2"> {/* Set variant="body2" for smaller text */}
                        {item.label}
                      </Typography>
                    } />
                    <IconButton size="small" onClick={handleExpandInfraToggle}>  
                      {expandedInfra ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                      </IconButton> 
                    <Checkbox indeterminate={(checkedCategory.indexOf(2)!== -1 && 
                        (checkedCategory.indexOf(20)=== -1||checkedCategory.indexOf(21)=== -1||checkedCategory.indexOf(22)=== -1||checkedCategory.indexOf(23)=== -1||checkedCategory.indexOf(24)=== -1))
                        ||(checkedCategory.indexOf(2)=== -1 && 
                        (checkedCategory.indexOf(20)!== -1||checkedCategory.indexOf(21)!== -1||checkedCategory.indexOf(22)!== -1||checkedCategory.indexOf(23)!== -1||checkedCategory.indexOf(24)!== -1))} 
                        size="small" checked={checkedCategory.indexOf(item.id) !== -1} /> {/* Use checkedCategory here */}
                  </MenuItem>
                  ))}
                  {expandedInfra && category.slice(5,10).map((item) => (
                    <MenuItem size="small" key={item.id} value={item.id} sx={{ height:'26px'}}>
                    <Box sx={{width:'18px'}}></Box> 
                    <ListItemIcon size="small" >
                      {item.icon}
                    </ListItemIcon>
                    <ListItemText primary={
                      <Typography variant="body2"> {/* Set variant="body2" for smaller text */}
                        {item.label}
                      </Typography>
                    } />
                    <Checkbox size="small" checked={checkedCategory.indexOf(item.id) !== -1} /> {/* Use checkedCategory here */}
                  </MenuItem>
                  ))}
                  {category.slice(11,12).map((item) => (
                    <MenuItem size="small" key={item.id} value={item.id} sx={{ height:'40px'}}>
                      
                    <ListItemIcon size="small" >
                      {item.icon}
                    </ListItemIcon>
                    <ListItemText primary={
                      <Typography variant="body2"> {/* Set variant="body2" for smaller text */}
                        {item.label}
                      </Typography>
                    } />
                    <IconButton size="small" onClick={handleExpandVertToggle}>  
                      {expandedVert ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                      </IconButton> 
                    <Checkbox indeterminate={(checkedCategory.indexOf(3)!== -1 && 
                        (checkedCategory.indexOf(30)=== -1||checkedCategory.indexOf(31)=== -1||checkedCategory.indexOf(32)=== -1))
                        ||(checkedCategory.indexOf(3)=== -1 && 
                        (checkedCategory.indexOf(30)!== -1||checkedCategory.indexOf(31)!== -1||checkedCategory.indexOf(32)!== -1))} 
                        size="small" checked={checkedCategory.indexOf(item.id) !== -1} /> {/* Use checkedCategory here */}
                  </MenuItem>
                  ))}
                  {expandedVert && category.slice(12,15).map((item) => (
                    <MenuItem size="small" key={item.id} value={item.id} sx={{ height:'26px'}}>
                      <Box sx={{width:'18px'}}></Box> 
                    <ListItemIcon size="small" >
                      {item.icon}
                    </ListItemIcon>
                    <ListItemText primary={
                      <Typography variant="body2"> {/* Set variant="body2" for smaller text */}
                        {item.label}
                      </Typography>
                    } />
                    <Checkbox size="small" checked={checkedCategory.indexOf(item.id) !== -1} /> {/* Use checkedCategory here */}
                  </MenuItem>
                  ))}
                  {category.slice(15,16).map((item) => (
                    <MenuItem size="small" key={item.id} value={item.id} sx={{ height:'40px'}}>
                      
                    <ListItemIcon size="small" >
                      {item.icon}
                    </ListItemIcon>
                    <ListItemText primary={
                      <Typography variant="body2"> {/* Set variant="body2" for smaller text */}
                        {item.label}
                      </Typography>
                    } />
                    <Checkbox size="small" checked={checkedCategory.indexOf(item.id) !== -1} /> {/* Use checkedCategory here */}
                  </MenuItem>
                  ))}
                </Select>
                </List>
                <Box sx={{ marginTop:'2px',paddingBottom:'4px',fontSize: '11px', alignItems: 'center', textAlign: 'center' }}>Filter by Software Category</Box>
              </Box>

              <Box sx={{ marginLeft: '4px', marginRight: '0%', marginBottom: 0 }}>
              <Box sx={{ backgroundColor:theme.tableHeader.highlight,height:'38px',width:'165px',display: 'flex', justifyContent: 'flex-start', alignItems: 'center',border: `1px solid ${theme.palette.mode === 'dark' ? '#333' : '#ddd'}`, borderRadius: '4px' }}>
                <Box sx={{width:'10px'}}></Box>
              {isSlided ? <EventIcon />: <CalendarMonthIcon />}
                <FormControlLabel
                  control={<Switch checked={isSlided} onChange={handleSliderChange} />}
                  label={
                    <Typography variant="body2" sx={{ fontWeight: 'bold', fontSize: '10px' }}> {/* Adjust variant as needed */}
                      {isSlided ? 'Q2 Reported Only' : 'Last Reported Quarter'}
                    </Typography>
                  }
                />
              </Box>
                <Box sx={{ marginTop:'2px',paddingBottom:'4px',fontSize: '11px', alignItems: 'center', textAlign: 'center' }}>
                  Filter By Reported Date
                  </Box>
             </Box>
              </Box>
              
            </Paper>
          
            <Paper elevation={theme.palette.mode=='dark'?4:2} sx={{backgroundColor: theme.background.backgroundColor, width: '340px', marginLeft: '8px', paddingTop: '8px', marginTop: '6px', paddingLeft: '4px', paddingRight: '8px', paddingBottom: '0px', display: 'flex', alignItems: 'center' }}>
            <Box sx={{ maxWidth: '45px', fontSize: '12px', fontWeight: 'bold', textAlign: 'center',  paddingBottom: '10px' }}>Chart Filters</Box>
              <Box sx={{  marginLeft: '8px', marginRight: '4px' }}>
              <ToggleButtonGroup
                sx={{
                  backgroundColor:theme.tableHeader.highlight,
                  '& .Mui-selected': {
                    color: theme.toggleButton.selectedTextColor,
                    backgroundColor: theme.toggleButton.selectedBackgroundColor,
                  },
                }}
                value={alignment}
                exclusive
                onChange={handleChange}
                size="small"
                aria-label="Time Period Toggle"
              >
                <ToggleButton style={{ textTransform: 'none' }} value="metrics" sx={{ minWidth:'55px',py: 0.1, px: 1.1 }}>
                  <Tooltip title="Key Performance Indicators">
                    <IconButton color={alignment === 'metrics' ? 'primary' : 'default'}  sx={{ py: 0, px: 0 }}>
                      <Box>
                        <TableChartIcon />
                        <Typography variant="body2" align="center" sx={{ mt: -1, fontWeight: 'bold', fontSize: '10px' }}>KPIs</Typography>
                      </Box>
                    </IconButton>
                  </Tooltip>
                </ToggleButton>
                <ToggleButton style={{ textTransform: 'none' }} value="growth" sx={{ minWidth:'55px',py: 0.1, px: 1.1 }}>
                  <Tooltip title="Metric Growth">
                    <IconButton color={alignment === 'growth' ? 'primary' : 'default'}  sx={{ py: 0, px: 0 }}>
                      <Box>
                        <ShowChartIcon />
                        <Typography variant="body2" align="center" sx={{ mt: -1, fontWeight: 'bold', fontSize: '10px' }}>Growth</Typography>
                      </Box>
                    </IconButton>
                  </Tooltip>
                </ToggleButton>
                <ToggleButton style={{ textTransform: 'none' }} value="momentum" sx={{ minWidth:'55px',py: 0.1, px: 1.1 }}>
                  <Tooltip title="Change in Growth Rate">
                    <IconButton color={alignment === 'momentum' ? 'primary' : 'default'}  sx={{ py: 0, px: 0 }}>
                      <Box>
                        <ChangeHistoryIcon />
                        <Typography variant="body2" align="center" sx={{ mt: -1, fontWeight: 'bold', fontSize: '10px' }}>Change</Typography>
                      </Box>
                    </IconButton>
                  </Tooltip>
                </ToggleButton>
                
                <ToggleButton style={{ textTransform: 'none' }} value="profit" sx={{ minWidth:'55px',py: 0.1, px: 1.1 }}>
                  <Tooltip title="Profitability">
                    <IconButton color={alignment === 'profit' ? 'primary' : 'default'}  sx={{ py: 0, px: 0 }}>
                      <Box>
                        <AttachMoneyIcon />
                        <Typography variant="body2" align="center" sx={{ mt: -.8, fontWeight: 'bold', fontSize: '10px' }}>Profit</Typography>
                      </Box>
                    </IconButton>
                  </Tooltip>
                </ToggleButton>
                <ToggleButton style={{ textTransform: 'none' }} value="all" sx={{ minWidth:'55px',py: 0.1, px: 1.1 }}>
                  <Tooltip title="All Metrics">
                    <IconButton color={alignment === 'all' ? 'primary' : 'default'}  sx={{ py: 0, px: 0 }}>
                      <Box>
                        <WidthFullIcon />
                        <Typography variant="body2" align="center" sx={{ mt: -.8, fontWeight: 'bold', fontSize: '10px' }}>All</Typography>
                      </Box>
                    </IconButton>
                  </Tooltip>
                </ToggleButton>
                
              </ToggleButtonGroup>
              <Box sx={{ marginTop:'2px',paddingBottom:'4px',fontSize: '11px', alignItems: 'center', textAlign: 'center' }}>Toggle Metrics</Box>
             </Box>
          </Paper>
          </Box>
          <Box sx={{ marginTop: '6px' }}></Box>
        </Collapse>
        
        <IconButton
          sx={{
            p: 0.1,
            fontSize: 15,
            marginTop: '2px',
            marginRight: '2px',


          }}
          onClick={handleToggleCollapse}
          aria-label="toggle-collapse"
        >
          {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </IconButton>
        </Paper>
    <Paper elevation={3} sx={{ maxWidth: maxTableWidth+50,paddingBottom:'5px',paddingTop: '5px', marginTop: '6px', flex: 1, paddingLeft: '1%',marginLeft:'1%',paddingRight:'1%' }}>
      <Box>
        <Box sx={{ color: theme.palette.primary.main, fontSize: '24px', fontWeight: '600' }}>Comp Sheet
        {alignment === 'growth' && <span>: Growth Metrics</span>}
        {alignment === 'profit' && <span>: Profitability Metrics</span>}
        {alignment === 'metrics' && <span>: KPIs</span>}
        {alignment === 'momentum' && <span>: Momentum</span>}
        {alignment === 'all' && <span>: All Metrics</span>}
        </Box>
        <div className='sublabel'>Select symbol to view security analysis</div>
      </Box>

      {filteredData.length > 0 ? (
        <TableContainer component={Paper} sx={{ cursor:'pointer',maxHeight: open? '69vh':'76vh', marginTop: '4px', overflowY: 'auto' , overflowX: 'auto',maxWidth: filters.find(filter => filter.name === alignment)?.maxWidth || 'none' }} ref={tableContainerRef}>
          <Table size='small' aria-label="trended metrics table">
          <TableHead>
            <TableRow sx={{maxWidth:'75px', backgroundColor: theme.tableHeader.color, fontWeight: 'bold', fontSize: '30',position: 'sticky', top: 0, zIndex: 2 }}>
              <TableCell align="left" sx={{textAlign: 'center' , position: 'sticky', top: 0, zIndex: 2, backgroundColor: theme.tableHeader.color, left: 0, fontWeight: 'bold'}}>
              <Typography sx={{fontSize:'small',fontWeight:'bold'}}>
                Security Symbol
                
                </Typography>
              </TableCell>
              <TableCell align="left" sx={{ textAlign: 'center' ,minWidth:'140px', top: 0, backgroundColor: theme.tableHeader.color, left: 0, fontWeight: 'bold'}}>
              <Typography sx={{fontSize:'small',fontWeight:'bold'}}>
                Company Name
                
                </Typography>
              </TableCell>
              <TableCell align="left" sx={{textAlign: 'center' , top: 0, backgroundColor: theme.tableHeader.color, left: 0, fontWeight: 'bold'}}>
              <Typography sx={{fontSize:'small',fontWeight:'bold'}}>
                Last Reported Quarter
                
                </Typography>
              </TableCell>
              
              {filteredData.length > 0 && filteredData[0].metrics.map(metric => (
                <TableCell
                  key={metric.field}
                  align="left"
                  sx={{ textAlign: 'center' ,maxWidth:'75',position: 'sticky', top: 0, zIndex: 1, backgroundColor: theme.tableHeader.color, fontWeight: 'bold' }}
                  onClick={() => handleSort(metric.field)}
                >
                <Typography sx={{maxWidth:'75px',fontSize:'small',fontWeight:'bold', display:'flex',flexDirection:'column',justifyContent:'space-between'}}>
                        {metric.field}
                        <Box sx={{display:'flex',justifyContent:'space-between'}}>
                          <ArrowUpward sx={{ opacity: (sortBy === metric.field && sortOrder === 'asc') ? 1 : 0.2, fontSize: 'small' }} />
                          <ArrowDownward sx={{ opacity: (sortBy === metric.field && sortOrder === 'desc') ? 1 : 0.2, fontSize: 'small' }} />
                        </Box>
                      </Typography>
              </TableCell>
              ))}
            </TableRow>
          </TableHead>
            <TableBody sx={{position: 'relative', zIndex: 1 }}>
            {firstRow.map((company, index) => (
                <TableRow key={index}
                  sx={{
                    '&:last-child td, &:last-child th': { border: 0 },
                    position: 'relative',
                    ':hover': {
                      backgroundColor: theme.tableHeader.highlightDark,
                      '.freeze-column-ticker': {
                        backgroundColor: theme.tableHeader.highlightDark,
                      },
                    },
                    backgroundColor: theme.tableHeader.highlightDark,
                  }}>
                    
                    <TableCell
                      align="left"
                      component="th"
                      scope="row"
                      className="freeze-column-ticker"
                      sx={{backgroundColor: theme.tableHeader.highlightDark,maxWidth:'100px',position: 'sticky', top: 0, zIndex: 1, left: 0 }}
                    >
                  <NavLink to={`/${company.ticker}`} style={{ textDecoration: 'none', color: 'inherit', display: 'flex', alignItems: 'center' }}>
                  <span>{company.ticker}</span>
                  <ArrowForward sx={{paddingLeft:'4px', opacity:0.2, fontSize:"small"}}/>
                </NavLink>
                  </TableCell>
                  <TableCell>
                  {company.companyName}
                  </TableCell>
                  <TableCell>
                  {company.reportedDate}
                  </TableCell>
                 
                  {company.metrics.map((metric, metricIndex) => (
                    <TableCell key={metricIndex} align="left" sx={{ maxWidth: '75px' }}>
                      {metric.str}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
              {filteredData.map((company, index) => (
                <TableRow key={index}
                  sx={{
                    '&:last-child td, &:last-child th': { border: 0 },
                    position: 'relative',
                    ':hover': {
                      backgroundColor: theme.tableHeader.highlightDark,
                      '.freeze-column-ticker': {
                        backgroundColor: theme.tableHeader.highlightDark,
                      },
                    },
                    backgroundColor: index % 2 === 0 ? theme.tableHeader.highlight: 'inherit',
                  }}>
                    
                    <TableCell
                      align="left"
                      component="th"
                      scope="row"
                      className="freeze-column-ticker"
                      sx={{backgroundColor: index % 2 === 0 ? theme.tableHeader.highlight: theme.background.default,maxWidth:'100px',position: 'sticky', top: 0, zIndex: 1, left: 0 }}
                    >
                  <NavLink to={`/${company.ticker}`} style={{ textDecoration: 'none', color: 'inherit', display: 'flex', alignItems: 'center' }}>
                  <span>{company.ticker}</span>
                  <ArrowForward sx={{paddingLeft:'4px', opacity:0.2, fontSize:"small"}}/>
                </NavLink>
                  </TableCell>
                  <TableCell>
                  {company.companyName}
                  </TableCell>
                  <TableCell>
                  {company.reportedDate}
                  </TableCell>
                 
                  {company.metrics.map((metric, metricIndex) => (
                    <TableCell key={metricIndex} align="left" sx={{ maxWidth: '75px' }}>
                      {metric.str}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <p>No trended metrics available</p>
      )}

      <Box sx={{ paddingBottom: '1vh' }}></Box>
    </Paper>
  </Box>
      )}
      export default CompSheetComponent;