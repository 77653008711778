import React from "react";

import data from '../data/charts/segmentation.json';
import { useState,useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import {FaDatabase, FaIndustry, FaDesktop, FaBuilding} from 'react-icons/fa';
import '../index.css'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { Select,MenuItem,ListItemText,useMediaQuery,ListItemIcon,Checkbox,ToggleButton,ToggleButtonGroup,Collapse,IconButton,List,Box,Paper } from "@mui/material";
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import MyResponsiveTreeMap from './ChartSegment'
import { useTheme } from '@mui/material/styles';
import { Tooltip, Typography } from '@mui/material';



  function filterTreeByName(tree, nameToRemove) {
    // Base case: If the current node's name matches the name to remove, return null to filter it out.
    if (tree.name === nameToRemove) {
      return null;
    }
  
    // Recursively filter children and create a new children array without the removed nodes.
    if (tree.children && tree.children.length > 0) {
      const newChildren = [];
      for (const child of tree.children) {
        const filteredChild = filterTreeByName(child, nameToRemove);
        if (filteredChild !== null) {
          newChildren.push(filteredChild);
        }
      }
      tree.children = newChildren;
    }
  
    // Return the updated tree.
    return tree;
  }
  
function Segmentation(){
  const theme = useTheme();
  const location = useLocation(); // If using React Router


  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);

    // If you want to scroll to the top every time the component updates, you can include an empty dependency array []
  }, []); // The empty dependency array ensures that this effect runs only once when the component mounts


    const [open, setOpen] = useState(true); // State to manage the open/closed state

    const items = [
        { id: 1, label: 'Application Software', icon: <FaDesktop /> },
        { id: 2, label: 'Infrastructure Software', icon: <FaDatabase /> },
        { id: 3, label: 'Vertical Software', icon: <FaIndustry /> },
        { id: 4, label: 'Diversified Software', icon: <FaBuilding /> }
        // Add more items as needed
    ];

    const [checked, setChecked] = React.useState([1,2,3]);

    const handleToggle = (event) => {
      const { value: selectedItems } = event.target;
      
      setChecked(prevCheckedCategory => {
          let newState = [...selectedItems];
    
          return newState;
      });
    }

    const [alignment, setAlignment] = React.useState('mktcap');

    const handleChange = (event, newAlignment) => {
      setAlignment(newAlignment);
    };

    let filteredData = JSON.parse(JSON.stringify(data))
    if (checked.indexOf(1) === -1) {
        filteredData = filterTreeByName(filteredData, "Application");
    }
    if(checked.indexOf(2) === -1){
        filteredData = filterTreeByName(filteredData, "Infrastructure");
    }
    if(checked.indexOf(3) === -1){
        filteredData = filterTreeByName(filteredData, "Vertical");
    }
    if(checked.indexOf(4) === -1){
        filteredData = filterTreeByName(filteredData, "Diversified");
        }

    const shouldWrap = useMediaQuery('(max-width: 900px)');
    const handleToggleCollapse = () => {
        setOpen(!open);
      };
    return(
        <>
    <Box sx={{width:'1900px',maxWidth:'100%'}} >
        <Box sx={{ position: 'relative', marginTop:'6px',paddingLeft: '1%', marginLeft: '0px' }}>
        <Paper elevation={2} sx={{ backgroundColor: theme.background.backgroundColor,paddingBottom: '4px', maxWidth: '1900px', paddingTop: '0px', marginTop: '6px', marginBottom: '0px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        {!open && (
          <Box
            className='label'
            sx={{
              paddingTop: '6px',
              paddingBottom: '6px',
              paddingLeft: '1.5%',
              fontWeight: 'bold',
            }}
          >
            <Box sx={{ paddingTop: '2px', fontSize: '12px',minWidth:'200px' }}>
              Expand Dashboard Filters
            </Box>
          </Box>
        )}
        <Collapse in={open} sx={{ width: '95%' }}>
        <Box sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap',justifyContent:'center' }}>
        <Paper elevation={theme.palette.mode=='dark'?4:2} sx={{ backgroundColor: theme.background.backgroundColor,width: '520px', marginLeft: '1%', paddingTop: '8px', marginTop: '6px', paddingLeft: '1%', paddingRight: '2%', paddingBottom: '0px', display: 'flex', alignItems: 'center' }}>
          <Box sx={{ maxWidth: '65px', fontSize: '12px', fontWeight: 'bold', textAlign: 'center',  paddingBottom: '10px' }}>Dashboard Filters</Box>
          <Box sx={{ display: 'flex', justifyContent: 'flex-start', flex: 1, flexWrap: 'wrap' }}>
          
              <Box sx={{marginBottom: '0px', marginLeft: '2%', marginRight: '1%' }}>
        <List size='small' disablePadding sx={{ width:'250px', display: 'flex', flexDirection: 'row'}}>
                <Select size="small" 
                  sx={{backgroundColor:theme.tableHeader.highlight,width:'250px'}}
                  multiple
                  value={checked} // Use checkedCategory here
                  onChange={handleToggle} // Use handleCheckedChangeCategory here
                  renderValue={(selected) => {
                    
                    if (selected.length === 4) {
                      return <Typography variant="body2">No Filter Applied</Typography>;
                    } else if (selected.length === 1) {
                      return <Typography variant="body2">{items.find((type) => type.id === selected[0]).label}</Typography>;
                    }
                    else if (selected.length > 1) {
                      return <Typography variant="body2">Multiple Items Selected</Typography>;
                    } else {
                      return <Typography variant="body2">No Filter Applied</Typography>;
                    }
                  }}
                  MenuProps={{
                    PaperProps: {
                      style: {
                        maxHeight: 250,
                        width: 'auto',
                      },
                    },
                  }}
                >
                  {items.map((item) => (
                    <MenuItem size="small" key={item.id} value={item.id} sx={{ height:'40px'}}>
                      
                      <ListItemIcon size="small" >
                        {item.icon}
                      </ListItemIcon>
                      
                      <ListItemText primary={
                        <Typography variant="body2"> {/* Set variant="body2" for smaller text */}
                          {item.label}
                        </Typography>
                      } />
                       
                      <Checkbox  checked={checked.indexOf(item.id) !== -1} /> {/* Use checkedCategory here */}
                    </MenuItem>
                  ))}
                  
                </Select>
                </List>
                <Box sx={{paddingBottom:'4px', marginTop:'2px',fontSize: '11px', alignItems: 'center', textAlign: 'center' }}>Select Segment</Box>
          </Box>
          <Box sx={{ marginLeft: '2%', marginRight: '1%', marginBottom: 0 }}>
              <ToggleButtonGroup
                sx={{backgroundColor:theme.tableHeader.highlight,
                  '& .Mui-selected': {
                    color: theme.toggleButton.selectedTextColor,
                    backgroundColor: theme.toggleButton.selectedBackgroundColor,
                  },
                }}
                value={alignment}
                exclusive
                onChange={handleChange}
                size="small"
                aria-label="Time Period Toggle"
              >
                <ToggleButton style={{ textTransform: 'none' }} value="mktcap" sx={{ py: 0.1, px: 1.1 }}>
                  <Tooltip title="Market Cap">
                    <IconButton color={alignment === 'mktcap' ? 'primary' : 'default'}  sx={{ py: 0, px: 0 }}>
                      <Box>
                        <AccountBalanceIcon />
                        <Typography variant="body2" align="center" sx={{ mt: -.8, fontWeight: 'bold', fontSize: '10px' }}>Mkt Cap</Typography>
                      </Box>
                    </IconButton>
                  </Tooltip>
                </ToggleButton>
                <ToggleButton style={{ textTransform: 'none' }} value="revenue" sx={{ py: 0.1, px: 1.1 }}>
                  <Tooltip title="TTM Revenue">
                    <IconButton color={alignment === 'revenue' ? 'primary' : 'default'}  sx={{ py: 0, px: 0 }}>
                      <Box>
                        <AttachMoneyIcon />
                        <Typography variant="body2" align="center" sx={{ mt: -1, fontWeight: 'bold', fontSize: '10px' }}>Revenue</Typography>
                      </Box>
                    </IconButton>
                  </Tooltip>
                </ToggleButton>
              </ToggleButtonGroup>
              <Box sx={{paddingBottom:'4px', marginTop:'2px',fontSize: '11px', alignItems: 'center', textAlign: 'center' }}>Select Methodology</Box>
             </Box>
          </Box>
          
          </Paper>
          
          
          </Box>
          <Box sx={{ marginTop: '6px' }}></Box>
        </Collapse>
        <IconButton
            sx={{
              p: 0.1,
              fontSize: 15,
              marginTop: '2px',
              marginRight: '2px',


            }}
            onClick={handleToggleCollapse}
            aria-label="toggle-collapse"
          >
            {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </IconButton>
        </Paper>
    </Box>
    
    <Paper sx={{
      minWidth:'400px',
      marginTop:'6px',
      maxWidth: '1900px',
      marginLeft: '1%',
      paddingBottom: '0px',
      paddingTop: '5px',
      paddingRight:'1%',
      position: 'relative'}} elevation={3}>
      <Box sx={{color: theme.palette.primary.main,fontSize:'30px', fontWeight:'600',paddingTop:'0px',paddingLeft:'2%'}}>Market Segmentation Treemap</Box>
    
      <Box sx={{width: '98%',
        maxWidth: '1900px',
        height: open?'62vh':'69vh',
        marginLeft: '1%',
        paddingBottom: '0px',
        paddingTop: '0px',
        paddingRight:'1%',
        position: 'relative'}}>
          <MyResponsiveTreeMap data={filteredData} value={alignment} theme={theme} />
          <img
              src={require('../data/images/logo.png')}
              alt="Website Logo"
              style={{
                position: 'absolute',
                bottom: 25, // Adjust the distance from the bottom
                right: 28, // Adjust the distance from the right
                width: '110px', // Set the desired width for the postage stamp
                height: 'auto', // Maintain aspect ratio
                opacity: 0.4, // Set the desired opacity for the watermark
              }}
            />
        </Box>
        </Paper>

        </Box>
        </>
    
    )
}
export default Segmentation