// theme.js
import { createTheme } from '@mui/material/styles';

const lightTheme = createTheme({
  
  palette: {
    mode: 'light',
    primary: {
      main: '#3f64a8', // Set your primary color
    },
    secondary: {
      main: '#3f64a8', // Set your secondary color
    },
    action:{
      selected:'#F0F3F8'
    }
  },
  button: {
    backgroundColor: '#2196F3', // Default button background color in light theme
    color: '#3f64a8', // Default button text color in light theme
  },
  toggleButton: {
    
    selectedTextColor: '#3f64a8',
    selectedBackgroundColor: 'rgb(222,222,222)', // Text color for the selected state in light theme
  },
  table:{
    header:'#FAFAFA'
  },
  tableHeader:{
    highlightDark:'rgb(238,238,238)',
    color:'rgb(225,225,225)',
    group:'rgb(246,246,246)',
    highlight:'rgb(248,248,248)',
  },
  tooltip:{
    color:'rgb(232,232,232)',
  },
  background:{
    default: '#ffffff', // Default background color for light mode
    backgroundColor:'#f2f2f2',
  },
  scrollbar: {
    width: '0.6em',
    height: '0.6em',
    backgroundColor: 'rgba(0,0,0,.3)',
    borderRadius: '10px',
  },
});

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: 'rgb(145,166,205)', // Set your primary color
    },
    secondary: {
      main: 'rgb(145,166,205)', // Set your secondary color
    },
    action:{
      selected:'#3E4248'
    },
  },
  table:{
    header:'rgb(25,25,25)'
  },
  button: {
    backgroundColor: '#1976D2', // Default button background color in dark theme
    color: 'rgb(145,166,205)' // Default button text color in dark theme
  },
  toggleButton: {
    selectedTextColor: 'rgb(145,166,205)',
    selectedBackgroundColor: '#2C3443', // Text color for the selected state in light theme
  },
  tooltip:{
    color:'#1e1e1e',
  },
  tableHeader:{
    highlightDark:'rgb(58,58,58)',
    color:'rgb(55,55,55)',
    group:'rgb(42,42,42)',
    highlight:'rgb(39,39,39)',
  },
  background:{
    default: 'rgb(30,30,30)', // Default background color for dark mode
    backgroundColor:'rgb(22,22,22)',
  },
  scrollbar: {
    width: '0.6em',
    height: '0.6em',
    backgroundColor: 'rgba(0,0,0,.3)',
    borderRadius: '10px',
  },
});

export { lightTheme, darkTheme };